import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../../../core/core.module';
import { LoadingSpinnerComponent } from '../../components/loading-spinner/loading-spinner.component';
import { InlineSVGModule } from 'ng-inline-svg';

@NgModule({
    imports: [CommonModule, CoreModule, InlineSVGModule],
    declarations: [LoadingSpinnerComponent],
    exports: [LoadingSpinnerComponent],
    providers: [],
})
export class LoadingSpinnerModule {}
