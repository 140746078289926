import { environment } from '../../../../../environments/environment';
import { AuthenticationService, ChangePasswordInput } from '@scalepoint/nemex-api-clients/services';
import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { tap, takeUntil, finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'newpassword',
    templateUrl: './new-password.component.html',
    styleUrls: ['./new-password.component.scss'],
})
export class NewPasswordComponent implements OnInit {
    renewForm: FormGroup;
    passwordsNotMatching = false;
    renewError = false;
    renewloading = false;
    showPasswordChangedMessage = false;
    private unsubscribe: Subject<any>;

    @Input() username: string;
    @Input() password: string;

    constructor(private fb: FormBuilder, private authenticationService: AuthenticationService, private cdr: ChangeDetectorRef) {
        this.unsubscribe = new Subject();
    }

    ngOnInit() {
        this.initRenewForm();
    }

    initRenewForm() {
        this.renewForm = this.fb.group(
            {
                username: [{ value: this.username, disabled: this.username }, Validators.compose([Validators.required, Validators.minLength(1)])],
                currentPassword: ['', Validators.compose([Validators.required, Validators.minLength(1)])],
                newPassword: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
                newPasswordConfirm: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
            },
            { validators: this.matchingPasswords('newPassword', 'newPasswordConfirm') }
        );
    }

    matchingPasswords(passwordKey: string, confirmPasswordKey: string) {
        return (group: FormGroup): { [key: string]: any } => {
            let password = group.controls[passwordKey];
            let confirmPassword = group.controls[confirmPasswordKey];

            this.passwordsNotMatching = false;
            if (password.valid && confirmPassword.valid && confirmPassword.touched && password.value !== confirmPassword.value) {
                this.passwordsNotMatching = true;
                return {
                    mismatchedPasswords: true,
                };
            }
        };
    }

    renewPassword() {
        this.renewError = false;

        const controls = this.renewForm.controls;
        /** check form */
        if (this.renewForm.invalid) {
            Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
            return;
        }

        this.renewloading = true;

        let changePasswordInput = new ChangePasswordInput({
            username: controls['username'].value,
            currentPassword: controls['currentPassword'].value,
            newPassword: controls['newPassword'].value,
        });

        this.authenticationService
            .password(environment.apiVersion, changePasswordInput)
            .pipe(
                tap(
                    () => {
                        //this.loginForm.controls['password'].setValue('');
                        this.showPasswordChangedMessage = true;
                    },
                    (error) => {
                        if (error && error.status >= 500) {
                            // Handled by ErrorInterceptService
                            return;
                        }

                        console.log(error);
                        this.renewError = true;
                    }
                ),
                takeUntil(this.unsubscribe),
                finalize(() => {
                    this.renewloading = false;
                    this.cdr.markForCheck();
                })
            )
            .subscribe();
    }

    isControlHasError(controlName: string, validationType: string): boolean {
        const control = this.renewForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }
}
