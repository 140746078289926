import { Component, OnInit, Input } from '@angular/core';
import { CalculateStep } from '../calculateStep';

@Component({
    selector: 'calculate-progress',
    templateUrl: './calculate-progress.component.html',
    styleUrls: ['./calculate-progress.component.scss'],
})
export class CalculateProgressComponent implements OnInit {
    @Input() step: CalculateStep;

    constructor() {}

    ngOnInit(): void {}
}
