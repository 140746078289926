import { ExtendedCprResult } from '@scalepoint/nemex-api-clients/services';
import { CprModel } from '../cpr';
import { PersonNameModel } from '../personName';
import { AddressNormalOutputModel } from '../policy/addressNormalOutput';

export class ExtendedCprResultModel {
    address?: AddressNormalOutputModel;
    person?: PersonNameModel;
    cpr?: CprModel;
    addressProtection?: boolean | undefined;
    advertisementProtection?: boolean | undefined;
    male?: boolean;
    dateOfBirth?: Date;
    cprStatus?: string | undefined;
    cprMessage?: string | undefined;

    constructor(extendedCprResult: ExtendedCprResult) {
        this.address = extendedCprResult.address ? new AddressNormalOutputModel(extendedCprResult.address) : undefined;
        this.person = extendedCprResult.person ? new PersonNameModel(extendedCprResult.person) : undefined;
        this.cpr = extendedCprResult.cpr ? new CprModel(extendedCprResult.cpr) : undefined;
        this.addressProtection = extendedCprResult.addressProtection;
        this.advertisementProtection = extendedCprResult.advertisementProtection;
        this.male = extendedCprResult.male;
        this.dateOfBirth = extendedCprResult.dateOfBirth;
        this.cprStatus = extendedCprResult.cprStatus;
        this.cprMessage = extendedCprResult.cprMessage;
    }
}
