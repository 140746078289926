// Angular
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
// Layout
import { LayoutConfigService, TranslationService } from '../../../core/_base/layout';
// Auth
import { AuthNoticeService, AuthService } from '../../../core/auth';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { Role } from '../../../core/auth/_services/roles';

@Component({
    selector: 'kt-auth',
    template: '',
    encapsulation: ViewEncapsulation.None,
})
export class AuthComponent implements OnInit {
    // Public properties
    today: number = Date.now();
    headerLogo: string;

    constructor(
        private layoutConfigService: LayoutConfigService,
        public authNoticeService: AuthNoticeService,
        private translationService: TranslationService,
        private oauthService: OAuthService,
        private router: Router,
        private auth: AuthService
    ) {}

    ngOnInit(): void {
        this.translationService.setLanguage(this.translationService.getSelectedLanguage());
        this.headerLogo = this.layoutConfigService.getLogo();

        this.oauthService.loadDiscoveryDocumentAndTryLogin().then((_) => {
            if (!this.oauthService.hasValidAccessToken()) {
                this.oauthService.initLoginFlow();
            } else {
                this.auth.saveData();

                if (!this.auth.hasRole(Role.AccessNemex)) {
                    this.auth.signOut();
                }

                // Trick: https://stackoverflow.com/a/37298351
                setTimeout(() => this.router.navigate(['/frontpage']), 0);
            }
        });
    }
}
