import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { LoadingService } from '../../../services/loading-service/loading.service';
import { debounce } from 'lodash-es';
import { Subscription } from 'rxjs';

@Component({
    selector: 'loading-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent implements OnInit, OnDestroy {
    isLoading = false;
    subscriptions: Subscription[] = [];

    constructor(private loadingService: LoadingService, private changeDetectorRef: ChangeDetectorRef) {}

    setLoading =
        // Only show the loading spinner if loading is more than 200ms
        debounce((isLoading) => {
            this.isLoading = isLoading;
            this.changeDetectorRef.markForCheck();
        }, 200);

    ngOnInit() {
        this.subscriptions.push(
            this.loadingService.loading.subscribe((isLoading) => {
                this.setLoading(isLoading);
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
