<div class="kt-header__topbar-item search-wrapper">
    <span class="kt-header__topbar-icon" ngClass="kt-header__topbar-icon--success" (click)="onIconClick()">
        <span class="kt-svg-icon" inlineSVG="./../../../../../../assets/media/icons/svg/General/Search.svg"></span>
    </span>
    <div [hidden]="!showSearchInput" class="search-input-wrapper">
        <input
            #searchInput
            [(ngModel)]="searchString"
            (keyup.enter)="search()"
            (focusout)="showSearchInput = false"
            type="text"
            class="search-input form-control kt-quick-search__input"
            placeholder="{{ 'PAGES.PARTIALS.LAYOUT.TOPBAR.SEARCH.SEARCH_PLACEHOLDER' | translate }}"
        />
    </div>
</div>
