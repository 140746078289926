import { Injectable, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TokenStorage } from '../token-storage.service';
import { FunctionsService } from '../../_base/crud/utils/functions.service';
import { AuthData } from '../authdata';
import { Role } from './roles';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OAuthService } from 'angular-oauth2-oidc';
import { getAuthConfig } from '../../../core/auth/_services/auth.config';
import { AppConfigService } from '../../../services/config-service/config.service';

@Injectable()
export class AuthService {
    public clearTokenObserveable: Observable<void>;

    loginStatusChanged: EventEmitter<boolean> = new EventEmitter();
    tokenExpirationChanged: EventEmitter<Date> = new EventEmitter();

    private clearTokenSource = new Subject<void>();

    constructor(private oauthService: OAuthService, private tokenStorage: TokenStorage, private functionService: FunctionsService, private modalService: NgbModal, config: AppConfigService) {
        this.clearTokenObserveable = this.clearTokenSource.asObservable();
        this.oauthService.configure(getAuthConfig(config.appConfig.Authentication.Issuer));
        this.oauthService.setupAutomaticSilentRefresh();
    }

    public getToken(): string {
        return this.tokenStorage.getAccessToken();
    }

    public clearToken(): void {
        this.tokenStorage.clear();
        this.clearTokenSource.next();
    }

    public isAuthenticated(): boolean {
        return !this.tokenExpired();
    }

    public signOut() {
        this.modalService.dismissAll();
        this.clearToken();
        this.oauthService.revokeTokenAndLogout();
    }

    public getExpirationDate(): Date {
        return this.tokenStorage.getExpirationDate();
    }

    public saveData(): TokenStorage {
        const data: AuthData = {
            access_token: this.oauthService.getAccessToken(),
            expires_in: this.oauthService.getAccessTokenExpiration(),
        };

        const nowDate = this.functionService.convertToUtcDate(new Date());

        // Do not use data.expires_at since users time settings might be messed up
        const expireDate = new Date(nowDate.getTime() + data.expires_in * 1000); // expires_in is in seconds - here we have to provide milliseconds

        const username = this.getUserNameFromJWT(data.access_token);
        const initials = this.getInitialsFromJWT(data.access_token);
        const phone = this.getPhoneFromJWT(data.access_token);
        const email = this.getEmailFromJWT(data.access_token);

        const ts = this.tokenStorage.setAccessToken(data.access_token).setExpDate(expireDate).setUsername(username).setInitials(initials).setPhone(phone).setEmail(email);

        this.tokenExpirationChanged.emit(this.tokenStorage.getExpirationDate());
        this.loginStatusChanged.emit(true);

        return ts;
    }

    public getEmail(): string {
        return this.tokenStorage.getEmail();
    }

    public getPhone(): string {
        return this.tokenStorage.getPhone();
    }

    public getUsername(): string {
        return this.tokenStorage.getUsername();
    }

    public getInitials(): string {
        return this.tokenStorage.getInitials();
    }

    public hasRole(role: Role): boolean {
        const token = this.getToken();

        return this.hasRoleInJwt(role, token);
    }

    public getRoles(): Role[] {
        const token = this.getToken();

        if (!token) {
            return [];
        }

        const tokenObject = this.getJwtObject(token);

        if (!tokenObject) {
            return [];
        }

        return tokenObject.role;
    }

    private tokenExpired(): boolean {
        const nowDate = this.functionService.convertToUtcDate(new Date());
        const expDate = this.tokenStorage.getExpirationDate();

        if (expDate != null && expDate.getTime() > nowDate.getTime()) {
            return false;
        }

        return true;
    }

    private getUserNameFromJWT(token: string): string {
        const tokenObject = this.getJwtObject(token);

        if (!tokenObject) {
            return '';
        }

        return tokenObject.name;
    }

    private getInitialsFromJWT(token: string): string {
        const tokenObject = this.getJwtObject(token);

        if (!tokenObject) {
            return '';
        }

        return tokenObject.unique_name;
    }

    private getPhoneFromJWT(token: string): string {
        const tokenObject = this.getJwtObject(token);

        if (!tokenObject) {
            return '';
        }

        return tokenObject.nemex_phone;
    }

    private getEmailFromJWT(token: string): string {
        const tokenObject = this.getJwtObject(token);

        if (!tokenObject) {
            return '';
        }

        return tokenObject.email;
    }

    private hasRoleInJwt(role: Role, token: string): boolean {
        const tokenObject = this.getJwtObject(token);

        if (!tokenObject) {
            return false;
        }

        return tokenObject.role.some((r) => r === role);
    }

    private getJwtObject(token: string) {
        const parts = token.split('.');
        if (parts.length !== 3) {
            return null;
        }
        const payload = parts[1];
        const tokenObject = JSON.parse(this.base64Decode(payload));

        return tokenObject;
    }

    private base64Decode(input: string) {
        // To support decoding UTF-8
        return decodeURIComponent(
            atob(input)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );
    }
}
