import { PersonName } from '@scalepoint/nemex-api-clients/services';

export class PersonNameModel {
    readonly firstAndMiddleNames?: string | undefined;
    readonly surname?: string | undefined;
    readonly fullName?: string | undefined;

    constructor(apiPersonName: PersonName) {
        if (!apiPersonName) {
            return;
        }
        this.firstAndMiddleNames = apiPersonName.firstAndMiddleNames;
        this.surname = apiPersonName.surname;
        this.fullName = apiPersonName.fullName;
    }
}
