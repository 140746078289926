import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AlertService } from '../../../../services/alert-service/alert.service';
import { AlertModel } from '../../../../services/alert-service/alert-model';
import { AlertType } from '../../../../services/alert-service/alert-type';
import { ServerErrorResultModel } from '../../../../services/service-models/serverErrorResult';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptService implements HttpInterceptor {
    constructor(private alertService: AlertService, private router: Router, private translate: TranslateService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(
                (event: HttpEvent<any>) => {},
                (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 401) {
                            if (location.href.indexOf('auth') === -1 && location.href.indexOf('newpassword') === -1) {
                                this.router.navigateByUrl('/auth/login');
                                const alertModel = new AlertModel('Login udløbet', AlertType.tokenExpired);
                                this.alertService.setAlert(alertModel);

                                return;
                            }
                        }

                        if (err.status < 500) {
                            return;
                        }

                        // Suppress error messages from customer rating
                        if (request.url.indexOf('/customers') !== -1 && request.url.indexOf('/rating') !== -1) {
                            return;
                        }

                        if (err.status === 500) {
                            // Blob is returned on status 500
                            new Response(err.error)
                                .text()
                                .then((response) => {
                                    try {
                                        const responseObject: ServerErrorResultModel = new ServerErrorResultModel(JSON.parse(response));

                                        const alertModel = new AlertModel(this.translate.instant('SHARED.COMMON.ERROR'), AlertType.contactTechSupport, responseObject.traceId);

                                        this.alertService.setAlert(alertModel);
                                    } catch (e) {
                                        this.showGenericErrorMessage();
                                    }
                                })
                                .catch(this.showGenericErrorMessage);

                            return;
                        }

                        this.showGenericErrorMessage();
                    }
                }
            )
        );
    }

    showGenericErrorMessage() {
        const alertModel = new AlertModel(this.translate.instant('SHARED.COMMON.ERROR'), AlertType.contactTechSupport);

        this.alertService.setAlert(alertModel);
    }
}
