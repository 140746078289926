import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { TranslationService } from './../../core/_base/layout';
import { DefaultLangChangeEvent } from '@ngx-translate/core';
import { dictionary } from './dictionary';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class BackendTranslateService implements OnDestroy {
    language: string;
    dictionary = dictionary;
    subscriptions: Subscription[] = [];

    constructor(private translationService: TranslationService) {
        this.language = this.translationService.getSelectedLanguage();

        this.subscriptions.push(
            this.translationService.getLanguageChangeEmitter().subscribe((languageChangeEvent: DefaultLangChangeEvent) => {
                this.language = languageChangeEvent.lang;
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    // area example: CUSTOMER.TABS
    translate(text: string, area: string, fallback?: string): string {
        function navigateObject(path: string, obj: object) {
            return path.split('.').reduce((prev, curr) => prev && prev[curr], obj);
        }

        try {
            const translatedText = navigateObject(area, this.dictionary[this.language].translations)[text];

            if (!translatedText) {
                return fallback ? fallback : text;
            }

            return translatedText;
        } catch (e) {
            // Return backend string if translation is not found
            return fallback ? fallback : text;
        }
    }
}
