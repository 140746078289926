import { AuthService } from '../auth';
import { Role } from '../auth/_services/roles';

export class MenuConfig {
    public defaults: any = {
        header: {
            self: {},
            items: [
                // {
                //     title: 'Agenturer',
                //     root: true,
                //     alignment: 'left',
                //     toggle: 'click',
                //     submenu: [
                //         {
                //             title: 'NEM',
                //             bullet: 'dot',
                //             icon: 'flaticon-tool',
                //         },
                //         {
                //             title: 'todo',
                //             bullet: 'dot',
                //             icon: 'flaticon-tool',
                //         },
                //         {
                //             title: 'todo',
                //             bullet: 'dot',
                //             icon: 'flaticon-tool',
                //         },
                //     ],
                // },
            ],
        },
        aside: {
            self: {},
            items: [
                {
                    title: 'FRONTPAGE',
                    root: true,
                    icon: 'flaticon2-architecture-and-city',
                    page: '/frontpage',
                    bullet: 'dot',
                },
                { section: 'CUSTOMER_HANDLING' },
                {
                    title: 'SEARCH',
                    bullet: 'dot',
                    icon: 'flaticon2-user-outline-symbol',
                    page: '/customer',
                },
                {
                    title: 'CREATE_CUSTOMER',
                    bullet: 'dot',
                    icon: 'flaticon2-user-1',
                    page: '/create/customer',
                },
                {
                    title: 'CREATE_OFFER',
                    bullet: 'dot',
                    icon: 'flaticon2-start-up',
                    page: '/calculate',
                },
            ],
        },
    };

    public configs(userRoles: Role[]): any {
        const config = this.defaults;

        if (userRoles.some((r) => r === Role.LeadBasic)) {
            config.aside.items.push({
                title: 'CREATE_LEAD',
                bullet: 'dot',
                icon: 'flaticon-user-add',
                modal: 'leads-create-modal',
            });
        }

        if (userRoles.some((r) => r === Role.LeadAdmin)) {
            config.aside.items.push({
                title: 'CREATE_BULK_LEADS',
                bullet: 'dot',
                icon: 'flaticon-upload',
                modal: 'leads-bulk-create-modal',
            });
        }

        const toolRoles = [Role.CprSearch, Role.DfimSearch, Role.SmsAdmin, Role.SmsAgencySingle, Role.SmsAgencyBulk, Role.CustomerReviewsBulk];

        if (userRoles.some((r) => toolRoles.some((tr) => tr === r))) {
            config.aside.items.push({ section: 'TOOLS' });

            if (userRoles.some((r) => r === Role.CprSearch)) {
                config.aside.items.push({
                    title: 'CPR',
                    bullet: 'dot',
                    icon: 'flaticon-questions-circular-button',
                    page: '/cpr',
                });
            }

            if (userRoles.some((r) => r === Role.DfimSearch)) {
                config.aside.items.push({
                    title: 'DFIM',
                    bullet: 'dot',
                    icon: 'flaticon-search',
                    page: '/dfim',
                });
            }

            if (userRoles.some((r) => r === Role.SmsAgencySingle || r === Role.SmsAgencyBulk)) {
                config.aside.items.push({
                    title: 'TEXT_MESSAGES',
                    bullet: 'dot',
                    icon: 'flaticon-speech-bubble',
                    modal: 'agency-text-message',
                });
            }

            if (userRoles.some((r) => r === Role.SmsAdmin)) {
                config.aside.items.push({
                    title: 'SMS_TEMPLATES',
                    bullet: 'dot',
                    icon: 'flaticon-list-3',
                    page: '/smstemplates',
                });
            }

            if (userRoles.some((r) => r === Role.CustomerReviewsBulk)) {
                config.aside.items.push({
                    title: 'CUSTOMER_REVIEWS',
                    bullet: 'dot',
                    icon: 'flaticon-star',
                    page: '/customerreviews',
                });
            }
        }

        config.aside.items.push({ section: 'DOCUMENTS' });
        if (userRoles.some((r) => r === Role.FolderAccess)) {
            config.aside.items.push({
                title: 'FOLDERS',
                bullet: 'dot',
                icon: 'flaticon-folder-1',
                page: '/folders/view',
            });
        }

        if (userRoles.some((r) => r === Role.FolderAdmin)) {
            config.aside.items.push({
                title: 'SETTINGS',
                bullet: 'dot',
                icon: 'flaticon-settings-1',
                page: '/folders/admin',
            });
        }

        if (userRoles.some((r) => r === Role.QuestionnaireAdmin)) {
            config.aside.items.push({ section: 'STATISTICS' });
            config.aside.items.push({
                title: 'QUESTIONNAIRE_REPORT',
                bullet: 'dot',
                icon: 'flaticon-pie-chart',
                page: '/statistics/questionnaires',
            });
        }

        if (userRoles.some((r) => r === Role.DashboardAdmin || r === Role.DashboardRead)) {
            config.aside.items.push({ section: 'DASHBOARDS' });
            if (userRoles.some((r) => r === Role.DashboardRead)) {
                config.aside.items.push({
                    title: 'VIEW_DASHBOARD',
                    bullet: 'dot',
                    icon: 'flaticon-squares-2',
                    page: '/dashboards/select',
                });
            }

            if (userRoles.some((r) => r === Role.DashboardAdmin)) {
                config.aside.items.push({
                    title: 'EDIT_DASHBOARDS',
                    bullet: 'dot',
                    icon: 'flaticon2-pen',
                    page: '/dashboards/edit',
                });
            }
        }

        return config;
    }
}
