import { NewPasswordComponent } from '../new-password/new-password/new-password.component';
import { NewPasswordModule } from '../new-password/new-password.module';
// Angular
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
// Material
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
// Translate
import { TranslateModule } from '@ngx-translate/core';
// Module components
import { AuthComponent } from './auth.component';
import { AuthNoticeComponent } from './auth-notice/auth-notice.component';
// Auth
import { AuthService } from '../../../core/auth';
import { AuthGuard } from './../../../core/auth/_guards/auth.guard';
import { OAuthModule } from 'angular-oauth2-oidc';

const routes: Routes = [
    {
        path: '',
        component: AuthComponent,
    },
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        RouterModule.forChild(routes),
        MatInputModule,
        MatFormFieldModule,
        MatCheckboxModule,
        TranslateModule.forChild(),
        NewPasswordModule,
        OAuthModule.forRoot(),
    ],
    providers: [],
    exports: [AuthComponent],
    declarations: [AuthComponent, AuthNoticeComponent],
})
export class AuthModule {
    static forRoot(): ModuleWithProviders<AuthModule> {
        return {
            ngModule: AuthModule,
            providers: [AuthService, AuthGuard],
        };
    }
}
