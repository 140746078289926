import { ILanguage } from './iLanguage';

export const english: ILanguage = {
    translations: {
        AGENCIES: {
            Krifa: 'Krifa',
            Nem: 'NEM Forsikring',
            MS: 'Middelfart Sparekasse',
            opal: 'Brevis',
            HS: 'HS Forsikringsagentur',
            JFR: 'Jysk Forsikring Rådgivning A/S',
            MK: 'UNIVERS Forsikringsformidling',
            NEXT: 'NEXT FORSIKRING A/S',
            ICare: 'iCare Forsikring A/S',
            FODAN: 'NEM Forsikring',
            SKRON: 'Sparekassen Kronjylland',
            MARSH: 'Marsh privatforsikring',
            TLN: 'TLN',
            RIGPO: 'NEM Forsikring (afd.RP)',
        },
        BOOL: {
            true: 'Yes',
            false: 'No',
        },
        ENUMS: {
            PRODUCT_TYPE: {
                1: 'Dog',
                2: 'Car',
                3: 'House',
                4: 'Home',
                5: 'Accident',
                6: 'Caravan',
                7: 'Moped',
                8: 'Motorcycle',
                9: 'Road assistance',
                10: 'Holiday house',
                11: 'Trailer',
                12: 'Travel',
                13: 'Tractor',
                14: 'Agriculture',
                15: 'Boat',
                16: 'Ownership change',
                17: 'Group life',
                18: 'Salary',
                99: 'Unknown',
            },
            COLLECTION_STATE: {
                0: 'Open',
                1: 'Paid',
                2: 'Written off',
                3: 'Cancelled',
                4: 'Overdue',
                5: 'Reminder',
            },
            EMAIL_TYPE: {
                1: 'Private',
                2: 'Work',
            },
            PAYMENT_METHOD: {
                0: 'Unknown',
                1: 'Payment slip',
                2: 'Betalingsservice',
            },
            PHONENUMBER_TYPE: {
                1: 'Landline',
                2: 'Mobile',
                3: 'Fax',
                4: 'Work',
                99: 'Other',
            },
            POLICY_INSTALMENT_PERIOD_TYPE: {
                0: 'None',
                1: 'Yearly',
                2: 'Biannual',
                3: 'Quarterly',
                4: 'Monthly',
                99: 'Unknown',
            },
            POLICY_STATE: {
                0: 'Unknown',
                1: 'Active',
                2: 'Pending',
                3: 'WaitingPolicy',
                4: 'Failed',
                5: 'Offer',
                6: 'Cancelled',
                7: 'Deleted',
                8: 'FutureActivation',
                9: 'FutureCancellation',
                10: 'WaitingOffer',
            },
            POLICY_VERSION_STATE: {
                0: 'Future',
                1: 'Active',
                2: 'Past',
            },
            RELATION_TYPE: {
                0: 'Unknown',
                1: 'Cohabitants',
                2: 'Child',
                3: 'Parent',
                4: 'Family to',
                5: 'Employee at',
                6: 'Employee',
                7: 'Owner',
                8: 'Owner of',
                9: 'Customer',
                10: 'Customer at',
                11: 'Member',
                12: 'Member at',
            },
            UNPAID_FEE_STATE: {
                1: 'Paid',
                2: 'Unpaid',
                3: 'No registration',
            },
            INSURANCE_SCOPE: {
                0: 'Unknown',
                1: 'Leisure',
                2: 'Full',
                3: 'Child',
            },
            PRODUCT_CATEGORY: {
                0: 'Building',
                1: 'MotorVehicles',
                2: 'Other',
            },
            POLICY_TASK_STATUS: {
                1: 'Done',
                2: 'Cancelled',
                3: 'Rejected',
                4: 'Waiting',
                5: 'Unhandled',
                99: 'None',
            },
        },
    },
};
