<div ngbDropdown placement="bottom-right" class="kt-header__topbar-item kt-header__topbar-item--user">
    <div ngbDropdownToggle class="kt-header__topbar-wrapper">
        <div class="kt-header__topbar-user" [ngClass]="{ 'kt-header__topbar-icon': icon }">
            <span class="kt-header__topbar-username kt-hidden-mobile" *ngIf="greeting">{{ username }}</span>
            <i *ngIf="icon" class="flaticon2-user-outline-symbol"></i>
            <img *ngIf="avatar" alt="Pic" [attr.src]="userimage" />
            <span *ngIf="badge" class="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold">{{ username | firstLetter }}</span>
        </div>
    </div>
    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <!--begin: Head -->
        <div class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x" style="background-image: url(./assets/media/misc/bg-1.jpg)">
            <div class="kt-user-card__avatar">
                <img alt="Pic" [attr.src]="userimage" />
                <span [hidden]="true" class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">
                    {{ username | firstLetter }}
                </span>
            </div>
            <div class="kt-user-card__name">
                {{ username }}
            </div>
        </div>
        <!--end: Head -->

        <!--begin: Navigation -->
        <div class="kt-notification">
            <a ngbDropdownItem [routerLink]="'/profile'" class="kt-notification__item">
                <div class="kt-notification__item-icon">
                    <i class="flaticon2-calendar-3 kt-font-success"></i>
                </div>
                <div class="kt-notification__item-details">
                    <div class="kt-notification__item-title kt-font-bold">
                        {{ 'PAGES.PARTIALS.LAYOUT.TOPBAR.USER_PROFILE.TITLE' | translate }}
                    </div>
                    <div class="kt-notification__item-time">
                        {{ 'PAGES.PARTIALS.LAYOUT.TOPBAR.USER_PROFILE.DESCRIPTION' | translate }}
                    </div>
                </div>
            </a>
            <div class="kt-notification__custom">
                <a [routerLink]="'/signout'" class="btn btn-outline-brand btn-upper btn-sm btn-bold">{{ 'PAGES.PARTIALS.LAYOUT.TOPBAR.USER_PROFILE.SIGNOUT' | translate }}</a>
            </div>
        </div>
        <!--end: Navigation -->
    </div>
</div>
