import { ProductCategoryEnumDisplay } from '@scalepoint/nemex-api-clients/services';

export class ProductCategoryEnumDisplayModel {
    readonly name?: string | undefined;
    readonly typeCode?: string | undefined;

    constructor(apiProductCategoryEnumDisplay: ProductCategoryEnumDisplay) {
        this.name = apiProductCategoryEnumDisplay.name;
        this.typeCode = apiProductCategoryEnumDisplay.typeCode;
    }
}
