import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../../../pipes/pipes.module';
import { BlockedCprModalComponent } from './blocked-cpr-modal.component';
import { CprServiceWrapper } from '../../../services/cpr-service/cpr.service.wrapper';

@NgModule({
    imports: [CommonModule, FormsModule, NgbModule, PipesModule, TranslateModule.forChild()],
    providers: [CprServiceWrapper],
    declarations: [BlockedCprModalComponent],
    exports: [BlockedCprModalComponent],
})
export class BlockedCprModalModule {}
