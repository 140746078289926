import { LeadDetails } from '@scalepoint/nemex-api-clients/services';
import { PersonNameModel } from '../personName';

export class LeadDetailsModel {
    leadId?: string | undefined;
    name?: PersonNameModel;
    phoneNumber?: string | undefined;

    constructor(apiLeadDetails: LeadDetails) {
        this.leadId = apiLeadDetails.leadId;
        this.name = apiLeadDetails.name ? new PersonNameModel(apiLeadDetails.name) : null;
        this.phoneNumber = apiLeadDetails.phoneNumber;
    }
}
