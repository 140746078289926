import { AppConfigService } from './../../../../services/config-service/config.service';
import { OAuthStorage } from 'angular-oauth2-oidc';
// Angular
import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
// RxJS
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {
    constructor(private inj: Injector, private authStorage: OAuthStorage, private config: AppConfigService, private router: Router) {}

    addHeader(request: HttpRequest<any>): HttpRequest<any> {
        // "Request header field Authorization is not allowed by Access-Control-Allow-Headers in preflight response"
        // is thrown if we call an endpoint with our Authorization - we should only send it when neeeded!

        if (!this.config.appConfig) {
            return request;
        }

        if (request.url.indexOf(this.config.appConfig.Nemex.ApiHost) !== -1) {
            // only add Authorization to header when we call our own api
            const token = this.authStorage.getItem('access_token');
            if (token) {
                return request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`,
                    },
                });
            }
        }

        return request;
    }

    // intercept request and add token
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = this.addHeader(request);

        return next.handle(request).pipe(
            tap((event) => {
                // We need to skip intersecting the request for config.json, because auth.service isn't ready. Also disregard third party requests.
                if (!this.config.appConfig) {
                    return;
                }

                if (request.url.indexOf('appsettings.json') !== -1 || request.url.indexOf('refreshtoken') !== -1 || request.url.indexOf(this.config.appConfig.Nemex.ApiHost) === -1) {
                    return;
                }
            })
        );
    }
}
