import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { IdNameModel } from '../../../services/service-models/idName';
import { LeadsServiceWrapper } from '../../../services/leads-service/leads.service.wrapper';
import { AlertService } from '../../../services/alert-service/alert.service';
import { AgencyServiceWrapper } from '../../../services/agency-service/agency.service.wrapper';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'leads-create-modal',
    templateUrl: './leads-create-modal.component.html',
    styleUrls: ['./leads-create-modal.component.scss'],
})
export class LeadsCreateModalComponent implements OnInit {
    agency: IdNameModel;
    campaign: IdNameModel;
    name: string;
    phoneNumber: string;
    email: string;
    comment: string;

    isCreatingLead: boolean;
    campaignsObservable: Observable<IdNameModel[]>;
    agenciesObservable: Observable<IdNameModel[]>;

    constructor(private leadsService: LeadsServiceWrapper, public activeModal: NgbActiveModal, private alertService: AlertService, private agencyService: AgencyServiceWrapper) {}

    ngOnInit(): void {
        this.agenciesObservable = this.agencyService.getAgencies().pipe(
            tap((agencies) => {
                if (agencies.length === 1) {
                    this.agency = agencies[0];
                    this.onAgencySelected();
                }
            })
        );
    }

    onAgencySelected() {
        this.campaignsObservable = this.leadsService.getLeadCampaigns(this.agency.id);
    }

    isStringFalsy(value: string): boolean {
        return !Boolean(value);
    }

    onClose() {
        this.activeModal.close();
    }

    onSave() {
        this.isCreatingLead = true;

        const nameSplit = this.name.split(' ');
        const surname = nameSplit.length === 1 ? null : nameSplit.pop();
        const firstName = nameSplit.length === 1 ? nameSplit[0] : nameSplit.join(' ');

        this.leadsService.createLead(this.agency.id, this.campaign.id, firstName, surname, this.email, this.phoneNumber, this.comment).subscribe(
            () => {
                this.onClose();
                this.isCreatingLead = false;
            },
            (error) => {
                this.alertService.showAlertFromError(error);
                this.isCreatingLead = false;
            }
        );
    }
}
