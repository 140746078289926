import { ILanguage } from './iLanguage';

export const danish: ILanguage = {
    translations: {
        AGENCIES: {
            Krifa: 'Krifa',
            Nem: 'NEM Forsikring',
            MS: 'Middelfart Sparekasse',
            opal: 'Brevis',
            HS: 'HS Forsikringsagentur',
            JFR: 'Jysk Forsikring Rådgivning A/S',
            MK: 'UNIVERS Forsikringsformidling',
            NEXT: 'NEXT FORSIKRING A/S',
            ICare: 'iCare Forsikring A/S',
            FODAN: 'NEM Forsikring',
            SKRON: 'Sparekassen Kronjylland',
            MARSH: 'Marsh privatforsikring',
            TLN: 'TLN',
            RIGPO: 'NEM Forsikring (afd.RP)',
        },
        BOOL: {
            true: 'Ja',
            false: 'Nej',
        },
        ENUMS: {
            PRODUCT_TYPE: {
                1: 'Hund',
                2: 'Bil',
                3: 'Hus',
                4: 'Indbo',
                5: 'Ulykke',
                6: 'Campingvogn',
                7: 'Knallert',
                8: 'Motorcykel',
                9: 'Vejhjælp',
                10: 'Fritidshus',
                11: 'Trailer',
                12: 'Rejse',
                13: 'Traktor',
                14: 'Landbo',
                15: 'Båd',
                16: 'Ejerskifte',
                17: 'Gruppeliv',
                18: 'Lønsikring',
                99: 'Ukendt',
            },
            COLLECTION_STATE: {
                0: 'Åben',
                1: 'Betalt',
                2: 'Afskrevet',
                3: 'Annulleret',
                4: 'Forfalden',
                5: 'Rykker',
            },
            EMAIL_TYPE: {
                1: 'Privat',
                2: 'Arbejde',
            },
            PAYMENT_METHOD: {
                0: 'Ukendt',
                1: 'Indbetalingskort',
                2: 'Betalingsservice',
            },
            PHONENUMBER_TYPE: {
                1: 'Fastnet',
                2: 'Mobil',
                3: 'Fax',
                4: 'Arbejde',
                99: 'Andet',
            },
            POLICY_INSTALMENT_PERIOD_TYPE: {
                0: 'Ingen',
                1: 'Årlig',
                2: 'Halvårlig',
                3: 'Kvartal',
                4: 'Månedlig',
                99: 'Ukendt',
            },
            POLICY_STATE: {
                0: 'Ukendt', // Unknown
                1: 'Aktiv', // Active
                2: 'Fremtidig ikraft', // Pending - not in use
                3: 'Ventende police', // Waiting policy
                4: 'Fejlet', // Failed
                5: 'Tilbud', // Offer
                6: 'Afgangsført', // Cancelled
                7: 'Slettet', // Deleted
                8: 'Fremtidig aktivering', // FutureActivation
                9: 'Fremtidig afgang', // FutureCancellation
                10: 'Ventende tilbud', // Waiting offer
            },
            POLICY_VERSION_STATE: {
                0: 'Fremtidig',
                1: 'Aktiv',
                2: 'Tidligere',
            },
            RELATION_TYPE: {
                0: 'Ukendt', // Unknown
                1: 'Samboende', // Cohabitants
                2: 'Barn af', // Child
                3: 'Forældre til', // Parent
                4: 'Familie til', // FamilyTo
                5: 'Ansat af', // EmployeeAt
                6: 'Ansat', // Employee
                7: 'Ejet af', // Owner
                8: 'Ejer af', // OwnerOf
                9: 'Kunde', // Customer
                10: 'Kunde ved', // CustomerAt
                11: 'Medlem', // Member
                12: 'Medlem ved', // MemberAt
            },
            UNPAID_FEE_STATE: {
                1: 'Betalt',
                2: 'Ikke betalt',
                3: 'Ingen registrering',
            },
            INSURANCE_SCOPE: {
                0: 'Ukendt',
                1: 'Fritid',
                2: 'Fuldtid',
                3: 'Barn',
            },
            PRODUCT_CATEGORY: {
                0: 'Boligforsikringer',
                1: 'Motorkøretøjsforsikringer',
                2: 'Andre forsikringer',
            },
            POLICY_TASK_STATUS: {
                1: 'Færdig',
                2: 'Aflyst',
                3: 'Afvist',
                4: 'Afventer',
                5: 'Ubehandlet',
                99: 'Ukendt',
            },
        },
    },
};
