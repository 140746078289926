import { LeadWrapupCommentDetails } from '@scalepoint/nemex-api-clients/services';

export class LeadWrapupCommentDetailsModel {
    readonly created?: Date;
    readonly text?: string | undefined;

    constructor(apiModel: LeadWrapupCommentDetails) {
        this.created = apiModel.created;
        this.text = apiModel.text;
    }
}
