import { SmsTemplate } from '@scalepoint/nemex-api-clients/services';

export class SmsTemplateModel {
    readonly id: number;
    readonly name: string | undefined;
    readonly text: string | undefined;
    readonly agencyId?: string | undefined;
    readonly created: Date;
    readonly deleted?: Date | undefined;
    readonly lastModified?: Date | undefined;

    constructor(apiSmsTemplate: SmsTemplate) {
        this.id = apiSmsTemplate.id;
        this.name = apiSmsTemplate.name;
        this.text = apiSmsTemplate.text;
        this.agencyId = apiSmsTemplate.agencyId;
        this.created = apiSmsTemplate.created;
        this.deleted = apiSmsTemplate.deleted;
        this.lastModified = apiSmsTemplate.lastModified;
    }
}
