import { ProductCategoryWithProducts } from '@scalepoint/nemex-api-clients/services';
import { ProductCategoryEnumDisplayModel } from './productCategoryEnumDisplay';
import { ProductInfoModel } from './productInfo';

export class ProductCategoryWithProductsModel {
    category?: ProductCategoryEnumDisplayModel;
    products?: ProductInfoModel[] | undefined;

    constructor(apiProductCategoryWithProducts: ProductCategoryWithProducts) {
        this.category = new ProductCategoryEnumDisplayModel(apiProductCategoryWithProducts.category);
        this.products = apiProductCategoryWithProducts.products.map(
            (p) => new ProductInfoModel(p.id, p.name, p.discountEntry, p.discountGiving, p.productType.enum.valueOf(), p.availableForSignup, p.availableForChange)
        );
    }
}
