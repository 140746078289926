import { Injectable, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Router, NavigationStart } from '@angular/router';
import { share } from 'rxjs/operators';

@Injectable()
export class LoadingService implements OnDestroy {
    @Output() loading: EventEmitter<boolean> = new EventEmitter();
    loadings: symbol[] = [];
    subscriptions: Subscription[] = [];

    constructor(private router: Router) {
        this.subscriptions.push(
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationStart) {
                    // clear loadings on page change
                    this.loadings = [];
                    this.loading.emit(false);
                }
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    setLoading(identifier: symbol, isLoading: boolean) {
        if (!isLoading) {
            this.loadings = this.loadings.filter((l) => l !== identifier);
        } else {
            this.loadings.push(identifier);
        }

        this.loading.emit(this.loadings.length > 0);
    }

    loadingObservable<T>(observable: Observable<T>): Observable<T> {
        const identifier = Symbol();
        this.setLoading(identifier, true);

        const sharedObservable = observable.pipe(share());

        this.subscriptions.push(
            sharedObservable.subscribe(
                () => {
                    this.setLoading(identifier, false);
                },
                () => {
                    this.setLoading(identifier, false);
                },
                () => {
                    this.setLoading(identifier, false);
                }
            )
        );

        return sharedObservable;
    }

    loadingPromise<T>(promise: Promise<T>): Promise<T> {
        const identifier = Symbol();
        this.setLoading(identifier, true);

        const newPromise = new Promise<T>((resolve, reject) => {
            promise.then((response) => {
                this.setLoading(identifier, false);
                resolve(response);
            });

            promise.catch((error) => {
                this.setLoading(identifier, false);
                reject(error);
            });
        });

        return newPromise;
    }
}
