import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThousandSeparatorPipe } from './thousandSeparatorPipe';
import { BackendTranslatePipe } from './backendTranslatePipe';
import { PhoneNumberPipe } from './phoneNumberPipe';
import { DkDatePipe } from './dkDatePipe';
import { LoadingPipe } from './loadingPipe';
import { DkDateTimePipe } from './dkDateTimePipe';
import { SumPipe } from './sumPipe';
import { CommaSeparatedPipe } from './commaSeparatedPipe';
import { MapSumPipe } from './mapSumPipe';
import { SortPipe } from './sortPipe';

@NgModule({
    declarations: [ThousandSeparatorPipe, BackendTranslatePipe, PhoneNumberPipe, DkDatePipe, LoadingPipe, DkDateTimePipe, SumPipe, CommaSeparatedPipe, MapSumPipe, SortPipe],
    imports: [CommonModule],
    exports: [ThousandSeparatorPipe, BackendTranslatePipe, PhoneNumberPipe, DkDatePipe, LoadingPipe, DkDateTimePipe, SumPipe, CommaSeparatedPipe, MapSumPipe, SortPipe],
})
export class PipesModule {}
