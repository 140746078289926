import { Questionnaire } from '@scalepoint/nemex-api-clients/services';

export class QuestionnaireModel {
    questionnaireId?: string | undefined;
    name?: string | undefined;

    constructor(apiQuestionnaire: Questionnaire) {
        this.questionnaireId = apiQuestionnaire.questionnaireId;
        this.name = apiQuestionnaire.name;
    }
}
