import { Injectable } from '@angular/core';
import { SmsTemplateService, IAddSmsTemplate, AddSmsTemplate, IUpdateSmsTemplate, UpdateSmsTemplate } from '@scalepoint/nemex-api-clients/services';
import { environment } from '../../../environments/environment';
import { SmsTemplateModel } from '../service-models/sms/smsTemplate';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class SmsTemplatesServiceWrapper {
    constructor(private smsTemplatesService: SmsTemplateService) {}

    getTemplate(templateId: number): Observable<SmsTemplateModel> {
        return this.smsTemplatesService.smsTemplatesGET(templateId, environment.apiVersion).pipe(map((t) => new SmsTemplateModel(t)));
    }

    getAllTemplates(): Observable<SmsTemplateModel[]> {
        return this.smsTemplatesService.smsTemplatesAll(environment.apiVersion).pipe(map((templates) => templates.map((t) => new SmsTemplateModel(t))));
    }

    createTemplate(name: string, text: string, agencyId: string): Observable<SmsTemplateModel> {
        const newTemplate: IAddSmsTemplate = {
            name,
            text,
            agencyId,
        };

        return this.smsTemplatesService.smsTemplatesPOST(environment.apiVersion, new AddSmsTemplate(newTemplate)).pipe(map((t) => new SmsTemplateModel(t)));
    }

    updateTemplate(templateId: number, name: string, text: string, agencyId: string): Observable<void> {
        const editTemplate: IUpdateSmsTemplate = {
            id: templateId,
            name,
            text,
            agencyId,
        };

        return this.smsTemplatesService.smsTemplatesPUT(environment.apiVersion, new UpdateSmsTemplate(editTemplate));
    }

    deleteTemplate(templateId: number): Observable<void> {
        return this.smsTemplatesService.smsTemplatesDELETE(templateId, environment.apiVersion);
    }
}
