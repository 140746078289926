import { CprResult } from '@scalepoint/nemex-api-clients/services';
import { CprModel } from '../cpr';
import { PersonNameModel } from '../personName';
import { AddressNormalOutputModel } from '../policy/addressNormalOutput';

export class CprResultModel {
    address?: AddressNormalOutputModel;
    person?: PersonNameModel;
    cpr?: CprModel;
    addressProtection?: boolean | undefined;
    male?: boolean;
    dateOfBirth?: Date;
    cprStatus?: string | undefined;
    cprMessage?: string | undefined;

    constructor(cprResult: CprResult) {
        this.address = cprResult.address ? new AddressNormalOutputModel(cprResult.address) : undefined;
        this.person = cprResult.person ? new PersonNameModel(cprResult.person) : undefined;
        this.cpr = cprResult.cpr ? new CprModel(cprResult.cpr) : undefined;
        this.addressProtection = cprResult.addressProtection;
        this.male = cprResult.male;
        this.dateOfBirth = cprResult.dateOfBirth;
        this.cprStatus = cprResult.cprStatus;
        this.cprMessage = cprResult.cprMessage;
    }
}
