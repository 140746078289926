import { Cpr } from '@scalepoint/nemex-api-clients/services';

export class CprModel {
    readonly cprBirthDate?: string | undefined;
    readonly cprSerial?: string | undefined;
    readonly cprFull?: string | undefined;

    constructor(apiCpr: Cpr) {
        this.cprBirthDate = apiCpr.cprBirthDate;
        this.cprSerial = apiCpr.cprSerial;
        this.cprFull = apiCpr.cprFull;
    }
}
