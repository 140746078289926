<div class="modal-header fade show alert alert-primary" role="alert">
    <div class="alert-text">
        <h5>{{ 'COMPONENTS.AGENCY_TEXT_MESSAGE_MODAL.TITLE' | translate }}</h5>
    </div>
</div>
<div class="modal-body" [ngClass]="{ bulk: hasBulkRole, single: !hasBulkRole }">
    <div class="data-row">
        <p class="row-label">{{ 'COMPONENTS.AGENCY_TEXT_MESSAGE_MODAL.AGENCY' | translate }}</p>
        <div ngbDropdown class="d-inline-block agency-select row-content">
            <button class="btn btn-outline-primary agency-option" ngbDropdownToggle>
                {{ selectedAgency ? selectedAgency.name : ('COMPONENTS.AGENCY_TEXT_MESSAGE_MODAL.CHOOSE_AGENCY' | translate) }}
            </button>
            <div ngbDropdownMenu>
                <button *ngFor="let agency of agencies" ngbDropdownItem (click)="onSelectAgency(agency)">{{ agency.name }}</button>
            </div>
        </div>
    </div>

    <div class="data-row" *ngIf="filteredSmsTemplates && filteredSmsTemplates.length > 0">
        <p class="row-label">{{ 'COMPONENTS.AGENCY_TEXT_MESSAGE_MODAL.TEMPLATE' | translate }}</p>
        <div ngbDropdown class="d-inline-block template-select row-content">
            <button class="btn btn-outline-primary template-option" ngbDropdownToggle>
                {{ selectedSmsTemplate ? selectedSmsTemplate.name : ('COMPONENTS.AGENCY_TEXT_MESSAGE_MODAL.CHOOSE_TEMPLATE' | translate) }}
            </button>
            <div ngbDropdownMenu>
                <button *ngFor="let template of filteredSmsTemplates" ngbDropdownItem (click)="onSelectTemplate(template)">{{ template.name }}</button>
            </div>
        </div>
    </div>

    <div class="data-row recipients-row">
        <p class="row-label">{{ uiTexts.recipients }}</p>
        <mat-form-field class="row-content">
            <mat-label>{{ uiTexts.recipientsPlaceholder }}</mat-label>
            <textarea matInput [placeholder]="uiTexts.recipientsExample" [(ngModel)]="phoneNumbersInput" (keyup)="validateUserInputs()" (blur)="onRecipientBlur()"></textarea>
        </mat-form-field>
    </div>

    <div class="data-row message-row">
        <p class="row-label">{{ 'COMPONENTS.AGENCY_TEXT_MESSAGE_MODAL.MESSAGE' | translate }}</p>
        <mat-form-field class="row-content">
            <mat-label>{{ 'COMPONENTS.AGENCY_TEXT_MESSAGE_MODAL.MESSAGE_PLACEHOLDER' | translate }}</mat-label>
            <textarea matInput [(ngModel)]="textMessage" (keyup)="validateUserInputs()"></textarea>
        </mat-form-field>
    </div>
    <div class="alert alert-danger" role="alert" *ngIf="textMessage && textMessage.length > 306">
        <div class="alert-text">{{ 'COMPONENTS.AGENCY_TEXT_MESSAGE_MODAL.MAX_CHARACTERS_MESSAGE' | translate }}</div>
    </div>
    <div class="alert alert-danger" role="alert" *ngIf="showInvalidPhoneNumbers && !hasBulkRole">
        <div class="alert-text">{{ 'COMPONENTS.AGENCY_TEXT_MESSAGE_MODAL.SINGLE.INVALID_PHONE_NUMBERS_MESSAGE' | translate }}</div>
    </div>
    <div class="alert alert-danger" role="alert" *ngIf="showInvalidPhoneNumbers && hasBulkRole">
        <div class="alert-text">{{ 'COMPONENTS.AGENCY_TEXT_MESSAGE_MODAL.BULK.INVALID_PHONE_NUMBERS_MESSAGE' | translate }}</div>
    </div>
</div>
<div class="modal-footer">
    <button
        type="button"
        [disabled]="!areUserInputsValid || isSending"
        class="btn btn-secondary"
        [ngClass]="{ 'kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--info': isSending }"
        (click)="onSend()"
    >
        {{ 'SHARED.COMMON.SEND' | translate }}
    </button>
    <button type="button" class="btn btn-secondary" (click)="onClose()">{{ 'SHARED.COMMON.CANCEL' | translate }}</button>
</div>
