import { EventEmitter, Injectable } from '@angular/core';
import {
    AgencyService,
    IScheduleCallbackInput,
    IWrapupLeadInput,
    LeadPullService,
    ScheduleCallbackInput,
    WrapupLeadInput,
    ICreateLeadInput,
    PersonNameInput,
    IPersonNameInput,
    CreateLeadInput,
    UserService,
    ICreateLeadContactInput,
    CreateLeadContactInput,
    INoContactInput,
    NoContactInput,
} from '@scalepoint/nemex-api-clients/services';
import { environment } from '../../../environments/environment';
import { Observable, of } from 'rxjs';
import { IdNameModel } from '../service-models/idName';
import { LeadDetailsModel } from '../service-models/lead/leadDetails';
import { map, share, tap } from 'rxjs/operators';
import { LeadPullDetailsModel } from '../service-models/lead/leadPullDetails';

@Injectable({
    providedIn: 'root',
})
export class LeadsServiceWrapper {
    private sessionStorageKey = 'leadPulls';
    private getNextLeadPullObservables: { [agencyId: string]: Observable<LeadPullDetailsModel> } = {};
    private leadPullsCache: { [agencyId: string]: LeadPullDetailsModel } = {};

    leadPulled: EventEmitter<{ leadPull: LeadPullDetailsModel; agencyId: string }> = new EventEmitter();
    leadPullClosed: EventEmitter<string> = new EventEmitter();

    constructor(private leadPullService: LeadPullService, private agencyService: AgencyService, private userService: UserService) {
        this.restoreLeads();
    }

    getActiveLeadPull(agencyId: string) {
        return this.leadPullsCache[agencyId];
    }

    markInitiated(leadPull: LeadPullDetailsModel): Observable<void> {
        if (leadPull.isNewContact) {
            leadPull.initiated = true;
            this.saveLeads();
            return of();
        }

        return this.leadPullService.quoteInitiated(leadPull.pullId, environment.apiVersion).pipe(
            tap(() => {
                leadPull.initiated = true;
                this.saveLeads();
            })
        );
    }

    noContact(pullId: string, comment: string): Observable<void> {
        const input: INoContactInput = {
            comment,
        };

        return this.leadPullService.nocontact(pullId, environment.apiVersion, new NoContactInput(input)).pipe(
            tap(() => {
                const agencyId = this.getAgencyId(pullId);
                this.clearCache(pullId);
                this.leadPullClosed.emit(agencyId);
            })
        );
    }

    wrapup(pullId: string, wrapupReasonId: string, comment: string): Observable<void> {
        const wrapupLeadInput: IWrapupLeadInput = {
            wraupReasonId: wrapupReasonId,
            comment,
        };

        return this.leadPullService.wrapup(pullId, environment.apiVersion, new WrapupLeadInput(wrapupLeadInput)).pipe(
            tap(() => {
                const agencyId = this.getAgencyId(pullId);
                this.clearCache(pullId);
                this.leadPullClosed.emit(agencyId);
            })
        );
    }

    scheduleCallback(pullId: string, callbackTime: Date, comment: string): Observable<void> {
        const scheduleCallback: IScheduleCallbackInput = {
            callbackTime,
            comment,
        };

        return this.leadPullService.callback(pullId, environment.apiVersion, new ScheduleCallbackInput(scheduleCallback)).pipe(
            tap(() => {
                const agencyId = this.getAgencyId(pullId);
                this.clearCache(pullId);
                this.leadPullClosed.emit(agencyId);
            })
        );
    }

    getLeadCampaigns(agencyId: string): Observable<IdNameModel[]> {
        return this.agencyService.leadCampaigns(agencyId, environment.apiVersion).pipe(map((campaigns) => campaigns.map((c) => new IdNameModel(c))));
    }

    getLeadBulkCampaigns(agencyId: string): Observable<IdNameModel[]> {
        return this.agencyService.leadBulkCampaigns(agencyId, environment.apiVersion).pipe(map((bulkcampaigns) => bulkcampaigns.map((c) => new IdNameModel(c))));
    }

    getWrapupReasons(agencyId: string): Observable<IdNameModel[]> {
        return this.agencyService.leadWrapupReasons(agencyId, environment.apiVersion).pipe(map((reasons) => reasons.map((r) => new IdNameModel(r))));
    }

    createLead(agencyId: string, campaignId: string, firstName: string, surName: string, email: string, phoneNumber: string, comment: string): Observable<LeadDetailsModel> {
        const personNameInput: IPersonNameInput = {
            firstName,
            surName,
        };

        const createLeadInput: ICreateLeadInput = {
            campaignId,
            personName: new PersonNameInput(personNameInput),
            email,
            phoneNumber,
            comment,
        };

        return this.agencyService.leads(agencyId, environment.apiVersion, new CreateLeadInput(createLeadInput)).pipe(map((details) => new LeadDetailsModel(details)));
    }

    createLeadContact(agencyId: string, campaignId: string, firstName: string, surName: string, phoneNumber: string, email?: string): Observable<LeadPullDetailsModel> {
        const personNameInput: IPersonNameInput = {
            firstName,
            surName,
        };

        const createLeadContactInput: ICreateLeadContactInput = {
            agencyId,
            campaignId,
            personName: new PersonNameInput(personNameInput),
            email,
            phoneNumber,
        };

        return this.userService.salesContacts(environment.apiVersion, new CreateLeadContactInput(createLeadContactInput)).pipe(
            map((details) => new LeadPullDetailsModel(details, true)),
            tap((leadPullDetails) => {
                this.leadPullsCache[agencyId] = leadPullDetails;
                this.saveLeads();
                this.leadPulled.emit({ leadPull: leadPullDetails, agencyId });
            })
        );
    }

    pullNextLead(agencyId: string): Observable<LeadPullDetailsModel> {
        const existingLead = this.leadPullsCache[agencyId];

        if (existingLead) {
            return of(existingLead);
        }

        // Share ongoing requests for the same agency
        const obs = this.getNextLeadPullObservables[agencyId];

        if (obs) {
            return obs;
        }

        this.getNextLeadPullObservables[agencyId] = this.userService.next(agencyId, environment.apiVersion).pipe(
            share(),
            map((details) => new LeadPullDetailsModel(details)),
            tap(
                (details) => {
                    this.leadPullsCache[agencyId] = details;
                    this.saveLeads();
                    this.leadPulled.emit({ leadPull: details, agencyId });
                    this.getNextLeadPullObservables[agencyId] = null;
                },
                () => {
                    this.getNextLeadPullObservables[agencyId] = null;
                },
                () => {
                    this.getNextLeadPullObservables[agencyId] = null;
                }
            )
        );

        return this.getNextLeadPullObservables[agencyId];
    }

    getCloseOfferReasons(): Observable<IdNameModel[]> {
        // TODO: Needs API implementation
        const reasons: IdNameModel[] = [
            {
                id: '1',
                name: 'For høj pris',
            },
            {
                id: '2',
                name: 'En anden grund',
            },
        ];

        return of(reasons);
    }

    closeOffer(offerId: string, closeOfferReason: string, productIds: string[]): Observable<void> {
        // TODO: Needs API implementation
        return of(null);
    }

    removeLead(pullId: string) {
        const agencyId = this.getAgencyId(pullId);
        this.clearCache(pullId);
        this.saveLeads();
        this.leadPullClosed.emit(agencyId);
    }

    private clearCache(pullId: string) {
        const agencyId = this.getAgencyId(pullId);

        if (agencyId) {
            this.leadPullsCache[agencyId] = null;
            this.saveLeads();
        }
    }

    private getAgencyId(pullId: string) {
        const entries = Object.entries(this.leadPullsCache);
        const pullKeyValue = entries.find((entry) => {
            return entry[1]?.pullId === pullId;
        });

        return pullKeyValue ? pullKeyValue[0] : null;
    }

    private saveLeads() {
        const leadsJsonString = JSON.stringify(this.leadPullsCache);
        sessionStorage.setItem(this.sessionStorageKey, leadsJsonString);
    }

    private restoreLeads() {
        const leadsString = sessionStorage.getItem(this.sessionStorageKey);
        if (leadsString) {
            this.leadPullsCache = JSON.parse(leadsString);
            // Convert lastUpdated from a string to an actual date.
            for (const agency in this.leadPullsCache) {
                for (const prop in this.leadPullsCache[agency]) {
                    if (prop === 'lastUpdated' && this.leadPullsCache[agency][prop]) {
                        this.leadPullsCache[agency][prop] = new Date(this.leadPullsCache[agency][prop]);
                    }
                }
            }
        }
    }
}
