// Angular
import { Injectable, OnDestroy } from '@angular/core';
// RxJS
import { BehaviorSubject, Subscription } from 'rxjs';
// Object path
import * as objectPath from 'object-path';
// Services
import { MenuConfigService } from './menu-config.service';

@Injectable()
export class MenuAsideService implements OnDestroy {
    // Public properties
    menuList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    subscriptions: Subscription[] = [];

    /**
     * Service constructor
     *
     * @param menuConfigService: MenuConfigService
     */
    constructor(private menuConfigService: MenuConfigService) {
        this.loadMenu();

        this.subscriptions.push(
            menuConfigService.onConfigUpdated$.subscribe(() => {
                this.loadMenu();
            })
        );

        this.subscriptions.push(
            this.menuConfigService.onConfigUpdated$.subscribe(() => {
                this.loadMenu();
            })
        );
    }

    /**
     * Load menu list
     */
    loadMenu() {
        // get menu list
        const menuItems: any[] = objectPath.get(this.menuConfigService.getMenus(), 'aside.items');
        this.menuList$.next(menuItems);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
