<div *ngIf="alertModel" class="modal fade show modal-overlay" tabindex="-1" role="dialog" aria-labelledby="alert-title" aria-modal="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header alert alert-{{ alertModel.theme }} fade show" role="alert">
                <div class="alert-icon"><i class="flaticon-warning"></i></div>
                <h5 class="alert-text">{{ alertModel.title }}</h5>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="alertModel.alertType === 1">
                    <p>{{ 'VIEWS.ALERT_DIALOG.ERROR_TEXT' | translate }}</p>

                    <ng-container *ngIf="alertModel.traceId">
                        <p>{{ 'VIEWS.ALERT_DIALOG.TRACE_DESCRIPTION' | translate }}</p>
                        <p>
                            <b>{{ 'VIEWS.ALERT_DIALOG.TRACE_ID' | translate }}</b> {{ alertModel.traceId }}
                        </p>
                    </ng-container>

                    <p>
                        <b>{{ 'VIEWS.ALERT_DIALOG.PHONE_LABEL' | translate }}</b> 88 81 80 00
                    </p>
                    <p>
                        <b>{{ 'VIEWS.ALERT_DIALOG.EMAIL_LABEL' | translate }}</b>
                        <a href="mailto:support@scalepoint.com"> support@scalepoint.com</a>
                    </p>
                </ng-container>

                <ng-container *ngIf="alertModel.alertType === 2">
                    <p>{{ 'VIEWS.ALERT_DIALOG.SESSION_EXPIRED' | translate }}</p>
                </ng-container>

                <ng-container *ngIf="alertModel.alertType === 3">
                    <p>{{ 'VIEWS.ALERT_DIALOG.CALCULATION_FAILED' | translate }}</p>

                    <ng-container *ngIf="alertModel.message">
                        <p>{{ alertModel.message }}</p>
                    </ng-container>

                    <ng-container *ngIf="alertModel.traceId">
                        <p>
                            <b>{{ 'VIEWS.ALERT_DIALOG.TRACE_ID' | translate }}</b> {{ alertModel.traceId }}
                        </p>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="alertModel.alertType === 4 || alertModel.alertType === 5">
                    <ng-container *ngIf="alertModel.title">
                        <p>
                            <b>
                                {{ alertModel.title }}
                            </b>
                        </p>
                    </ng-container>

                    <ng-container *ngIf="alertModel.message">
                        <p>{{ alertModel.message }}</p>
                    </ng-container>

                    <ng-container *ngIf="alertModel.traceId">
                        <p>
                            <b>{{ 'VIEWS.ALERT_DIALOG.TRACE_ID' | translate }}</b> {{ alertModel.traceId }}
                        </p>
                    </ng-container>
                </ng-container>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="closeAlert()">{{ 'VIEWS.ALERT_DIALOG.CLOSE' | translate }}</button>
            </div>
        </div>
    </div>
</div>
