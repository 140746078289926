import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';

@Injectable()
export class MatPaginatorIntlCustom extends MatPaginatorIntl {
    itemsPerPageLabel = this.getItemsPerPageLabel();
    nextPageLabel = this.getNextPageLabel();
    previousPageLabel = this.getPreviousPageLabel();
    firstPageLabel = this.getFirstPageLabel();
    lastPageLabel = this.getLastPageLabel();

    getLabels(lan: string): Labels {
        switch (lan) {
            case 'da': {
                return new Labels('Antal pr. side', 'Næste side', 'Forrige side', 'Første side', 'Sidste side', 'af');
            }
            case 'en': {
                return new Labels('Items pr. page', 'Next page', 'Previous page', 'First page', 'Last page', 'of');
            }
        }
    }

    getItemsPerPageLabel(): string {
        let lan = this.getLanguage();
        let labels = this.getLabels(lan);
        return labels._itemsPerPageLabel;
    }

    getNextPageLabel(): string {
        let lan = this.getLanguage();
        let labels = this.getLabels(lan);
        return labels._nextPageLabel;
    }

    getPreviousPageLabel(): string {
        let lan = this.getLanguage();
        let labels = this.getLabels(lan);
        return labels._previousPageLabel;
    }

    getFirstPageLabel(): string {
        let lan = this.getLanguage();
        let labels = this.getLabels(lan);
        return labels._firstPageLabel;
    }

    getLastPageLabel(): string {
        let lan = this.getLanguage();
        let labels = this.getLabels(lan);
        return labels._lastPageLabel;
    }

    getOf(): string {
        let lan = this.getLanguage();
        let labels = this.getLabels(lan);
        return labels._of;
    }

    getLanguage(): string {
        let lan = 'da';
        lan = localStorage.getItem('language');
        return lan;
    }

    getRangeLabel = function(page, pageSize, length) {
        let ofWord = this.getOf();
        if (length === 0 || pageSize === 0) {
            return '0 ' + ofWord + ' ' + length;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return startIndex + 1 + ' - ' + endIndex + ' ' + ofWord + ' ' + length;
    };
}

class Labels {
    _itemsPerPageLabel: string;
    _nextPageLabel: string;
    _previousPageLabel: string;
    _firstPageLabel: string;
    _lastPageLabel: string;
    _of: string;

    constructor(itemsPerPageLabel: string, nextPageLabel: string, previousPageLabel: string, firstPageLabel: string, lastPageLabel: string, of: string) {
        this._itemsPerPageLabel = itemsPerPageLabel;
        this._nextPageLabel = nextPageLabel;
        this._previousPageLabel = previousPageLabel;
        this._firstPageLabel = firstPageLabel;
        this._lastPageLabel = lastPageLabel;
        this._of = of;
    }
}
