import { AddressNormalOutput } from '@scalepoint/nemex-api-clients/services';

export class AddressNormalOutputModel {
    readonly streetName?: string | undefined;
    readonly houseNo?: string | undefined;
    readonly level?: string | undefined;
    readonly doorSide?: string | undefined;
    readonly zipCode?: string | undefined;
    readonly city?: string | undefined;
    placeName?: string | undefined;
    countryCode?: string | undefined;
    countryName?: string | undefined;
    coName?: string | undefined;
    readonly displayWithZipAndCity?: string | undefined;
    readonly displayWithoutZipAndCity?: string | undefined;

    constructor(apiAddressNormal: AddressNormalOutput) {
        this.streetName = apiAddressNormal.streetName;
        this.houseNo = apiAddressNormal.houseNo;
        this.level = apiAddressNormal.level;
        this.doorSide = apiAddressNormal.doorSide;
        this.zipCode = apiAddressNormal.zipCode;
        this.city = apiAddressNormal.city;
        this.placeName = apiAddressNormal.placeName;
        this.countryCode = apiAddressNormal.countryCode;
        this.countryName = apiAddressNormal.countryName;
        this.coName = apiAddressNormal.coName;
        this.displayWithZipAndCity = apiAddressNormal.displayWithZipAndCity;
        this.displayWithoutZipAndCity = apiAddressNormal.displayWithoutZipAndCity;
    }
}
