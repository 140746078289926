import { ServerErrorResult } from '@scalepoint/nemex-api-clients/services';

export class ServerErrorResultModel {
    traceId?: string | undefined;
    type?: string | undefined;
    title?: string | undefined;
    status?: number | undefined;
    detail?: string | undefined;
    instance?: string | undefined;
    readonly extensions?: { [key: string]: any } | undefined;

    constructor(apiServerErrorResult: ServerErrorResult) {
        this.traceId = apiServerErrorResult.traceId;
        this.type = apiServerErrorResult.type;
        this.title = apiServerErrorResult.title;
        this.status = apiServerErrorResult.status;
        this.detail = apiServerErrorResult.detail;
        this.instance = apiServerErrorResult.instance;
        this.extensions = apiServerErrorResult.extensions;
    }
}
