export const locale = {
    lang: 'en',
    data: {
        SHARED: {
            VALIDATION: {
                REQUIRED_FIELD: 'Required field',
                MIN_LENGTH_FIELD: 'Minimum field length:',
                MAX_LENGTH_FIELD: 'Maximum field length:',
            },
            COMMON: {
                YES: 'Yes',
                NO: 'No',
                UNKNOWN: 'Unknown',
                SAVE: 'Gem',
                CANCEL: 'Undo',
                CLOSE: 'Close',
                CHOOSE_TYPE: 'Select type',
                ERROR: 'Error',
                SEND: 'Send',
                CURRENCY_SHORT: 'kr.',
                DATE: 'Date',
                EMAIL: 'Email',
                STATUS: 'Status',
                ANSWERED: 'Answered',
                DISPATCHED: 'Sent',
                SCHEDULED: 'Scheduled',
                SEARCH: 'Search',
                NEXT: 'Next',
                OK: 'OK',
                LOADING: 'Loading ...',
                OWNER: 'Owner',
                USER: 'User',
                CO_USER: 'Secondary user',
                CLEAR_DATE: 'reset',
                ADD: 'add',
                NOT_FOUND: 'Not found',
                NAME: 'Navn',
                MOBILE_NO: 'Mobil nummer',
            },
            MENU: {
                FRONTPAGE: 'Front page',
                SEARCH: 'Search',
                SEARCH_CUSTOMER: 'Search customer',
                CUSTOMER_HANDLING: 'Customer management',
                CUSTOMER_OVERVIEW: 'Customer overview',
                DFIM: 'DFIM',
                TOOLS: 'Tools',
                DFIM_LOOKUP: 'DFIM lookup',
                CPR: 'CPR',
                CPR_LOOKUP: 'CPR lookup',
                DOCUMENTS: 'Documents',
                SETTINGS: 'Setup',
                FOLDERS: 'Folders',
                DOCUMENT_FOLDERS: 'Document folders',
                CREATE_CUSTOMER: 'Create customer',
                CREATE_OFFER: 'Create quotes',
                SMS_TEMPLATES: 'SMS texts',
                TEXT_MESSAGES: 'Send SMS',
                STATISTICS: 'statistics',
                QUESTIONNAIRE_REPORT: 'Questionnaire report',
                CALCULATE: 'Calculate',
                POLICY_MTA: 'Police change',
                DASHBOARDS: 'Dashboards',
                EDIT_DASHBOARDS: 'Set up dashboards',
                VIEW_DASHBOARD: 'Show dashboard',
                CUSTOMER_REVIEWS: 'Customer reviews',
                CREATE_LEAD: 'Create lead',
                CREATE_BULK_LEADS: 'Create bulk leads',
            },
        },
        COMPONENTS: {
            CUSTOMER_CONSENT: {
                CUSTOMER_CONSENT_GIVEN: 'The customer has given their consent',
                CUSTOMER_CONSENT_REJECTED: 'The customer has rejected their consent',
                CUSTOMER_CONSENT_WITHDRAWN: 'The customer has withdrawn their consent',
                CHOOSE_CONSENT_TYPE: 'Choose consent type',
                WARNING_LINE_1: 'Warning!!!',
                WARNING_LINE_2: 'The customer has to give consent, or we will not be able to keep him as a customer',
                ERROR_MESSAGE: 'An error occured. Refresh the page and try again.',
            },
            CUSTOMER_WARNING_MODAL: {
                TITLE: 'Warning',
                WARNING_QUESTION: 'Should there be a warning on the customer?',
            },
            BLOCKED_CPR_MODAL: {
                TITLE: 'The customer was rejected on the website!',
                BLOCKED_NOTE: 'Note! The customer attempted to register on the website, but was rejected',
                DATE: 'Date',
                REASON: 'Reason',
                REMARK: 'Remark',
            },
            CPR_MODAL: {
                TITLE: 'CPR lookup',
                NAME: 'Name',
                CPR: 'CPR',
                ADDRESS: 'Address',
                ADDRESS_PROTECTION: 'Address protected',
                CPR_STATUS: 'CPR status',
            },
            LEADS_SCHEDULED: {
                TITLE: 'Scheduled leads',
                CHOOSE_DATE: 'Choose date',
                CALL_BACK_TIME: 'Call back',
                NAME: 'Name',
                PHONE: 'Phone',
                COMMENTS: 'Comments',
                LEAD_COMMENT: 'Lead comment',
            },
            LEADS_CREATE_CONTACT_MODAL: {
                TITLE: 'Create contact',
                CAMPAIGN: 'Campaign',
                CREATE: 'Create',
            },
            LEADS_CLOSE_OFFER_MODAL: {
                TITLE: 'Close offer',
                CLOSE_REASON: 'Choose rejection reason',
                WHICH_PRODUCT: 'Which product',
            },
            LEADS_CLOSE_MODAL: {
                TITLE: 'Close lead',
                REASON: 'Reason',
                COMMENT: 'Comment',
                CLOSE_LEAD: 'Close lead',
            },
            LEADS_CALLBACK_MODAL: {
                TITLE: 'Call back later',
                DATE: 'Date',
                TIME: 'Time',
                COMMENT: 'Comment',
                CREATE: 'Create',
                VALIDATION_TEXT_TOO_EARLY: 'The time needs to be 5 minutes from now or later',
                CALLBACK_TO: 'Call back to',
            },
            LEADS_CREATE_MODAL: {
                TITLE: 'Create lead',
                AGENCY: 'Agency',
                CAMPAIGN: 'Campaign',
                NAME: 'Name',
                PHONE_NUMBER: 'Telephone',
                COMMENT: 'Comment',
                CREATE: 'Create',
                EMAIL: 'Email',
            },
            LEADS_BULK_CREATE_MODAL: {
                TITLE: 'Create bulk leads',
                AGENCY: 'Agency',
                CAMPAIGN: 'Campaign',
                CREATE: 'Create',
                DRAG_DROP_MESSAGE: 'Drag and drop a file here or click to choose one',
                DRAG_DROP_FILE_TYPES: 'Allowed file types: CSV',
                COLUMN_HELP_TEXT: 'Column format in row 1: FirstName;SurName;PhoneNumber;Email;Comment',
            },
            LEADS_NO_CONTACT_MODAL: {
                TITLE: 'Close lead with no contact',
                CLOSE_LEAD: 'Close lead',
                COMMENT: 'Comment',
                SMS_TYPE: 'SMS type',
                DANISH_SMS: 'Danish SMS',
                ENGLISH_SMS: 'English SMS',
                DANISH_SMS_MESSAGE: `
Hej [REPLACE_NAME]\n
Vi har forsøgt at ringe til dig angående forsikringstilbud, dog uden held.\n
Hvis dette har nogen interesse, kan du kontakte os på Tlf: 70 40 70 40\n
Med venlig hilsen\n
NEXT Forsikringsformidling
`,
                ENGLISH_SMS_MESSAGE: `
Hello [REPLACE_NAME],\n
We have tried to call you regarding insurance quotes, but without success.\n
If this has any interest, you can contact us on Tel: 70 40 70 40\n
Yours sincerely,\n
NEXT Insurance brokerage
`,
            },
            LEADS_REMOVE_MODAL: {
                TITLE: 'Remove lead',
                TEXT: 'Are you sure you want to remove the lead?',
                CLOSE_LEAD: 'Remove lead',
            },
            LEADS_COMPONENT: {
                TITLE: 'Leads',
                WRAPUP_REASON: 'Reason for rejection',
                CHOOSE_WRAPUP_REASON: 'Select reason for rejection',
                CANCEL_LEAD: 'Reject lead',
                CREATE_LEAD: 'New lead',
                CLOSE_LEAD: 'Close lead',
                REMOVE_LEAD: 'Remove lead',
                PHONE: 'Phone number',
                LEAD_ID: 'Reference ID',
                LEAD_REFERENCE: 'Reference reference',
                WRAPUP_LEAD_FAILED_MESSAGE: 'Could not reject lead. Please try again.',
                GET_LEAD_FAILED_MESSAGE: 'Could not fetch lead. Please try again.',
                GET_LEAD_NO_MORE_LEADS_MESSAGE: 'There were no more leads.',
                CLOSE_LEAD_FAILED_MESSAGE: 'Failed to complete lead. Please try again.',
                CREATE_CONTACT: 'Create contact',
                CAMPAIGN: 'Campaign',
                COMMENT: 'Comment',
                GET_LEAD: 'Get lead',
                NAME: 'Name',
                CALL_BACK: 'Call back',
                NO_CONTACT: 'No contact',
                ADDITIONAL_COMMENT: 'Additional comment',
                EMAIL: 'Email',
                LAST_UPDATED: 'Last updated',
                STATUS: 'Status',
                CALL_TRIES: 'Call tries',
            },
            INVOICES_COMPONENT: {
                POLICY: 'Police',
                PRODUCT: 'Product',
                INVOICE_DATE: 'Invoice date',
                DUE_DATE: 'Payment date',
                AMOUNT: 'Amount',
                STATUS: 'Status',
            },
            EDIT_CASE_WORKER_MODAL: {
                TITLE: 'Edit company information',
                CHOOSE_CASE_WORKER: 'Choose caseworker',
                REFERRAL: 'Reference',
                SAVE: 'Gem',
                CANCEL: 'Undo',
                ERROR: 'An error occurred - please try again.',
            },
            EDIT_ADDRESS_MODAL: {
                TITLE: 'Edit customer information',
                EDIT_ADDRESS: 'Edit address',
                ADDRESS_SEARCH: 'Address finder',
                ADDRESS_INPUT: 'Address fields',
                ADDRESS_FOREIGN: 'foreign address',
                STREET_NAME: 'Street name',
                HOUSE_NO: 'House number',
                LEVEL: 'Floor',
                DOOR_SIDE: 'Door',
                ZIP_CODE: 'Zip code',
                CITY: 'Town',
                ADDRESS_LINES: 'Enter address',
                COUNTRY_CODE: 'Country code',
                SAVE: 'Gem',
                CANCEL: 'Undo',
                ERROR: 'An error occurred - please try again.',
            },
            DOCUMENT_NOTE_MODAL: {
                TITLE: 'Note',
                FROM: 'From',
                CHANNEL: 'Channel',
                DESCRIPTION: 'Description',
                SPOKE_ABOUT: 'Talked about',
                CONCLUSION: 'Conclusion',
                WHEN: 'Date',
                CLOSE: 'close',
            },
            CUSTOMER_DOCUMENTS_COMPONENT: {
                DOCUMENT: 'Document',
                DATE: 'Date',
                CREATED_BY: 'Created by',
                ADD: 'add',
                UPLOAD_NOTICE: 'Please note that the documents are available in the customer&#39;s self-service.',
            },
            UPLOAD_DOCUMENT_MODAL: {
                TITLE: 'Upload file',
                UPLOAD: 'Upload',
                CANCEL: 'Undo',
                DRAG_DROP_MESSAGE: 'Drag files here or click to select a file',
                DRAG_DROP_FILE_TYPES: 'Allowed file types: PDF, Word, TXT, JPG, BMP, GIF',
            },
            CREDIT_INFORMATION_MODAL: {
                TITLE: 'RKI storage',
                NO_REGISTRATIONS_MESSAGE: 'There are no registrations on the customer.',
                CREDITOR: 'Creditor',
                AMOUNT: 'Amount',
                HANDOFF_DATE: 'Transmission date',
                DELETED_DATE: 'Deleted date',
                CLOSE: 'close',
            },
            CUSTOMER_TEXT_MESSAGE_MODAL: {
                TITLE: 'Send SMS',
                CHOOSE_PHONE_NUMBER: 'Select number',
                YES: 'Yes',
                NO: 'No',
                CHOOSE_ALLOW_REPLY: 'Possibility of answering?',
                RECIPIENT_PHONE_NUMBER: 'Recipient number',
                TEMPLATE: 'Template',
                CHOOSE_TEMPLATE: 'Select template',
                NO_PHONE_NUMBERS: 'There are no Mobile Phone numbers created for this customer.',
            },
            QUESTIONNAIRE_MODAL: {
                TITLE: 'Send questionnaire',
                RECIPIENT: 'Recipient',
                SCHEMA: 'Schedule',
                TIME: 'Point in time',
                PREVIOUS_FUTURE_SENT: 'Past / future dispatch',
                DATE: 'Date',
                STATUS: 'Status',
                CHOOSE_EMAIL: 'Select email',
                CHOOSE_SCHEMA: 'Select schedule',
                CHOOSE_DATE: 'Choose a time',
                SEE_ANSWERS: 'See answer',
                BACK: 'Back to questionnaires',
                CLOSE: 'close',
                ANSWER_TITLE: 'Questionnaire answers:',
                QUESTION: 'Questions',
                ANSWER: 'Reply',
                NO_ANSWERS_FOUND: 'No answer found.',
                GET_ANSWERS_ERROR: 'An error occurred while loading replies.',
            },
            POLICY_AGREEMENT_MODAL: {
                TITLE: 'Police change',
                DISCOUNT_AGREEMENT: 'Discount agreement',
                GO_TO_MTA: 'Go to policy change',
                EDIT_POLICY_CREATION: 'Edit policy creation',
                FUTURE_EDIT_WARNING:
                    'NOTE! There is a future change. You must remember that if you want to make a change that will also apply to future events, then after the change - you must implement a new change for each future event. This is not possible via SalesPortal - contact customer service.',
                CANCEL_POLICY: 'Cancel policy',
                LOADING_FAILED: 'An error occurred while loading the policy',
            },
            POLICY_CANCEL_MODAL: {
                TITLE: 'Cancel policy',
                REASON: 'Reasons',
                REASON_REMARK: 'Remark',
                CANCEL_DATE: 'Event date',
                CHOOSE_DATE: 'Select date',
                CANCEL_ERROR: 'There was an error. The policy was not canceled.',
            },
            EDIT_FOLDER_MODAL: {
                TITLE: 'Folder setup',
                NAME: 'Name',
                TYPE_NAME: 'Enter name',
                TYPE: 'Type',
                LIMITED: 'Limited',
                OPEN: 'Open',
                CHOOSE_TYPE: 'Select type',
                CHOOSE_AGENCIES: 'Select agencies',
                CHOOSE_USERS: 'Select users',
                AGENCIES_ACCESS: 'Agency access',
                USERS_ACCESS: 'User access',
                SAVE: 'Gem',
                CANCEL: 'Undo',
                FOLDER_ALREADY_EXISTS_MESSAGE: 'There is already a folder with the selected name. Please select another.',
                CLOSE_WITHOUT_SAVING_MESSAGE: 'You have made changes that have not been saved. Are you sure you want to close?',
                CLOSE_WITHOUT_SAVING: 'Close without saving',
            },
            MOTORCARD_MODAL: {
                TITLE: 'Generate document',
                GREEN_CARD: 'Green card',
                RED_CARD: 'Red card',
                VALID_FROM: 'valid from',
                VALID_TO: 'Valid until',
                DOWNLOAD_DOCUMENT: 'Download document',
                CANCEL: 'Undo',
                CHOOSE_DATE: 'Select date',
            },
            CUSTOMER_CLAIMS_COMPONENT: {
                DATE: 'Date',
                PRODUCT: 'Product',
                POLICY_NO: 'Police no.',
                DESCRIPTION: 'Description',
                STATUS: 'Status',
            },
            CUSTOMER_OFFERS_COMPONENT: {
                OFFER_NO: 'Offer number',
                CREATED_BY: 'Created by',
                PRODUCTS: 'Products',
                STATUS: 'Status',
                PRICES: 'Prices',
                TOTAL_PRICE: 'Price (total)',
                ACTIONS: 'Actions',
                DELETE: 'Delete',
            },
            DFIM_MODAL: {
                TITLE: 'DFIM lookup',
            },
            AGENCY_TEXT_MESSAGE_MODAL: {
                TITLE: 'Send SMS',
                CHOOSE_AGENCY: 'Select agency',
                AGENCY: 'Agency',
                MESSAGE: 'Message',
                MESSAGE_PLACEHOLDER: 'write message',
                MAX_CHARACTERS_MESSAGE: 'You must enter a maximum of 306 characters',
                TEMPLATE: 'Template',
                CHOOSE_TEMPLATE: 'Select template',
                SINGLE: {
                    RECIPIENTS: 'Recipient',
                    RECIPIENTS_PLACEHOLDER: 'Enter recipient',
                    RECIPIENTS_EXAMPLE: 'Eg. &quot;12345678&quot;',
                    INVALID_PHONE_NUMBERS_MESSAGE: 'The phone number is in an invalid format. Make sure that only numbers are entered and it is 8 digits long.',
                },
                BULK: {
                    RECIPIENTS: 'Recipients',
                    RECIPIENTS_PLACEHOLDER: 'Enter recipients',
                    RECIPIENTS_EXAMPLE: 'To send to multiple recipients, you can separate numbers with commas, semicolons, spaces, or new lines. Eg. &quot;12345678,12345679&quot;',
                    INVALID_PHONE_NUMBERS_MESSAGE:
                        'A phone number is in an invalid format. Make sure it is 8 digits long and that subsequent numbers are separated by commas, semicolons, spaces or new lines.',
                },
            },
            CUSTOMER_OFFER_DELETE_MODAL: {
                TITLE: 'Delete offer',
                REASON: 'Reasons',
                CHOOSE_REASON: 'Choose justification',
                REMARK: 'Remark',
                REMARK_PLACEHOLDER: 'Write if necessary. a remark',
                GENERIC_ERROR_MESSAGE: 'Sorry, there was an error.',
                DELETE: 'Delete',
                CANCEL: 'Undo',
            },
            PAYMENT_SERVICE_MODAL: {
                TITLE: 'Payment service',
                TYPE: 'Type',
                SUBSCRIBE: 'Sign up',
                UNSUBSCRIBE: 'Unsubscribe',
                POLICIES: 'Policer',
                CHOOSE_POLICIES: 'Select one or more policies',
                REG_NO: 'Reg. no.',
                REG_NO_PLACEHOLDER: 'Enter reg. no.',
                ACCOUNT_NO: 'Account no.',
                ACCOUNT_NO_PLACEHOLDER: 'Enter account no.',
                EXECUTE: 'Perform',
                SUBSCRIBE_POSTFIX: 'registration',
                UNSUBSCRIBE_POSTFIX: 'deregistration',
                STATUS_SUCCESS: 'The following policy numbers were successfully executed:',
                STATUS_FAIL: 'The following policy numbers failed:',
                STATUS_NO_POLICIES: '- no policies -',
            },
        },
        SERVICES: {
            ALERT_SERVICE: {
                ERROR: 'Error',
            },
            CUSTOMER_SERVICE: {
                INVALID_CPR_TITLE: 'Invalid CPR',
                INVALID_CPR_MESSAGE: 'No information could be retrieved on the entered civil registration number. Check if it is a valid civil registration number.',
            },
        },
        VIEWS: {
            ALERT_DIALOG: {
                ERROR_TEXT: 'Unfortunately, an error occurred. Please try again. If the error persists, you are welcome to contact technical support.',
                TRACE_DESCRIPTION: 'Provide error ID at contact.',
                TRACE_ID: 'Error ID',
                PHONE_LABEL: 'Telephone:',
                EMAIL_LABEL: 'Email:',
                SESSION_EXPIRED: 'Your login session has expired. Log in again.',
                CLOSE: 'close',
                CALCULATION_FAILED: 'The calculation failed. Try adjusting the information entered.',
            },
            EDIT_EMAILS_MODAL: {
                TITLE: 'Edit email',
                PRIVATE: 'Private',
                WORK: 'Occupation',
                EMAIL_PLACEHOLDER: 'Enter your email',
                UNSAVED_CHANGES: 'There are unsaved changes. Are you sure you want to close?',
                INVALID_INPUTS: 'You must remember to fill in all fields before you can save.',
                SAVE: 'Gem',
                CLOSE_NO_SAVE: 'Close without saving',
                CLOSE: 'close',
            },
            EDIT_RELATIONS_MODAL: {
                TITLE: 'Edit relationships',
                ADD_RELATION_PLACEHOLDER: 'Enter customer number, CPR or CVR',
                UNSAVED_CHANGES: 'There are unsaved changes. Are you sure you want to close?',
                INVALID_INPUTS: 'You must remember to fill in all fields before you can save.',
                SAVE: 'Gem',
                CLOSE_NO_SAVE: 'Close without saving',
                CLOSE: 'close',
                RELATION: 'Relation',
                NAME: 'Name',
                ADDRESS: 'Address',
                CUSTOMER_NO: 'Customer number',
                CHANGE: 'Change',
                CHANGE_DELETE: 'Deleted',
                CHANGE_CREATE: 'Added',
                CHANGE_NONE: 'None',
                DELETE: 'Delete',
                ADD: 'add',
                CANNOT_CREATE_CUSTOMER: 'Unfortunately, the customer could not be created. Are you sure the information is correct?',
                CANNOT_ADD_SAME_CUSTOMER_RELATION: 'It is not allowed to add a relation to oneself.',
            },
            EDIT_PHONES_MODAL: {
                TITLE: 'Edit phone number',
                MOBILE: 'Mobile phone',
                WORK: 'Occupation',
                LANDLINE: 'Landline',
                FAX: 'Fax',
                PHONE_PLACEHOLDER: 'Enter number',
                UNSAVED_CHANGES: 'There are unsaved changes. Are you sure you want to close?',
                INVALID_INPUTS: 'You must remember to fill in all fields before you can save.',
                INVALID_NUMBER: 'Invalid number entered.',
                SAVE: 'Gem',
                CLOSE_NO_SAVE: 'Close without saving',
                CLOSE: 'close',
            },
            LAST_VISITED_USERS: {
                USER: 'User',
                DATE: 'Date',
            },
            TIMEOUT_WARNING: {
                TITLE: 'You have been inactive for a long time.',
                TEXT: 'For security reasons, you will be logged out if you do nothing.',
                COUNTDOWN_TEXT: 'You will be automatically logged out',
                LOG_OFF: 'Logout',
                STAY: 'Stay on the page',
            },
            NOTES: {
                DATE: 'Date',
                NOTE: 'Note',
                CREATE_NOTE: 'Create note',
                CREATE_NOTE_PLACEHOLDER: 'Enter note',
            },
            POLICY: {
                SHARED: {
                    SECONDARY_POLICY_HOLDER: 'Secondary policyholder',
                },
                CHANGE_OF_OWNERSHIP: {
                    CHANGE_OF_OWNERSHIP_INSURANCE: 'Change of ownership insurance',
                },
                CARAVAN_DETAILS: {
                    CARAVAN_INSURANCE: 'Caravan insurance',
                    USAGE_AND_TYPE: 'Type',
                    BRAND: 'Brand',
                    MODEL: 'Model',
                    MODELYEAR: 'Year',
                    MARKETVALUE: 'Trade value',
                    REGNO: 'Registration number',
                    CHASSISNO: 'Chassis number',
                    STATIONARY: 'Stagnant',
                    SUPPLEMENTARY_INFO: 'Additional Information',
                },
                CAR_DETAILS: {
                    CAR_INSURANCE: 'Car insurance',
                    BRAND: 'Brand',
                    MODEL: 'Model',
                    VARIANT_NAME: 'Variant',
                    MODEL_YEAR: 'Model year',
                    MOTOR_SIZE: 'Engine size',
                    WEIGHT: 'Net weight (kg)',
                    HORSE_POWER: 'Hk',
                    CHASSIS_TYPE: 'Bodywork',
                    NEW_VALUE: 'New value',
                    YEAR_OF_FIRST_REGISTRATION: '1st year of registration',
                    REG_NO: 'Registration number',
                    CHASSIS_NUMBER: 'Chassis number',
                    VEHICLE_ID: 'Vehicle ID',
                    VEHICLE_MMVT: 'VehicleMMVT',
                    USAGE_AND_TYPE_NAME: 'Usage and type',
                    STAND_STILL: 'Stagnation',
                    LEASING: 'Leasing',
                    MAX_KM_PR_YEAR_NAME: 'Max. km. pr. year',
                    CURRENT_METER_READING: 'Km-stand',
                    DATE_OF_METER_READING: 'Reading date',
                    SUPPLEMENTARY_INFO: 'Additional Information',
                    BONUS_CLASS: 'Bonus class',
                },
                MOPED_DETAILS: {
                    MOPED_INSURANCE: 'Moped insurance',
                    USAGE_AND_TYPE_NAME: 'Type',
                    BRAND: 'Brand',
                    MODEL: 'Model',
                    REGNO: 'Registration number',
                    CHASSISNO: 'Chassis number',
                    VEHICLE_ID: 'Vehicle side',
                    YEAR_OF_FIRST_REGISTRATION: 'Year',
                    MARKET_VALUE: 'Trade value',
                },
                MC_DETAILS: {
                    MC_INSURANCE: 'Motorcycle insurance',
                    BRAND: 'Brand',
                    MODEL: 'Model',
                    NEWVALUE: 'New value',
                    REGNO: 'Registration number',
                    CHASSISNO: 'Chassis number',
                    LEASING: 'Leasing',
                    BONUSCLASS: 'Bonustrin',
                    DRIVING_PERIODE_NAME: 'Driving period',
                    SUPPLEMENTARY_INFO: 'Additional Information',
                },
                SALARY_DETAILS: {
                    SALARY_INSURANCE: 'Wage insurance',
                    TRANSFEREDFROMOTHERCOMPANY: 'Is the insurance transferred from another company',
                    COMPANYNAME: 'Company name',
                    SENIORITYMONTHS: 'Seniority in previous company (months)',
                    TOTALCOVERSUMPRMONTH: 'Total cover amount in previous company (per month)',
                    ID: 'ID',
                    NAME: 'Name',
                    OCCUPATION: 'Employment',
                    COVER_PER_MONTH: 'Coverage amount',
                    DEDUCTIBLE_MONTHS: 'Deductible months',
                    PAYOUTMONTHS: 'Payout months',
                    CUSTOMER_NO: 'Customer number',
                    BIRTHDATE: 'Date of birth',
                    AGE: 'Age',
                    MONTHS: 'months',
                    CURRENCY: 'kr',
                    NUMBER: 'Number',
                },
                TRACTOR_DETAILS: {
                    TRACTOR_INSURANCE: 'Tractor insurance',
                    BRAND: 'Brand',
                    MODEL: 'Model',
                    YEAR_OF_FIRST_REGISTRATION: 'Year',
                    NEWVALUE: 'New value',
                    REGNO: 'Registration number',
                    CHASSISNO: 'Chassis number',
                    USAGE: 'Application',
                },
                TRAILER_DETAILS: {
                    TRAILER_INSURANCE: 'Trailer insurance',
                    USAGE_AND_TYPE: 'Application and type',
                    BRAND: 'Brand',
                    MODEL: 'Model',
                    YEAR_OF_FIRST_REGISTRATION: 'Year',
                    MARKET_VALUE: 'Market value',
                    REGNO: 'Registration number',
                    CHASSISNO: 'Chassis number',
                },
                ACCIDENT_DETAILS: {
                    ACCIDENT_INSURANCE: 'Accident insurance',
                    NAME: 'Name',
                    CUSTOMER_NO: 'Customer number',
                    DEGREE_PERMANENT_INJURY: 'Permanent degree of damage',
                    INSURANCE_SCOPE: 'Type',
                    OCCUPATION: 'Employment',
                    CLOSEST_RELATIVE_AS_BENEFICIEARY: 'Police favor immediate family member',
                    BENEFICIERAY_PERCENTAGE: 'Benefit rate',
                    BENEFICIEARY: 'Beneficiary',
                    RISKS: 'Special risks',
                },
                DOG_DETAILS: {
                    DOG_INSURANCE: 'Dog insurance',
                    CALLNAME: 'Name',
                    RACE: 'Race',
                    PEDIGREENAME: 'Pedigree name',
                    BIRTHDATE: 'Date of birth',
                    GENDER: 'Sex',
                    CHIP_NUMBER: 'Chip number',
                },
                HOUSE_DETAILS: {
                    HOUSE_INSURANCE: 'Home insurance',
                    INSURANCE_PLACE: 'Place of insurance',
                    USAGE_AND_TYPE: 'Application and type',
                    TOTAL_RESIDENCE_AREA: 'Area, residential',
                    TOTAL_BUSINESS_AREA: 'Area, occupation',
                    TOTAL_GARAGE_AREA: 'Area, garage',
                    TOTAL_AREA_EXCLUDING_BASEMENT: 'Total area excl. cellar',
                    SUM_FOR_STAMP_DUTY: 'Stamp duty',
                    BUILDINGS: 'Buildings',
                    BUILDING_NO: 'Building number',
                    BUILDING_STATUS_NAME: 'Building status',
                    EXPECTED_COMPLETION_DATE: 'Expected completion date',
                    USAGE_NAME: 'Application',
                    HEATING_NAME: 'Form of heating',
                    ROOF_TYPE_NAME: 'Roof type',
                    RESIDENCE_AREA: 'Area, residential',
                    CONSTRUCTION_YEAR: 'Year of construction',
                    BUSINESS_AREA: 'Area, occupation',
                    GARAGE_AREA: 'Area, garage',
                    BASEMENT: 'Cellar',
                    FREE_HEATING_TEXT: 'Form of heating (free text)',
                    FREE_ROOF_TYPE_TEXT: 'Roof type (free text)',
                    RENOVATION_YEAR_ROOF: 'Renovation year, roof',
                    RENOVATION_YEAR_WINDOWS_AND_DOORS: 'Renovation year, windows and doors',
                    RENOVATION_YEAR_WIRES: 'Renovation year, wiring',
                    RENOVATION_YEAR_PIPES: 'Renovation year, pipes',
                    SUPPLEMENTARY_INFO: 'Additional Information',
                },
                FARMHOUSE_DETAILS: {
                    NAME: 'Insurance name',
                    STAMP_DUTY: 'Stamp duty',
                    INSURANCE_PLACE: 'Place of insurance',
                    USAGE_AND_TYPE: 'Application and type',
                    TOTAL_RESIDENCE_AREA: 'Area, residential',
                    TOTAL_BUSINESS_AREA: 'Area, occupation',
                    TOTAL_GARAGE_AREA: 'Area, garage',
                    TOTAL_AREA_EXCLUDING_BASEMENT: 'Total area excl. cellar',
                    SUM_FOR_STAMP_DUTY: 'Stamp duty',
                    BUILDINGS: 'Buildings',
                    BUILDING_NO: 'Building number',
                    BUILDING_STATUS_NAME: 'Building status',
                    EXPECTED_COMPLETION_DATE: 'Expected completion date',
                    USAGE_NAME: 'Application',
                    HEATING_NAME: 'Form of heating',
                    ROOF_TYPE_NAME: 'Roof type',
                    RESIDENCE_AREA: 'Area, residential',
                    CONSTRUCTION_YEAR: 'Year of construction',
                    BUSINESS_AREA: 'Area, occupation',
                    GARAGE_AREA: 'Area, garage',
                    BASEMENT: 'Cellar',
                    FREE_HEATING_TEXT: 'Form of heating (free text)',
                    FREE_ROOF_TYPE_TEXT: 'Roof type (free text)',
                    RENOVATION_YEAR_ROOF: 'Renovation year, roof',
                    RENOVATION_YEAR_WINDOWS_AND_DOORS: 'Renovation year, windows and doors',
                    RENOVATION_YEAR_WIRES: 'Renovation year, wiring',
                    RENOVATION_YEAR_PIPES: 'Renovation year, pipes',
                    SUPPLEMENTARY_INFO: 'Additional Information',
                    HOUSE_INSURANCE: 'Home insurance',
                    HOME_INSURANCE: 'Home contents insurance',
                    DOG_INSURANCE: 'Dog insurance',
                    OUTHOUSE_INSURANCE: 'Outhouse insurance',
                    INSURANCE_SUM: 'Sum insured',
                    VAT_REGISTERED: 'VAT registered',
                    HAY_AND_STRAW: 'Hay and straw',
                    INSURANCE_TYPE: 'Type of insurance',
                },
                HOME_DETAILS: {
                    HOME_INSURANCE: 'Home contents insurance',
                    INSURANCE_PLACE: 'Place of insurance',
                    HOUSING_TYPE_NAME: 'Housing type',
                    ROOF_TYPE_NAME: 'Roof type',
                    NUMBER_OF_OCCUPANTS: 'People in the household',
                    RESIDENCE_AREA: 'Living area',
                    BUSINESS_AREA: 'Business area',
                    GARAGE_AREA: 'Garage and outbuildings',
                    OVERWRITE_CALCULATED_AREA_VALUES: 'Overwrite calculated areas',
                    SUPPLEMENTARY_INFO: 'Additional Information',
                    INSURED_ITEMS_BIKES: 'Extended bicycle coverage',
                    BIKE: 'Bike',
                    BIKE_FRAME_NO: 'Chassis number',
                },
                BOAT_DETAILS: {
                    BOAT_INSURANCE: 'Boat insurance',
                    BOAT_TYPE: 'Boat type',
                    CERTIFICATION: 'Boat driver certification',
                    CERTIFICATION_5: 'Over 5 years of experience and no injuries the last 3 years',
                    CERTIFICATION_10: 'Over 10 years of experience and no injuries the last 3 years',
                    CERTIFICATION_20: 'Over 20 years of experience and no injuries the last 3 years',
                    CERTIFICATION_NONE: 'None',
                    USED_IN_SALTWATER: 'Used in salt water',
                    USED_IN_FRESHWATER: 'Used in fresh water',
                    MOTORIZED: 'Has engine',
                    SPEEDBOAT: 'Speed-boat',
                    MOTOR_YEAR: 'Engine year',
                    MOTOR_HORSEPOWER: 'Horsepower',
                    LENGTH_FOOT: 'Length in feet',
                    MAX_SPEED_KNOB: 'Top speed (knots)',
                    CONSTRUCTION_YEAR: 'Year',
                    LOCATION_HARBOR_REMARK: 'Location note',
                    MOTOR_SERIAL_NO: 'Engine serial number',
                    MOTOR_BRAND: 'Engine brand',
                    FUSELAGE_SERIAL: 'Hull serial number',
                    BOAT_NAME: 'Name',
                    HARBOR: 'Harbor',
                    LOCATION: 'Location',
                    SAILING_AREA: 'Sailing area',
                    SAILING_PERIOD: 'Sailing period',
                    MOTOR_TYPE: 'Engine type',
                    FUSELAG: 'Hull',
                    BRAND: 'Brand',
                    MODEL: 'Model',
                    BUILD_NO: 'Building number',
                    SUPPLEMENTARY_INFO: 'Additional information',
                },
            },
        },
        PAGES: {
            CPR: {
                SEARCH: 'Search',
                AGENCY: 'Agency',
                SEARCH_CPR: 'Search using CPR',
                CPR: 'CPR',
                CPR_STATUS: 'CPR status',
                INVALID_INPUTS_MESSAGE: 'Please fill out all required fields',
                EXTENDED_SEARCH: 'Extended search',
                PROTECTED_ADDRESS_WARNING: 'The person is address protected. You need to use "Extended address protected search"',
                EXTENDED_ADDRESS_PROTECTED_SEARCH: 'Extended address protected search',
                FULL_NAME: 'Full name',
                SEARCH_RESULT: 'Search result',
                NAME: 'Name',
                ADDRESS: 'Address',
                ADDRESS_PROTECTION: 'Address protected',
                ADVERTISEMENT_PROTECTION: 'Advertisement protected',
            },
            CUSTOMER_REVIEWS: {
                SEND_CUSTOMER_REVIEWS: 'Send customer reviews',
                AGENCIES: 'Agencies',
                CHOOSE_AGENCY: 'Choose agency',
                DRAG_DROP_MESSAGE: 'Drag files here or click to choose',
                DRAG_DROP_FILE_TYPES: 'Allowed file types: CSV',
                HELP_TEXT_COLUMNS: 'Columns: ConsumerEmail,ReferenceNumber,ConsumerName,Locale,SenderEmail,RedirectUri,Tag1,Tag2,Tag3,Tag4,TemplateId,SenderName',
                HELP_TEXT_GENERAL: 'Initials cannot contain /',
                UPLOAD: 'Send',
                SEND_SUCCEEDED_MESSAGE: 'The invitations were sent',
            },
            AUTH: {
                LOGIN: {
                    TITLE: 'Login',
                    BUTTON: 'Log in',
                    FORGOT_BUTTON: 'Forgot password',
                    PASSWORD: 'Password',
                    USERNAME: 'User name',
                    INVALID_LOGIN: 'Invalid login information. Please try again.',
                    PASSWORD_EXPIRED: 'Your password has expired',
                },
                FORGOT_PASSWORD: {
                    TITLE: 'Forgot password?',
                    TEXT: 'If you have forgotten your password, you can get help by contacting Nem Forsikring on 70 77 77 77.',
                    SUBMIT_BUTTON: 'Send',
                    BACK_BUTTON: 'Back',
                    FORGOT_BUTTON: 'Forgot password',
                    SUCCESS: 'Your password has now been reset.',
                    EMAIL: 'Email',
                },
                AUTH: {
                    WELCOME: 'Welcome to SalesPortal!',
                },
            },
            DASHBOARDS: {
                WIDGETS: {
                    DUMMY: 'Test widget',
                    TOP_SALESMEN: 'Top sellers',
                },
                EDIT_DASHBOARDS: {
                    CHOOSE_DASHBOARD: 'Select dashboard',
                    CHOOSE_WIDGET: 'Select widget',
                    CHOOSE_ALLOWED_USERS: 'Select allowed users',
                    CHOOSE_ALLOWED_AGENCIES: 'Select authorized agencies',
                    SETTINGS: 'Setup',
                    CHANGE_NAME: 'Correct name',
                    ROWS: 'Rows',
                    ALLOWED_USERS: 'Allowed users',
                    ALLOWED_AGENCIES: 'Allowed agencies',
                    NEW_COMPONENT: 'New component',
                    SAVE_CHANGES: 'Save Changes',
                    DELETE_DASHBOARD: 'Delete dashboard',
                    CREATE_NEW_DASHBOARD: 'Create new dashboard',
                    NEW_DASHBOARD_NAME: 'New dashboard name',
                },
                TOP_SALESMEN_WIDGET: {
                    NAME: 'Name',
                    POLICIES: 'Policer',
                    PREMIUMS: 'Prices',
                    CURRENCY: 'kr.',
                    TOP_SALESMEN_SETUP_MODAL: {
                        TITLE: 'Widget setup',
                        CHOOSE_SALESMEN: 'Choose salespeople',
                        SORTING: 'Sorting',
                        PREMIUMS: 'Prices',
                        POLICIES: 'Policer',
                        WIDGET_TITLE: 'Title',
                        CHOOSE_TITLE: 'Select title',
                        ALL_CHOSEN: 'Everyone is elected',
                        FONT_SIZE: 'Font size',
                        CHOOSE_FONT_SIZE: 'Select font size',
                    },
                },
            },
            CREATE_CUSTOMER: {
                TITLE: 'Create customer',
                GET_NEXT_LEAD: 'Get the next reference',
                NO_NEW_LEADS: 'No new references found',
                LOADING_LEAD_ERROR: 'An error occurred while loading the reference',
                LEAD_TITLE: 'Referral retrieved:',
                LEAD_NAME: 'Name',
                LEAD_PHONE_NUMBER: 'Phone number',
                LEAD_REFERENCE: 'Reference ID',
                CHOOSE_WRAPUP_REASON: 'choose reason',
                BTN_WRAPUP: 'OK',
                WRAPUP_ERROR: 'An error occurred while specifying the cause',
                CHOOSE_AGENCY: 'Select agency',
                CHOOSE_CASEWORKER: 'Choose caseworker',
                INPUT_ADDRESS: 'Enter address',
                INPUT_SSN: 'Enter CPR number',
                INPUT_REFERENCEID: 'Enter referral ID',
                NO_REFERENCE: 'The customer is not referred',
                ADDRESS_PROTECTED_TITLE: 'The entered CPR number is address protected. Enter address below:',
                INPUT_ADDRESS_MANUALLY_TITLE: 'I can not find the address',
                INPUT_ADDRESS_SEARCH_TITLE: 'Search address',
                INPUT_STREET_NAME: 'Street name',
                INPUT_STREET_NO: 'Number',
                INPUT_FLOOR: 'Floor',
                INPUT_DOOR: 'Door',
                INPUT_HOUSE_NO: 'Letter',
                INPUT_ZIPCODE: 'Zip code',
                CREATE: 'Create',
                INPUT_FIRST_AND_MIDDLENAMES: 'Enter first and middle names',
                INPUT_SURNAME: 'Enter last name',
            },
            FRONTPAGE: {
                TECHNICALASSISTANCE: {
                    TITLE: 'Technical assistance',
                    BODY: 'If you have any questions about SalesPortal, or encounter any problems, please feel free to contact us at:',
                    PHONE: 'Telephone:',
                    EMAIL: 'Email:',
                },
                INSURANCE_CONTACT: {
                    TITLE: 'Insurance issues',
                    BODY: 'If you have general questions about. insurance products, subscription rules, business procedures or the like, you are welcome to contact% CONTACTNAME%',
                    PHONE: 'Telephone:',
                    EMAIL: 'Email:',
                },
                LAST_VISITED_CUSTOMERS: {
                    TITLE: 'Recently visited customers',
                    CUSTOMERNO: 'Customer number',
                    NAME: 'Name',
                    ADDRESS: 'Address',
                    VISITED_TIME: 'Point in time',
                    AGENCY_NAME: 'Agency',
                },
            },
            DFIM: {
                TITLE: 'DFIM storage',
                TYPE_CPR_CVR: 'Enter CPR or CVR number',
                SEARCH_CPR: 'Search for CPR',
                SEARCH_ADDRESS: 'Search by address',
                CPR_CVR_NO: 'CPR / CVR number',
                FIRST_NAME: 'First name',
                SURNAME: 'Last name',
                STREET_NAME: 'Way',
                HOUSE_NO: 'Road number',
                ZIP_CODE: 'Zip code',
                CITY: 'Town',
                LEVEL: 'Floor',
                DOOR_SIDE: 'Side / door',
                INVALID_INPUTS_MESSAGE: 'Please fill in the selected fields.',
                INVALID_CPR_INPUT_MESSAGE: 'Please fill in the CPR / CVR field.',
                FEE_REGISTER_TITLE: 'Fee registered',
                PREMIUMS_REGISTER_TITLE: 'Jointly registered',
                NAME: 'Name',
                CPR: 'CPR',
                CVR: 'CVR',
                STATE: 'Status',
                COMMENT: 'Comment',
                ID: 'ID',
                ADDRESS: 'Address',
                POLICY_NO: 'Police',
                REGISTRATION_NO: 'Registration number.',
                COMPANY_NO: 'Business',
                COMPANY_CODE: 'Company code',
                REASON: 'Reasons',
                CREATION_DATE: 'Created',
                DELETION_DATE: 'Deleted',
                PAYMENT_DATE: 'Paid',
                NO_REGISTRATIONS_MESSAGE: 'No records found',
                SEARCH: 'Search',
            },
            SMS_TEMPLATES: {
                TITLE: 'SMS texts',
                NAME: 'Name',
                MESSAGE: 'Message',
                FUNCTIONS: 'Features',
                DELETE: 'Delete',
                EDIT: 'Edit',
                CREATE_TEMPLATE: 'New SMS text',
                INPUT_NAME_PLACEHOLDER: 'Enter name',
                INPUT_MESSAGE_PLACEHOLDER: 'Enter message',
                NAME_EXISTS_ERROR_MESSAGE: 'A template already exists with the selected name',
                DELETE_TEMPLATE_MODAL_TITLE: 'Delete% TEMPLATENAME%',
                DELETE_TEMPLATE_MODAL_CONFIRM_QUESTION: 'Are you sure you want to delete% TEMPLATENAME%?',
                AGENCY: 'Agency',
                AGENCIES: 'Agencies',
                CHOOSE_AGENCIES: 'Select agency',
            },
            PARTIALS: {
                LAYOUT: {
                    TOPBAR: {
                        USER_PROFILE: {
                            TITLE: 'User profile',
                            DESCRIPTION: 'Change password etc.',
                            SIGNOUT: 'Logout',
                        },
                        SEARCH: {
                            SEARCH_PLACEHOLDER: 'Search...',
                        },
                    },
                },
            },
            PROFILE: {
                TITLE: 'User profile',
                CHANGEPASSWORD: 'Change Password',
                NAME: 'Name',
                EMAIL: 'Email',
                PHONE: 'Phone number',
            },
            NEWPASSWORD: {
                HEADER: 'Change Password',
                DESCRIPTION: 'Enter your current login information and your desired new password:',
                NOT_MATCHING: 'Passwords are not the same.',
                RENEW_ERROR:
                    'An error occurred - your password has not been changed. Make sure that the password is at least 7 characters long, has not been used before and contains lowercase and uppercase letters as well as min. a number or special character.',
                PASSWORD_CHANGED: 'Your password has now been changed and you can log in with your new code.',
                PASSWORD: 'Password',
                NEWPASSWORD: 'New password',
                CONFIRM_PASSWORD: 'Confirm password',
                CONFIRM_PASSWORD_BUTTON: 'Change Password',
                CONFIRM_NEWPASSWORD: 'Confirm new password',
                USERNAME: 'User name',
                GO_TO_LOGIN: 'Go to login',
            },
            CUSTOMER: {
                CUSTOMER_SEARCH: {
                    TITLE: 'Search',
                    SEARCH_PLACEHOLDER: 'Enter search ...',
                    SEARCH: 'Search',
                    CUSTOMER_NO: 'Customer number',
                    NAME: 'Name',
                    ADDRESS: 'Address',
                    AGENCY: 'Agency',
                    POLICY_COUNT: 'Policer',
                    NO_CUSTOMERS_FOUND: 'No customers found for this search.',
                },
                CUSTOMER_SEARCH_DETAILS: {
                    DFIM_LOOKUP: 'DFIM lookup',
                    CUSTOMER_WARNING: 'Customer warning',
                    ADDRESS_PROTECTED: 'Address protected',
                    ADVERTISING_PROTECTED: 'Advertising protected',
                    REFERRAL: 'Reference',
                    CREATE_OFFER: 'Create quotes',
                    AGENCY: 'Agency',
                    CASEWORKER: 'Case manager',
                    TITLE: 'Master data',
                    CUSTOMER_NO: 'Customer no.',
                    CPR: 'CPR',
                    CVR: 'CVR',
                    NAME: 'Name',
                    YEARS: 'year',
                    ADDRESS: 'Address',
                    ZIP: 'Zip code',
                    CITY: 'Town',
                    PHONE_REMINDER: 'Remember to ask the customer for the phone number',
                    PHONE_MOBILE: 'Mobile phone',
                    PHONE_LANDLINE: 'Landline',
                    PHONE_WORK: 'Occupation',
                    PHONE_FAX: 'Fax',
                    PHONE_OTHER: 'Other things',
                    PHONE: 'Telephone',
                    EMAIL_REMINDER: 'Remember to ask the customer for the email',
                    ADD: 'add',
                    EMAIL_PRIVATE: 'Email (private)',
                    EMAIL_WORK: 'Email (work)',
                    EMAIL: 'Email',
                    TABS_TITLE: 'Notes and visitor log',
                    TAB_NOTES: 'Notes',
                    TAB_VISISTED_USERS: 'Visiting log',
                    SHOW_DETAILS: 'Show details',
                    STATUS: 'Status',
                    INSURED_ITEM: 'Insured object',
                    POLICYNO: 'Police number',
                    START_DATE: 'Start date',
                    END_DATE: 'End date',
                    PAYMENT_FREQUENCE: 'Payment frequency',
                    PAYMENT_METHOD: 'Payment method',
                    MAINMATURITY_DATE: 'Main decay',
                    CUSTOMER_RATING_GOOD: 'Mortgage allowed',
                    CUSTOMER_RATING_MEDIUM: 'Subscription requires individual notice and documentation in notes',
                    CUSTOMER_RATING_BAD: 'Mortgage not allowed',
                    INVOICES_TITLE: 'Invoice archive',
                    AGENCYINFORMATION: 'Company information',
                    CUSTOMERINFORMATION: 'Customer information',
                    TELEPHONEINFORMATION: 'Telephone information',
                    MAILINFORMATION: 'Mail information',
                    RELATIONS: 'Relationships',
                    DOCUMENTS_TITLE: 'Documents',
                    FUNCTIONS: 'Features',
                    CREDIT_INFORMATION_LOOKUP: 'RKI lookup',
                    CUSTOMER_SELFCARE_LOGIN: 'Customer self-service',
                    SEND_TEXT_MESSAGE: 'Send SMS',
                    INVOICE_AMOUNTS: 'Invoicing',
                    AWAITING_PAYMENT: 'Awaiting invoicing',
                    OUTSTANDING_AMOUNT: 'Outstanding',
                    TOTAL: 'In total',
                    SEND_QUESTIONNAIRE: 'Send questionnaire',
                    OTHER_INFO: 'Other information',
                    POLICY_COUNT: 'Number of policies',
                    OFFER_COUNT: 'Number of offers',
                    LOADING_POLICIES: 'Loading policies ...',
                    NO_POLICIES: 'No policies',
                    CLAIMS_TITLE: 'Injuries',
                    OFFERS_TITLE: 'Offer',
                    INSURANCE_CERTIFICATE: 'Proof of insurance',
                    ADVERTISING_PROTECTION: 'Advertising protection',
                    ADDRESS_PROTECTION: 'Address protection',
                    CONSENT: 'Declaration of consent',
                    PAYMENT_SERVICE: 'Payment service',
                    COUNTRY_CODE: 'Country code',
                    COUNTRY: 'Country',
                    CONAME: 'C / o',
                    NO_REFERED_ID_ERROR: 'NOTE! If the customer is referred, enter the ID under company information.',
                    NO_REFERED_ID_ERROR_TITLE: 'Reference ID is not filled out',
                    REFERRAL_REMINDER: 'Remember to enter the reference',
                    REMINDER_POPOVER: 'Several reminders have been sent to the policy, which have not yet been paid - it is therefore not possible to make changes.',
                    CANCELLED_POPOVER: 'The policy has been terminated - therefore no changes can be made.',
                    SEND_CUSTOMER_REVIEW_INVITE_TITLE: 'Send TP invitation',
                    SEND_CUSTOMER_REVIEW_INVITE_QUESTION: 'Are you sure you want to send a TP invitation to the customer?',
                    CPR_SEARCH: 'CPR lookup',
                    NO_EMAIL_MODAL_TITLE: 'No email',
                    NO_EMAIL_MODAL_TEXT: 'The customer has no email assigned. A Trustpilot invitation cannot be sent.',
                    CUSTOMER_RISK_YES: 'The customer lives at a risk address - premium penalty 100%',
                },
                CUSTOMER_TABS: {
                    GENERAL: {
                        TITLE: 'In general',
                    },
                    PREMIUMS: {
                        TITLE: 'Price',
                        DESCRIPTION: 'Description',
                        AMOUNT_ANNUAL: 'Price per. year',
                        TOTAL: 'Total',
                    },
                    COVERS: {
                        TITLE: 'Coverage',
                        NAME: 'Name',
                        COVERTYPE: 'Coverage type',
                        DESCRIPTION: 'Description',
                        MINDEDUCTIBLE: 'Minimum deductible',
                        MAXDEDUCTIBLE: 'Maximum deductible',
                        DEDUCTIBLE: 'Own risk',
                        DEDUCTIBLE_PR_CLAIM: 'Deductible per damage',
                        SUMTYPE: 'Sumtype',
                        MAXSUMPRCLAIM: 'Max sum per damage',
                        MAXSUMPERYEAR: 'Maximum amount per year',
                    },
                    COLLECTIONS: {
                        TITLE: 'Invoice archive',
                        INVOICEDATE: 'Invoice date',
                        DUEDATE: 'Due date',
                        AMOUNT: 'Amount',
                        STATE: 'Status',
                    },
                    DOCUMENTS: {
                        TITLE: 'Documents',
                        DESCRIPTION: 'Description',
                        DATE: 'Date',
                        DOWNLOAD: 'fetch',
                        DOWNLOAD_TERMS: 'Get insurance terms and conditions',
                        DOWNLOAD_MOTORCARD: 'Generate document',
                    },
                    NOTES: {
                        TITLE: 'Notes',
                        CREATE_NOTE: 'Create note',
                        DATE: 'Date',
                        NOTE: 'Note',
                    },
                    TASKS: {
                        TITLE: 'Tasks',
                        CREATED: 'Created',
                        TYPE: 'Type',
                        DESCRIPTION: 'Description',
                        STATUS: 'Status',
                    },
                    CLAIMS: {
                        TITLE: 'Injuries',
                        DATE: 'Date',
                        CLAIM_NO: 'Damage no.',
                        DESCRIPTION: 'Description',
                        STATE: 'Status',
                    },
                    POLICY_VERSIONS: {
                        TITLE: 'Version changes',
                        EVENTDATE: 'Version date',
                        MAJORCHANGE: 'Major change',
                        REASON: 'Reasons',
                        NOTE: 'Remark',
                        STATE: 'Status',
                    },
                    CLAUSES: {
                        TITLE: 'Clauses',
                        CLAUSENO: 'Clause',
                        DESCRIPTION: 'Description',
                        TEXT: 'Text',
                    },
                    EDI: {
                        TITLE: 'Terminations',
                        RECIEVED_SENT: 'Received / sent',
                        DIRECTION: 'Direction',
                        SENDER: 'Sender',
                        RECIEVER: 'Recipient',
                        TYPE: 'Type',
                        CANCELLED: 'Termination date',
                    },
                    PREVIOUSCOMPANY: {
                        INSURANCE: 'Insurance',
                        COMPANY: 'company',
                        POLICYNO: 'Police number',
                        REASON: 'Cause',
                        FREETEXT: 'Free text',
                        SENT: 'Sent',
                        APPROVED: 'Approved',
                    },
                },
            },
            FOLDERS: {
                ADMIN: {
                    FOLDERSETUP: 'Folder setup',
                    NAME: 'Name',
                    DOCUMENTS: 'Documents',
                    ACCESS: 'Access',
                    FUNCTIONS: 'Features',
                    LIMITED: 'Limited',
                    OPEN: 'Open',
                    CREATED_BY: 'Created by',
                    CREATED: 'Created',
                    ADD_FOLDER: 'Add folder',
                    CONFIRM_DELETE_TITLE: 'Delete folder',
                    CONFIRM_DELETE_QUESTION: 'Are you sure you want to delete the folder:% FOLDERNAME%?',
                },
                VIEW: {
                    ADD_DOCUMENT: 'Add document',
                    TITLE: 'Document folders',
                    FILE_TYPE: 'File type',
                    DOCUMENT_NAME: 'Document name',
                    ADDED: 'Added',
                    ADDED_BY: 'Added by',
                    CONFIRM_DELETE_TITLE: 'Delete document',
                    CONFIRM_DELETE_QUESTION: 'Are you sure you want to delete the document:% DOCUMENTNAME%?',
                },
            },
            CREATEOFFER: {
                STARTOFFER: {
                    TITLE: 'Create quotes',
                    BTN_WRAPUP: 'OK',
                    WRAPUP_ERROR: 'An error occurred while specifying the cause',
                    CHOOSE_AGENCY: 'Select agency',
                    CHOOSE_CASEWORKER: 'Choose caseworker',
                    INPUT_ADDRESS: 'Enter address',
                    INPUT_SSN: 'Enter CPR number',
                    INPUT_REFERENCEID: 'Enter referral ID',
                    NO_REFERENCE: 'The customer is not referred',
                    ADDRESS_PROTECTED_TITLE: 'The entered CPR number is address protected. Enter address below:',
                    INPUT_ADDRESS_MANUALLY_TITLE: 'I can not find the address',
                    INPUT_ADDRESS_SEARCH_TITLE: 'Search address',
                    INPUT_STREET_NAME: 'Street name',
                    INPUT_STREET_NO: 'Number',
                    INPUT_FLOOR: 'Floor',
                    INPUT_DOOR: 'Door',
                    INPUT_HOUSE_NO: 'Letter',
                    INPUT_ZIPCODE: 'Zip code',
                    INPUT_FIRST_AND_MIDDLENAMES: 'Enter first and middle names',
                    INPUT_SURNAME: 'Enter last name',
                },
                PRODUCTS: {
                    TITLE: 'Select products',
                    NO_AGENCY_ID: 'No agency selected',
                    NO_ADDRESS: {
                        TITLE: 'Invalid address',
                        TEXT: 'It is not possible to create quotes on the customer due to the customer&#39;s address. Contact customer service for quotes.',
                    },
                },
                CUSTOMERINFO: {
                    TITLE: 'Customer information',
                    SHOW_GOOGLE_MAP: 'Show overview map',
                    SHOW_STREETVIEW_MAP: 'View streetview',
                    MAP_NOT_AVAILABLE: 'Map not available',
                    SEARCH_RKI: 'Search RKI',
                },
                OFFEROVERVIEW: {
                    TITLE: 'Offer',
                    DISCOUNT: '% discount',
                    TOTAL_PRICE: 'Total price',
                    CURRENCY: 'kr.',
                    ELIGIBLE_FOR: 'Entitled',
                    BEGIN_CALCULATION: 'Start calculation',
                    REMOVE_PRODUCT_CONFIRM_TITLE: 'Confirm deletion',
                    REMOVE_PRODUCT_CONFIRM_QUESTION: 'Are you sure you want to remove% OFFERLINE% from the offer?',
                    CREATING_OFFER: 'Creates quotes',
                },
                PRODUCTBOX: {
                    ADD_PRODUCT: 'add',
                    DISCOUNT_ENTRY: 'Discount trigger',
                    DISCOUNT_GIVING: 'Discounting',
                },
            },
            STATISTICS: {
                QUESTIONNAIRES: {
                    TITLE: 'Questionnaire report',
                    DATE_FROM: 'Date from',
                    DATE_TO: 'Date to',
                    CHOOSE_DATE: 'Select date',
                    AGENCY: 'Agency',
                    CHOOSE_AGENCY: 'Select agency',
                    QUESTIONNAIRE: 'questionnaire',
                    CHOOSE_QUESTIONNAIRE: 'Select questionnaire',
                    DOWNLOAD_REPORT: 'Download report',
                },
            },
            POLICY_MTA: {
                MAJOR_CHANGE: 'Big change',
                DATE: 'Date',
                POLICY_START_DATE: 'Policy start date',
                COMMENT: 'Comment',
                MTA: 'Police change',
                PRODUCT_ACTIONS: {
                    CALCULATE: 'Calculate',
                    UPDATE_POLICY: 'Update police',
                    CANCEL: 'Undo',
                },
                PRE_UPDATE_MODAL: {
                    TITLE: 'Justification for change',
                    COMMENT: 'Comment',
                    TYPE_COMMENT: 'Write a comment',
                    MISSING_UPDATE_REASON: 'Please select a reason',
                    MISSING_COMMENT_MESSAGE: 'Please write a comment',
                    UPDATE_POLICY: 'Update police',
                    CANCEL: 'Undo',
                    UPDATE_REASON: 'Reasons',
                    SELECT_UPDATE_REASON: 'Choose justification',
                    COMMENT_MESSAGE_TOO_LONG: 'The comment is too long. Use max. 100 characters.',
                },
            },
            ACTIVATE: {
                SHARED: {
                    START_DATE: 'Entry into force',
                    MAIN_MATURITY_DATE: 'Next main due date',
                    SAVE_NEXT: 'Gem',
                    EDI: {
                        REMOVE_EDI: 'Remove EDI',
                        ADD_EDI: 'Add EDI',
                        CONSENT_RECEIVED: 'Consent received',
                        TYPE: 'Type',
                        INSURANCE_COMPANY: 'company',
                        REASON: 'Cause',
                        POLICY_NO: 'Police no.',
                        ENABLE_EDI: 'Termination insurance',
                        FREE_TEXT: 'Free text',
                    },
                    STREET: 'Way',
                    HOUSE_NO: 'House number',
                    FLOOR: 'Floor',
                    DOOR: 'Door',
                    ZIP_CODE: 'Zip code',
                    CITY: 'Town',
                    REGISTRATION_NO: 'Registration number',
                    CHASSIS_NO: 'Chassis number',
                    NAME: 'Name',
                    DATE_FOR_START: 'Date of entry into force',
                },
                DOG: {
                    CALL_NAME: 'Name',
                },
                SECONDHOUSE: {
                    REMOVE_HOUSE_EDI: 'Remove house EDI',
                    ADD_HOUSE_EDI: 'Add house EDI',
                    REMOVE_HOME_EDI: 'Remove contents EDI',
                    ADD_HOME_EDI: 'Add contents EDI',
                    HOUSE_EDI: 'House EDI',
                    HOME_EDI: 'Indbo EDI',
                },
                ACCIDENT: {
                    EDI_HELP_TEXT_LINE_1: 'It is not possible to EDI terminate the accident insurance via SalesPortal.',
                    EDI_HELP_TEXT_LINE_2_PRE: 'You must instead write an email to',
                    EDI_HELP_TEXT_LINE_3:
                        'NOTE! Before sending the email, make sure that all fields in the acceptance documents are filled in on the accident insurance. Ie. we have the following information available:',
                    EDI_HELP_TEXT_LINE_4: 'Name and cpr.nr. on the policyholder.',
                    EDI_HELP_TEXT_LINE_LIST_1: 'Name and cpr.nr. on the insured (s)',
                    EDI_HELP_TEXT_LINE_LIST_2: 'Policy number and company on previous policy',
                    EDI_HELP_TEXT_LINE_5: 'Remember to put the insurance into effect per. the date it can be taken over from previous company. (Pay attention to 5 days treatment time.)',
                },
                FARMHOUSE: {
                    HOUSE: 'House',
                    HOME: 'Indbo',
                    DOG: 'Dog',
                    OUTHOUSE: 'Outhouse',
                    ADD_HOME_EDI: 'Add contents EDI',
                    REMOVE_HOME_EDI: 'Remove contents EDI',
                    EDI_HELP_TEXT_LINE_1: 'It is not possible to EDI terminate landbo via SalesPortal.',
                    EDI_HELP_TEXT_LINE_2_PRE: 'You must instead write an email to',
                    EDI_HELP_TEXT_LINE_3:
                        'NOTE! Before sending the email, make sure that all fields in the acceptance documents are filled in at landbo. Ie. we have the following information available:',
                    EDI_HELP_TEXT_LINE_4: 'Name and cpr.nr. on the policyholder.',
                    EDI_HELP_TEXT_LINE_LIST_1: 'Name and cpr.nr. on the insured (s)',
                    EDI_HELP_TEXT_LINE_LIST_2: 'Policy number and company on previous policy',
                    EDI_HELP_TEXT_LINE_5: 'Remember to put the insurance into effect per. the date it can be taken over from previous company. (Pay attention to 5 days treatment time.)',
                },
            },
            SUMMARY: {
                CUSTOMER_DETAILS_TITLE: 'Customer information',
                PRODUCT_ACTIONS: {
                    CALCULATE: 'Calculate',
                    ADD_TO_OFFER: 'Add to quote',
                    UPDATE_OFFER: 'Update offers',
                    CANCEL: 'Undo',
                },
                TASKS: {
                    TITLE: 'Tasks',
                    PREVENTS_APPROVAL: 'Blocking approval',
                    DOES_NOT_PREVENT_APPROVAL: 'Does not block approval',
                },
                DFIM: {
                    TITLE: 'DFIM storage',
                    INSURANCE_HOLDER: 'Policyholder',
                    INSURANCE_HOLDER_NOT_REGISTERED_FEE: 'The policyholder is not registered in the fee register',
                    INSURANCE_HOLDER_REGISTERED_FEE: 'The policyholder is registered in the fee register',
                    INSURANCE_HOLDER_NOT_REGISTERED_PREMIUM: 'The policyholder is not registered in the joint register',
                    INSURANCE_HOLDER_REGISTERED_PREMIUM: 'The policyholder is registered in the joint register',
                    PRIMARY_USER: 'Primary user',
                    USER_NOT_REGISTERED_FEE: 'Primary user is not registered in the fee register',
                    USER_REGISTERED_FEE: 'Primary user is registered in the fee register',
                    USER_NOT_REGISTERED_PREMIUM: 'Primary user is not registered in the common register',
                    USER_REGISTERED_PREMIUM: 'Primary user is registered in the common register',
                    COULD_NOT_DETERMINE_INSURANCE_HOLDER_REGISTERED_FEE: 'Check of policyholder could not be performed in the fee register',
                    COULD_NOT_DETERMINE_INSURANCE_HOLDER_REGISTERED_PREMIUM: 'Check of policyholder could not be performed in the joint register',
                    COULD_NOT_DETERMINE_USER_REGISTERED_FEE: 'Primary user checks could not be performed in the fee register',
                    COULD_NOT_DETERMINE_USER_REGISTERED_PREMIUM: 'Primary user checks could not be performed in the common registry',
                },
                FLOW: {
                    TITLE: 'End offer',
                    APPROVE_OFFER: 'Accept offers',
                    UNAPPROVE_OFFER: 'Undo approve',
                    SEND_OFFER: 'Send offer',
                    ACCEPT_OFFER: 'Offer acceptance',
                    START_OFFER: 'Entry into force',
                    RKI_LOOKUP: 'RKI storage',
                    CLOSE_OFFER: 'Close offer',
                    SEND_OFFER_ACCEPT_TASK: 'Send acceptance task',
                    DOWNLOAD_OFFER_DOCUMENT: 'Download offer document',
                    CUSTOMER_SELFCARE: 'Customer Selfcare',
                    DOWNLOAD_ACCEPT: 'Download acceptance documents',
                    GO_TO_START_OFFER: 'Go into effect',
                    OFFER_NOT_APPROVED: 'The offer has not been approved',
                    CREATED_BY: 'Created by',
                    CREATED_DATE: 'Created d.',
                    VALID_UNTIL: 'Applicable to',
                    OFFER_NOT_SENT: 'The offer has not been sent',
                    SENT_DATE: 'Sent d.',
                    OFFER_NOT_ACCEPTED: 'The offer is not accepted',
                    ACCEPTED: 'Accepted',
                    OFFER_NOT_ACTIVE: 'The offer has not been activated',
                    NEW_PRODUCT: 'Add product',
                },
            },
            CALCULATE: {
                ACCEPTANCE_MODAL: {
                    TITLE: 'Send acceptance task',
                    SEND_TO: 'Send to',
                    MAIL_TEMPLATE: 'Template',
                    SELECT_MAIL_TEMPLATE: 'Choose template',
                    SUBJECT: 'Subject',
                    MAIL_TEXT: 'Mail text',
                    SEND: 'Send',
                    CLOSE: 'Close',
                },
                ERROR: {
                    TITLE: 'Error',
                },
                LEGAL_IDENTITY_INPUT: {
                    STREET: 'Way',
                    NAME: 'Name',
                    AGE: 'Age',
                    HOUSE_NO: 'House number',
                    DOOR: 'Door',
                    FLOOR: 'Floor',
                    ZIP_CODE: 'Zip code',
                    CITY: 'Town',
                    ADDRESS_NO: 'Address number',
                    DIRECTLY_TYPED: 'Directly entered',
                    INPUT_TYPE: 'Input type',
                    SEARCH: 'Search',
                    USE_CPR_NO_MESSAGE: 'Please try again with CPR number',
                    TYPE_VALID_ADDRESS_NO_MESSAGE: 'Please enter a valid customer number or CPR number',
                    COULD_NOT_CREATE_CUSTOMER: 'Unfortunately, the customer could not be created. Are you sure the information is correct?',
                    CPR_IS_ADDRESS_PROTECTED: 'The entered CPR number is address protected',
                    NONE: 'Not selected',
                    RELATIONSHIP: 'Relation',
                    SECONDARY_INSURANCE_HOLDER: 'Secondary policyholder',
                },
                SUPPLEMENTARY_INFOS: {
                    TITLE: 'General information',
                },
                CALCULATE_PROGRESS: {
                    CREATE_OFFER: 'Create quotes',
                    CALCULATE_OFFER: 'Calculate offers',
                    SUMMARY: 'Summary',
                    COMPLETE: 'Entry into force',
                },
                PRODUCT_ACTIONS: {
                    CALCULATE: 'Calculate',
                    SAVE_NEXT: 'Gem',
                },
                PRODUCT_CALCULATION: {
                    DESCRIPTION: 'Description',
                    PRICE: 'Price',
                    INSURANCE_EXCL_TAX: 'Insurance ex. charges',
                    CURRENCY: 'kr.',
                    TOTAL_AMOUNT_PRE_CHANGE: 'Price before change',
                    TOTAL_AMOUNT_DIF: 'Change in price',
                    TOTAL_AMOUNT: 'Total price',
                    AVERAGE: 'Average',
                    CURRENCY_PR_MONTH: 'DKK per month',
                },
                WRAPPER: {
                    ACTIONS: 'Actions',
                    CANCEL: 'Undo',
                    SAVE: 'Gem',
                },
                ADMINISTRATION_INPUT: {
                    TITLE: 'Administrative information',
                    PROVISION: 'Commission',
                    SECONDARY_PROVISION: 'Secondary commission',
                    INSTALMENT_PERIOD: 'Payment',
                    AGREEMENT: 'Package agreement',
                    MANUAL_DISCOUNT: 'Manual discount',
                    OTHER_DISCOUNTS: 'Other discounts',
                    NO_MANUAL_DISCOUNT: 'No manual discount',
                },
                COVER_SELECTOR: {
                    TITLE: 'Coverage',
                    COVER_PACKAGES: 'Coverage packages',
                    DEDUCTIBLE: 'Own risk',
                    COVERAGE_SCOPE: 'Scope of coverage',
                    COVER_PACKAGE_BUILD: 'build self',
                    SUM: 'Sum',
                    FRAME_NO: 'Chassis number',
                    ADD_BIKE: 'Add bike',
                    REMOVE_BIKE: 'Remove bike',
                },
                CALCULATE_ADDRESS_INPUT: {
                    TITLE: 'Address information',
                    ADDRESS: 'Address',
                    ADDRESS_CHOSEN: 'Selected address',
                    ADDRESS_SEARCH: 'Address finder',
                    CHANGE: 'Shift',
                    USE_LOOKUP_ADDRESS: 'Use the selected address',
                    MANUAL: 'Manual entry',
                    STREET_NAME: 'Street name',
                    STREET_NO: 'House number',
                    HOUSE_NO: 'Letter',
                    FLOOR: 'Floor',
                    DOOR: 'Door',
                    ZIP_CODE: 'Zip code',
                    CITY: 'Town',
                    PLACE: 'Place name',
                    USE_MANUAL_ADDRESS: 'Use entered',
                    IS_OWN_HOME: 'Policyholder&#39;s address',
                },
                CAR: {
                    CAR_DETAILS: 'Car information',
                    USAGE: 'Usage',
                    BRAND: 'Brand',
                    MODEL: 'Model',
                    YEAR: 'Year',
                    MOTOR_SIZE: 'Engine',
                    FUEL: 'Fuel',
                    VARIANT: 'Variant',
                    VARIANT_NAME: 'Variant name',
                    NEW_VALUE: 'The new value of the car',
                    WEIGHT: 'Net weight (kg)',
                    REGISTRATION_NUMBER: 'Registration number',
                    CHASSIS_NUMBER: 'Chassis number',
                    VEHICLE_ID: 'Vehicle side',
                    CHASSIS_TYPE: 'Bodywork',
                    HORSE_POWER: 'Hk',
                    MAX_KM_PR_YEAR: 'Max. km. pr. year',
                    ODOMETER_VALUE: 'Km-stand',
                    ODOMETER_DATE: 'Reading date',
                    ODOMETER_DATE_PLACEHOLDER: 'Select reading date',
                    DEDUCTIBLE: 'Own risk',
                    CHANGE_OWNER_USER: 'Change owner / user',
                    YEARS_WITH_OWN_CAR: 'Number of years with own car',
                    CLAIMS_LAST_5_YEARS: 'Number of injuries last 5 years',
                    BONUS_CLASS: 'Bonus class',
                    THEFT_SECURED_REQUIRED: 'Requirements for theft protection',
                    LEASING: 'Leasing',
                    STANDSTILL: 'Stagnation',
                    BONUS: 'Bonus class',
                    YEAR_OF_FIRST_REGISTRATION: '1st year of registration',
                    DMR_BTN: 'Get information from DMR',
                    DMR_ERROR: 'No information was found in DMR',
                    CAR_SELECTOR_MODAL: {
                        TITLE: 'Select car',
                    },
                    SAVED_VALUE: 'Saved value:',
                    MANUALLY_SELECT: 'Manually select the option from the list that matches best.',
                },
                CARAVAN: {
                    CARAVAN_DETAILS: 'Caravan information',
                    REGISTRATION_NUMBER: 'Registration number',
                    CHASSIS_NUMBER: 'Chassis number',
                    USAGE: 'Usage',
                    BRAND: 'Brand',
                    MODEL: 'Model',
                    YEAR: 'Year',
                    MARKET_VALUE: 'Trade value',
                    STATIONARY: 'Stationary caravan',
                    DMR_BTN: 'Get information from DMR',
                    DMR_ERROR: 'No information was found in DMR',
                },
                HOME: {
                    HOME_DETAILS: 'Housing information',
                    HOME_TYPE: 'Housing type',
                    ROOF_TYPE: 'Roof type',
                    RESIDENCE_AREA: 'Living area',
                    BUSINESS_AREA: 'Business area',
                    GARAGE_AREA: 'Garage and outbuilding area',
                    THEFT_SECURED: 'Theft protection',
                    NO_OCCUPANTS: 'number of residents',
                },
                TRAILER: {
                    TRAILER_DETAILS: 'Trailer information',
                    USAGE: 'Usage',
                    BRAND: 'Brand',
                    MODEL: 'Model',
                    YEAR: 'Year',
                    MARKET_VALUE: 'Trade value',
                    REGISTRATION_NUMBER: 'Registration number',
                    CHASSIS_NUMBER: 'Chassis number',
                    DEDUCTIBLE_FIXED: 'Deductible (fixed)',
                    DMR_BTN: 'Get information from DMR',
                    DMR_ERROR: 'No information was found in DMR',
                },
                ACCIDENT: {
                    REMOVE: 'Remove',
                    ADD_ACCIDENT_FULL: 'Add full-time accident',
                    ADD_ACCIDENT_LEISURE: 'Add leisure accident',
                    ADD_ACCIDENT_CHILD: 'Add child accident',
                    ADD_ACCIDENT: 'Add accident',
                    ACCIDENT_FULL: 'Full-time accident',
                    ACCIDENT_LEISURE: 'Leisure accident',
                    ACCIDENT_CHILD: 'Child accident',
                    TYPE: 'Type',
                    SCOPE_TYPE: 'Insurance scope',
                    OCCUPATION: 'Employment',
                    PERMANENT_INJURY_DEGREE: 'Mengrad (%)',
                    SPECIAL_RISKS: 'Special risks',
                    DOUBLE_COMPENSATION: 'Double replacement',
                    ADD_BENEFICIARY: 'Add beneficiary',
                    REMOVE_BENEFICIARY: 'Remove beneficiary',
                    BENEFICIARY: 'Beneficiary',
                    BENEFICIARY_TYPE: 'Favored type',
                    CHILD_SLAVE_HELP_TEXT: 'Mengrad, double compensation and coverages are put on the first child accident.',
                    ACCIDENT_BENEFICIEARY: {
                        INPUT_TYPE: 'Input type',
                        CUSTOMER: 'Customer',
                        PERSON: 'Person',
                        RELATION: 'Relation',
                        ADDRESS_NO: 'Address number',
                        SEARCH: 'Search',
                        PERCENTAGE: 'Percentage',
                        NAME: 'Name',
                        AGE: 'Age',
                        ZIP_CODE: 'Zip code',
                        DIRECTLY_TYPED: 'Directly stated',
                        ADDRESS: 'Address',
                    },
                    OCCUPATION_CATEGORY: 'Occupation category',
                },
                DOG: {
                    BIRTH_DATE: 'Date of birth',
                    LIABILITY: 'Dog responsibility',
                    EXTENDED_LIABILITY: 'Extended dog responsibility',
                    SICKNESS: 'Health insurance',
                    RACE: 'Race',
                    NAME: 'Nickname',
                    PEDIGREE_NAME: 'Pedigree name',
                    GENDER: 'Sex',
                    CHIP_NO: 'Tattoo no. / Microchipnr.',
                    HEALTH_JOURNAL: 'Health certificate',
                    ADD_NEW_DOG: 'Add dog',
                    DOG: 'Dog',
                    REMOVE_DOG: 'Remove dog',
                    HEALTH_INSURANCE_HELP_TEXT:
                        'The dog must be older than 2 months and younger than 5 years in order for a health insurance to be taken out. NOTE! Health certificate must be available when the dog is older than 3 years - Certificate must be a maximum of 14 days old.',
                    LIFE_INSURANCE_HELP_TEXT:
                        'A maximum of a life insurance sum of DKK 5,000 may be chosen if the dog is younger than 1 or older than 3 years. NOTE! Health certificate must be available when the dog is older than 1 year - Certificate must be a maximum of 14 days old.',
                },
                MOPED: {
                    MOPED_DETAILS: 'Information about mopeds',
                    TYPE: 'Usage',
                    BRAND: 'Brand',
                    MODEL: 'Model',
                    YEAR: 'Year',
                    REGISTRATION_NUMBER: 'Registration number',
                    CHASSIS_NUMBER: 'Chassis number',
                    DMR_BTN: 'Get information from DMR',
                    DMR_ERROR: 'No information was found in DMR',
                },
                MOTORCYCLE: {
                    TYPE: 'Type',
                    DRIVING_PERIOD: 'Driving period',
                    DEDUCTIBLE: 'Own risk',
                    BRAND: 'Brand',
                    MODEL: 'Model',
                    YEAR: 'Year',
                    YEAR_OF_FIRST_REGISTRATION: '1st year of registration',
                    MARKET_VALUE: 'New value',
                    REGISTRATION_NUMBER: 'Registration number',
                    CHASSIS_NO: 'Chassis number',
                    ENGINE_SIZE: 'Engine size (CCM)',
                    LEASING: 'Leasing',
                    STANDSTILL: 'Stagnation',
                    BONUS: 'Bonus class',
                    DMR_BTN: 'Get information from DMR',
                    DMR_ERROR: 'No information was found in DMR',
                },
                TRACTOR: {
                    DEDUCTIBLE: 'Own risk',
                    USAGE: 'Usage',
                    BRAND: 'Brand',
                    MODEL: 'Model',
                    YEAR: 'Year',
                    NEW_VALUE: 'New value',
                    REGISTRATION_NUMBER: 'Registration number',
                    CHASSIS_NUMBER: 'Chassis number',
                    APPROVED: 'Approved tractor',
                    REGISTERED: 'VAT registered',
                    DMR_BTN: 'Get information from DMR',
                    DMR_ERROR: 'No information was found in DMR',
                },
                HOUSE: {
                    BUILDING_NO: 'Building number',
                    REMOVE: 'Remove',
                    USAGE_TYPE: 'Type of use',
                    BUILDING_STATUS: 'Building status',
                    HEATING_TYPE: 'Heating type',
                    ROOF_TYPE: 'Roof type',
                    BASEMENT: 'Cellar',
                    RESIDENCE_AREA: 'Living area',
                    BUSINESS_AREA: 'Business area',
                    GARAGE_AREA: 'Garage / outbuilding area',
                    CONSTRUCTION_YEAR: 'Year of construction',
                    ROOF_TYPE_FREE_TEXT: 'Roof type (free text)',
                    RENOVATION_YEAR_PIPES: 'Renovation year, pipes',
                    RENOVATION_YEAR_ROOF: 'Renovation year, roof',
                    RENOVATION_YEAR_WINDOWS_DOORS: 'Renovation year, windows and doors',
                    RENOVATION_YEAR_WIRES: 'Renovation year, wiring',
                    ADD_BUILDING: 'Add building',
                    BUILDING: 'Building',
                    EXPECTED_COMPLETION_DATE: 'Expected completion',
                },
                SECONDHOUSE: {
                    TITLE: 'Holiday home',
                    INCLUDE_HOME: 'Include contents',
                    HOME: 'Indbo',
                    HOUSE: 'House',
                },
                FARMHOUSE: {
                    TITLE: 'Landbo',
                    INSURANCE_TYPE: 'Type of insurance',
                    DOG: 'Dog',
                    HOME: 'Indbo',
                    HOUSE: 'House',
                    OUTHOUSE: 'Outhouse',
                    ENABLE_DOG: 'Include dog',
                    ENABLE_HOME: 'Include contents',
                    INSURANCE_SUM: 'Sum insured',
                    STAMP_DUTY_SUM: 'Stamp duty',
                    VAT_REGISTERED: 'VAT registered',
                    HAY_STRAW: 'Storage of hay / straw',
                    BUILDING_SELECTOR: {
                        TITLE: 'Select building composition',
                        HOUSE: 'House',
                        OUTHOUSE: 'Expansion',
                        NONE: 'Not selected',
                        BUILDING: 'Building',
                        RESIDENCE_AREA: 'Residential',
                        BUSINESS_AREA: 'profession',
                        GARAGE_AREA: 'Garage',
                        BLOCK: 'Block',
                    },
                },
                SALARY: {
                    PREVIOUS_COMPANY_NAME: 'Former company name',
                    PREVIOUS_COMPANY_COVERAGE_AMOUNT: 'Previous sum insured (per month)',
                    PREVIOUS_COMPANY_QUALIFICATION_PERIOD: 'Previous seniority (months)',
                    OCCUPATION: 'Employment',
                    COVERAGE_AMOUNTS: 'Sum insured',
                    INITIAL_QUALIFICATION_PERIOD: 'Qualification period (months)',
                    QUALIFICATION_PERIOD: 'Requalification period',
                    COVERAGE_PERIOD: 'Payout period',
                    QUALIFYING_PERIOD: 'Deductible period',
                    TRANSFERRED_FROM_PREVIOUS: 'Transferred from earlier',
                },
            },
        },
    },
};
