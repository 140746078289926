import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable()
export class FunctionsService {
    deepCopy(oldObj: any): any {
        let newObj = oldObj;
        if (oldObj && typeof oldObj === 'object') {
            newObj = Object.prototype.toString.call(oldObj) === '[object Array]' ? [] : {};
            for (const i in oldObj) {
                newObj[i] = this.deepCopy(oldObj[i]);
            }
        }
        return newObj;
    }

    convertToUtc(date: Date): string {
        const dateLocal: moment.Moment = moment(date);
        const dateUtc = moment
            .parseZone(dateLocal)
            .utc()
            .format();
        return dateUtc;
    }

    convertToUtcDate(date: Date): Date {
        const dateLocal: moment.Moment = moment(date);
        const dateUtc = moment
            .parseZone(dateLocal)
            .utc(false)
            .toDate();
        return dateUtc;
    }

    convertToDateString(date: Date): string {
        const dateString = moment(date).format('YYYY-MM-DDTHH:mm:ss');
        return dateString;
    }

    convertDatePickerValueToDate(datePickerValue: any): Date {
        if (!datePickerValue) {
            return null;
        }

        const datePickerDate: any = datePickerValue.date;
        const localDate: Date = new Date(datePickerDate.year, datePickerDate.month - 1, datePickerDate.day, 0, 0, 0, 0); // Month is index based, which is way the -1
        return localDate;
    }

    validateEmail(email: string): boolean {
        const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(email);
    }

    validateRegNo(regNo: string): boolean {
        const regex = /^[a-zA-Z0-9]*$/;
        const result = regex.test(regNo);
        return result;
    }

    isCprDatepartValid(input: string): boolean {
        const inputExp = new RegExp('^\\d{10}$');

        if (!input || !inputExp.test(input)) {
            return false;
        }

        const day = parseInt(input.substr(0, 2), 10);

        if (isNaN(day) || day > 31 || day < 1) {
            return false;
        }

        const month = parseInt(input.substr(2, 2), 10) - 1; // beginning with 0 for January to 11 for December.

        if (isNaN(month) || month > 11 || month < 0) {
            return false;
        }

        const year = parseInt(input.substr(4, 2), 10);

        if (isNaN(year) || year > 99 || year < 0) {
            return false;
        }

        const date = new Date(year, month, day);

        return date instanceof Date && !isNaN(date.valueOf());
    }

    getDaysAndHoursTextFromMinutes(minutes: number): string {
        let result = '';
        let days = minutes / (60 * 24);
        const remainder = minutes % (60 * 24);
        let hours = remainder / 60;

        days = Math.floor(days);
        hours = Math.floor(hours);
        if (days && days > 0) {
            result = (days === 1 ? '1 dag' : days + ' dage') + ' og ';
        }
        result += hours === 1 ? '1 time' : hours + ' timer';
        if (minutes < 60) {
            result = 'mindre end en time';
        }
        return result;
    }
}
