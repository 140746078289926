import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

function getAppConfig(): Promise<void> {
    const appConfigPromise = new Promise<void>(resolve => {
        const httpRequest = new XMLHttpRequest();
        httpRequest.onreadystatechange = () => {
            if (httpRequest.readyState == 4 && httpRequest.status == 200) {
                window['tempAppConfig'] = JSON.parse(httpRequest.responseText);
                resolve();
            }
        };
        httpRequest.open('GET', '/appsettings.json', true);
        httpRequest.send(null);
    });

    return appConfigPromise;
}

getAppConfig().then(() => {
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch(err => console.error(err));
});
