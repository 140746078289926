import { Injectable } from '@angular/core';
import { AppSettings } from './iAppSettings';

@Injectable({
    providedIn: 'root',
})
export class AppConfigService {
    appConfig: AppSettings;

    constructor() {
        this.appConfig = window['tempAppConfig'];
    }
}
