import { Injectable } from '@angular/core';

@Injectable()
export class TokenStorage {
    constructor() {}

    public getAccessToken(): string {
        const token: string = this.getItem('accessToken');
        return token;
    }

    public getExpirationDate(): Date {
        const expDate: Date = new Date(this.getItem('expirationDate'));
        return expDate;
    }

    public getUsername(): string {
        const username: string = this.getItem('username');
        return username;
    }

    public getInitials(): string {
        const initials: string = this.getItem('initials');
        return initials;
    }

    public getPhone(): string {
        const phone: string = this.getItem('phone');
        return phone;
    }

    public getEmail(): string {
        const email: string = this.getItem('email');
        return email;
    }

    public setAccessToken(token: string): TokenStorage {
        this.setItem('accessToken', token);
        return this;
    }

    public setExpDate(expirationDate: Date): TokenStorage {
        this.setItem('expirationDate', expirationDate.toString());
        return this;
    }

    public setUsername(username: string): TokenStorage {
        this.setItem('username', username);
        return this;
    }

    public setInitials(initials: string): TokenStorage {
        this.setItem('initials', initials);
        return this;
    }

    public setEmail(email: string): TokenStorage {
        this.setItem('email', email);
        return this;
    }

    public setPhone(phone: string): TokenStorage {
        this.setItem('phone', phone);
        return this;
    }

    public clear() {
        this.removeItem('accessToken');
        this.removeItem('expirationDate');
        this.removeItem('username');
        this.removeItem('email');
        this.removeItem('phone');
        this.removeItem("'initials");
    }

    private setItem(itemName: string, value: string): void {
        sessionStorage.setItem(itemName, value);
    }

    private getItem(itemName: string): string {
        return sessionStorage.getItem(itemName);
    }

    private removeItem(itemName: string): void {
        sessionStorage.removeItem(itemName);
    }
}
