import { AuthConfig } from 'angular-oauth2-oidc';

export function getAuthConfig(issuer: string): AuthConfig {
    return {
        issuer: issuer,
        redirectUri: window.location.origin + '/auth',
        logoutUrl: window.location.origin + '/auth',
        clientId: 'inex',
        scope: 'openid',
        responseType: 'code',
        showDebugInformation: true,
    };
}
