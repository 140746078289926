import { BlockedCprLookup } from '@scalepoint/nemex-api-clients/services';
import { CprModel } from '../cpr';

export class BlockedCprLookupModel {
    isBlocked: boolean;
    created?: Date;
    cpr?: CprModel;
    reason: string;
    comment: string;

    constructor(blockedCprLookup: BlockedCprLookup) {
        this.isBlocked = blockedCprLookup.isBlocked;
        this.created = blockedCprLookup.created;
        this.cpr = blockedCprLookup.cpr ? new CprModel(blockedCprLookup.cpr) : null;
        this.reason = blockedCprLookup.reason;
        this.comment = blockedCprLookup.comment;
    }
}
