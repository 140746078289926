import { Injectable, OnDestroy } from '@angular/core';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { AppConfigService } from '../config-service/config.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from '../../core/auth';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ApplicationInsightsService implements OnDestroy {
    private appInsights: ApplicationInsights;
    private navigationEndSubscription: Subscription;

    constructor(private appConfigService: AppConfigService, private router: Router, private authService: AuthService) {
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: appConfigService.appConfig.ApplicationInsights.InstrumentationKey,
            },
        });

        this.appInsights.loadAppInsights();

        this.appInsights.addTelemetryInitializer(function(envelope) {
            envelope.tags['ai.cloud.role'] = 'Nemex.Frontend';
            envelope.tags['ai.application.ver'] = appConfigService.appConfig.Application.Version;
        });

        this.navigationEndSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(this.onNavigationEnd.bind(this));
    }

    ngOnDestroy() {
        this.navigationEndSubscription.unsubscribe();
    }

    onNavigationEnd(event: NavigationEnd) {
        if (this.authService.isAuthenticated()) {
            const username = this.authService.getUsername();
            this.setUsername(username);
        } else {
            this.clearUsername();
        }

        this.logPageView(null, event.url);
    }

    setUsername(username: string) {
        this.appInsights.setAuthenticatedUserContext(username);
    }

    clearUsername() {
        this.appInsights.clearAuthenticatedUserContext();
    }

    logPageView(name?: string, uri?: string) {
        this.appInsights.trackPageView({ name, uri });
    }

    logException(exception: Error, severityLevel: SeverityLevel, stackTrace?: string[]) {
        this.appInsights.trackException({
            exception,
            severityLevel,
            properties: { stackTrace: stackTrace ? stackTrace.join('/r/n') : null },
        });
    }
}
