// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
// Auth
import { AuthGuard } from './core/auth/_guards/auth.guard';
import { CommonModule } from '@angular/common';

const routes: Routes = [
    { path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then((m) => m.AuthModule) },
    {
        path: '',
        component: BaseComponent,
        children: [
            {
                path: 'frontpage',
                canActivate: [AuthGuard],
                loadChildren: () => import('./views/pages/frontpage/frontpage.module').then((m) => m.FrontpageModule),
            },
            {
                path: 'customer',
                canActivate: [AuthGuard],
                loadChildren: () => import('./views/pages/customer/customer.module').then((m) => m.CustomerModule),
            },
            {
                path: 'dfim',
                canActivate: [AuthGuard],
                loadChildren: () => import('./views/pages/dfim/dfim.module').then((m) => m.DfimModule),
            },
            {
                path: 'smstemplates',
                canActivate: [AuthGuard],
                loadChildren: () => import('./views/pages/sms-templates/sms-templates.module').then((m) => m.SmsTemplatesModule),
            },
            {
                path: 'folders',
                canActivate: [AuthGuard],
                loadChildren: () => import('./views/pages/folders/folders.module').then((m) => m.FoldersModule),
            },
            {
                path: 'signout',
                loadChildren: () => import('./views/pages/signout/signout.module').then((m) => m.SignoutModule),
            },
            {
                path: 'profile',
                canActivate: [AuthGuard],
                loadChildren: () => import('./views/pages/profile/profile.module').then((m) => m.ProfileModule),
            },
            {
                path: 'newpassword',
                canActivate: [AuthGuard],
                loadChildren: () => import('./views/pages/new-password/new-password.module').then((m) => m.NewPasswordModule),
            },
            {
                path: 'calculate',
                canActivate: [AuthGuard],
                loadChildren: () => import('./views/pages/calculate/calculate.module').then((m) => m.CalculateModule),
            },
            {
                path: 'summary',
                canActivate: [AuthGuard],
                loadChildren: () => import('./views/pages/offer-summary/offer-summary.module').then((m) => m.OfferSummaryModule),
            },
            {
                path: 'policymta',
                canActivate: [AuthGuard],
                loadChildren: () => import('./views/pages/policy-mta/policy-mta.module').then((m) => m.PolicyMtaModule),
            },
            {
                path: 'statistics',
                canActivate: [AuthGuard],
                loadChildren: () => import('./views/pages/statistics/statistics.module').then((m) => m.StatisticsModule),
            },
            {
                path: 'create/customer',
                canActivate: [AuthGuard],
                loadChildren: () => import('./views/pages/create-customer/create-customer.module').then((m) => m.CreateCustomerModule),
            },
            {
                path: 'dashboards',
                canActivate: [AuthGuard],
                loadChildren: () => import('./views/pages/dashboards/dashboards.module').then((m) => m.DashboardsModule),
            },
            {
                path: 'customerreviews',
                canActivate: [AuthGuard],
                loadChildren: () => import('./views/pages/customer-review/customer-review.module').then((m) => m.CustomerReviewModule),
            },
            {
                path: 'cpr',
                canActivate: [AuthGuard],
                loadChildren: () => import('./views/pages/cpr/cpr.module').then((m) => m.CprModule),
            },
            { path: '', redirectTo: 'frontpage', pathMatch: 'full' },
            { path: '**', redirectTo: 'frontpage', pathMatch: 'full' },
        ],
    },

    { path: '**', redirectTo: 'error/403', pathMatch: 'full' },
];

@NgModule({
    imports: [CommonModule, RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
