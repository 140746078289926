import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ApplicationInsightsService } from '../../services/application-insights-service/application-insights.service';
import { mapStackTrace } from 'sourcemapped-stacktrace';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) {}

    handleError(error: Error) {
        console.error(error);
        const appInsights = this.injector.get(ApplicationInsightsService);

        if (!error.stack) {
            appInsights.logException(error, SeverityLevel.Error);
            return;
        }

        mapStackTrace(error.stack, (stackTrace: string[]) => {
            appInsights.logException(error, SeverityLevel.Error, stackTrace);
        });
    }
}
