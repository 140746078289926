import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AlertService } from '../../../services/alert-service/alert.service';
import { AlertModel } from '../../../services/alert-service/alert-model';
import { Subscription } from 'rxjs';

@Component({
    selector: 'alert-dialog',
    templateUrl: './alert-dialog.component.html',
    styleUrls: ['./alert-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class AlertDialogComponent implements OnInit, OnDestroy {
    alertModel: AlertModel;
    subscriptions: Subscription[] = [];

    constructor(private alertService: AlertService, private changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit() {
        this.subscriptions.push(
            this.alertService.alertEmitter.subscribe((alert: AlertModel) => {
                this.alertModel = alert;
                this.changeDetectorRef.markForCheck(); // It seems that sometimes the dialog doesn't appear even with ChangeDetectionStrategy.Default set.
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    closeAlert() {
        this.alertService.setAlert(null);
    }
}
