// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
// Angular Material
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
// NgBootstrap
import { NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
// Translation
import { TranslateModule } from '@ngx-translate/core';
// Loading bar
import { LoadingBarModule } from '@ngx-loading-bar/core';
// Ngx DatePicker
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
// Perfect Scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';
// Core Module
import { CoreModule } from '../../core/core.module';
import { HeaderComponent } from './header/header.component';
import { AsideLeftComponent } from './aside/aside-left.component';
import { FooterComponent } from './footer/footer.component';
import { SubheaderComponent } from './subheader/subheader.component';
import { BrandComponent } from './brand/brand.component';
import { TopbarComponent } from './header/topbar/topbar.component';
import { MenuHorizontalComponent } from './header/menu-horizontal/menu-horizontal.component';
import { PartialsModule } from '../partials/partials.module';
import { BaseComponent } from './base/base.component';
import { PagesModule } from '../pages/pages.module';
import { HtmlClassService } from './html-class.service';
import { HeaderMobileComponent } from './header/header-mobile/header-mobile.component';
import { SearchComponent } from '../partials/layout/topbar/search/search.component';
import { AgencyTextMessageModalModule } from '../components/agency/agency-text-message-modal/agency-text-message-modal.module';
import { CalculateProgressModule } from '../pages/calculate/shared/calculate-progress/calculate-progress.module';
import { LeadsCreateModalModule } from '../components/leads-create-modal/leads-create-modal.module';
import { LeadsCreateBulkModalModule } from '../components/leads-bulk-create-modal/leads-bulk-create-modal.module';

@NgModule({
    declarations: [
        BaseComponent,
        FooterComponent,

        // headers
        HeaderComponent,
        BrandComponent,
        HeaderMobileComponent,

        // subheader
        SubheaderComponent,

        // topbar components
        TopbarComponent,

        // aside left menu components
        AsideLeftComponent,

        // horizontal menu components
        MenuHorizontalComponent,

        SearchComponent,
    ],
    exports: [
        BaseComponent,
        FooterComponent,

        // headers
        HeaderComponent,
        BrandComponent,
        HeaderMobileComponent,

        // subheader
        SubheaderComponent,

        // topbar components
        TopbarComponent,

        // aside left menu components
        AsideLeftComponent,

        // horizontal menu components
        MenuHorizontalComponent,
    ],
    providers: [HtmlClassService],
    imports: [
        CommonModule,
        RouterModule,
        PagesModule,
        PartialsModule,
        CoreModule,
        PerfectScrollbarModule,
        FormsModule,
        MatProgressBarModule,
        MatTabsModule,
        MatButtonModule,
        MatTooltipModule,
        TranslateModule.forChild(),
        LoadingBarModule,
        NgxDaterangepickerMd,
        InlineSVGModule,

        // ng-bootstrap modules
        NgbProgressbarModule,
        NgbTooltipModule,
        AgencyTextMessageModalModule,
        CalculateProgressModule,
        LeadsCreateModalModule,
        LeadsCreateBulkModalModule,
    ],
})
export class ThemeModule {}
