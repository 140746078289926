// Denmark
export const locale = {
    lang: 'da',
    data: {
        SHARED: {
            VALIDATION: {
                REQUIRED_FIELD: 'Påkrævet felt',
                MIN_LENGTH_FIELD: 'Minimum feltlængde:',
                MAX_LENGTH_FIELD: 'Maximum feltlængde:',
            },
            COMMON: {
                YES: 'Ja',
                NO: 'Nej',
                UNKNOWN: 'Ukendt',
                SAVE: 'Gem',
                CANCEL: 'Fortryd',
                CLOSE: 'Luk',
                CHOOSE_TYPE: 'Vælg type',
                ERROR: 'Fejl',
                SEND: 'Send',
                CURRENCY_SHORT: 'kr.',
                DATE: 'Dato',
                EMAIL: 'Email',
                STATUS: 'Status',
                ANSWERED: 'Besvaret',
                DISPATCHED: 'Afsendt',
                SCHEDULED: 'Planlagt',
                SEARCH: 'Søg',
                NEXT: 'Næste',
                OK: 'OK',
                LOADING: 'Indlæser...',
                OWNER: 'Ejer',
                USER: 'Bruger',
                CO_USER: 'Sekundær bruger',
                CLEAR_DATE: 'Nulstil',
                ADD: 'Tilføj',
                NOT_FOUND: 'Ikke fundet',
                NAME: 'Navn',
                MOBILE_NO: 'Mobil nummer',
            },
            MENU: {
                FRONTPAGE: 'Forside',
                SEARCH: 'Søg',
                SEARCH_CUSTOMER: 'Søg kunde',
                CUSTOMER_HANDLING: 'Kundehåndtering',
                CUSTOMER_OVERVIEW: 'Kundeoverblik',
                DFIM: 'DFIM',
                TOOLS: 'Værktøjer',
                DFIM_LOOKUP: 'DFIM opslag',
                CPR: 'CPR',
                CPR_LOOKUP: 'CPR opslag',
                DOCUMENTS: 'Dokumenter',
                SETTINGS: 'Opsætning',
                FOLDERS: 'Mapper',
                DOCUMENT_FOLDERS: 'Dokumentmapper',
                CREATE_CUSTOMER: 'Opret kunde',
                CREATE_OFFER: 'Opret tilbud',
                SMS_TEMPLATES: 'SMS tekster',
                TEXT_MESSAGES: 'Send SMS',
                STATISTICS: 'Statistik',
                QUESTIONNAIRE_REPORT: 'Spørgeskemarapport',
                CALCULATE: 'Beregn',
                POLICY_MTA: 'Policeændring',
                DASHBOARDS: 'Dashboards',
                EDIT_DASHBOARDS: 'Opsæt dashboards',
                VIEW_DASHBOARD: 'Vis dashboard',
                CUSTOMER_REVIEWS: 'Kundeanmeldelser',
                CREATE_LEAD: 'Opret lead',
                CREATE_BULK_LEADS: 'Opret bulk leads',
            },
        },
        COMPONENTS: {
            CUSTOMER_CONSENT: {
                CUSTOMER_CONSENT_GIVEN: 'Kunden har givet samtykke',
                CUSTOMER_CONSENT_REJECTED: 'Kunden har afvist at give samtykke',
                CUSTOMER_CONSENT_WITHDRAWN: 'Kunden har trukket samtykket tilbage',
                CHOOSE_CONSENT_TYPE: 'Vælg samtykke type',
                WARNING_LINE_1: 'Advarsel!!!',
                WARNING_LINE_2: 'Kunden skal give samtykke ellers kan vi ikke beholde vedkommende som kunde.',
                ERROR_MESSAGE: 'Der skete en fejl. Prøv igen eller opdater siden.',
            },
            CUSTOMER_WARNING_MODAL: {
                TITLE: 'Advarsel',
                WARNING_QUESTION: 'Skal der være en advarsel på kunden?',
            },
            BLOCKED_CPR_MODAL: {
                TITLE: 'Kunden er blevet afvist via website!',
                BLOCKED_NOTE: 'OBS! Kunden har forsøgt at indtegne sig via website og er blevet afvist',
                DATE: 'Dato',
                REASON: 'Årsag',
                REMARK: 'Bemærkning',
            },
            CPR_MODAL: {
                TITLE: 'CPR opslag',
                NAME: 'Navn',
                CPR: 'CPR',
                ADDRESS: 'Adresse',
                ADDRESS_PROTECTION: 'Adressebeskyttet',
                CPR_STATUS: 'CPR status',
            },
            LEADS_SCHEDULED: {
                TITLE: 'Mine aftaler',
                CHOOSE_DATE: 'Vælg dato',
                CALL_BACK_TIME: 'Ring tilbage',
                NAME: 'Navn',
                PHONE: 'Telefon',
                COMMENTS: 'Kommentarer',
                LEAD_COMMENT: 'Lead kommentar',
            },
            LEADS_CREATE_CONTACT_MODAL: {
                TITLE: 'Opret kontakt',
                CAMPAIGN: 'Kampagne',
                CREATE: 'Opret',
            },
            LEADS_CLOSE_OFFER_MODAL: {
                TITLE: 'Luk tilbud',
                CLOSE_REASON: 'Vælg afvisningsårsag',
                WHICH_PRODUCT: 'Hvilket produkt',
            },
            LEADS_CLOSE_MODAL: {
                TITLE: 'Luk lead',
                REASON: 'Begrundelse',
                COMMENT: 'Kommentar',
                CLOSE_LEAD: 'Luk lead',
            },
            LEADS_CALLBACK_MODAL: {
                TITLE: 'Ring tilbage senere',
                DATE: 'Dato',
                TIME: 'Tidspunkt',
                COMMENT: 'Kommentar',
                CREATE: 'Opret',
                VALIDATION_TEXT_TOO_EARLY: 'Tidspunktet skal være senere end 5 minutter fra nu.',
                CALLBACK_TO: 'Ring tilbage til',
            },
            LEADS_CREATE_MODAL: {
                TITLE: 'Opret lead',
                AGENCY: 'Agentur',
                CAMPAIGN: 'Kampagne',
                NAME: 'Navn',
                PHONE_NUMBER: 'Mobil nummer',
                COMMENT: 'Kommentar',
                CREATE: 'Opret',
                EMAIL: 'Email',
            },
            LEADS_BULK_CREATE_MODAL: {
                TITLE: 'Opret bulk lead',
                AGENCY: 'Agentur',
                CAMPAIGN: 'Kampagne',
                CREATE: 'Opret',
                DRAG_DROP_MESSAGE: 'Træk en fil hertil eller klik for at vælge en fil',
                DRAG_DROP_FILE_TYPES: 'Tilladte filtyper: CSV',
                COLUMN_HELP_TEXT: 'Kolonneformat i række 1: FirstName;SurName;PhoneNumber;Email;Comment',
            },
            LEADS_NO_CONTACT_MODAL: {
                TITLE: 'Luk lead med ingen kontakt',
                CLOSE_LEAD: 'Luk lead',
                COMMENT: 'Kommentar',
                SMS_TYPE: 'SMS type',
                DANISH_SMS: 'Dansk SMS',
                ENGLISH_SMS: 'Engelsk SMS',
                DANISH_SMS_MESSAGE: `
Hej [REPLACE_NAME]\n
Vi har forsøgt at ringe til dig angående forsikringstilbud, dog uden held.\n
Hvis dette har nogen interesse, kan du kontakte os på Tlf: 70 40 70 40\n
Med venlig hilsen\n
NEXT Forsikringsformidling
`,
                ENGLISH_SMS_MESSAGE: `
Hello [REPLACE_NAME],\n
We have tried to call you regarding insurance quotes, but without success.\n
If this has any interest, you can contact us on Tel: 70 40 70 40\n
Yours sincerely,\n
NEXT Insurance brokerage
`,
            },
            LEADS_REMOVE_MODAL: {
                TITLE: 'Fjern lead',
                TEXT: 'Er du sikker på du vil fjerne leadet?',
                CLOSE_LEAD: 'Fjern lead',
            },
            LEADS_COMPONENT: {
                TITLE: 'Leads',
                WRAPUP_REASON: 'Afvisningsårsag',
                CHOOSE_WRAPUP_REASON: 'Vælg afvisningsårsag',
                CANCEL_LEAD: 'Afvis lead',
                CREATE_LEAD: 'Nyt lead',
                CLOSE_LEAD: 'Luk lead',
                REMOVE_LEAD: 'Fjern lead',
                PHONE: 'Telefonnummer',
                LEAD_ID: 'Henvisnings-ID',
                LEAD_REFERENCE: 'Henvisninsreference',
                WRAPUP_LEAD_FAILED_MESSAGE: 'Kunne ikke afvise lead. Prøv igen.',
                GET_LEAD_FAILED_MESSAGE: 'Kunne ikke hente lead. Prøv igen.',
                GET_LEAD_NO_MORE_LEADS_MESSAGE: 'Der var ikke flere leads at hente.',
                CLOSE_LEAD_FAILED_MESSAGE: 'Kunne ikke gennemføre lead. Prøv igen.',
                CREATE_CONTACT: 'Opret kontakt',
                CAMPAIGN: 'Kampagne',
                COMMENT: 'Kommentar',
                GET_LEAD: 'Hent lead',
                NAME: 'Navn',
                CALL_BACK: 'Ring tilbage',
                NO_CONTACT: 'Ingen kontakt',
                ADDITIONAL_COMMENT: 'Yderligere kommentar',
                EMAIL: 'Email',
                LAST_UPDATED: 'Sidst opdateret',
                STATUS: 'Status',
                CALL_TRIES: 'Opkaldsforsøg',
            },
            INVOICES_COMPONENT: {
                POLICY: 'Police',
                PRODUCT: 'Produkt',
                INVOICE_DATE: 'Fakturadato',
                DUE_DATE: 'Betalingsdato',
                AMOUNT: 'Beløb',
                STATUS: 'Status',
            },
            EDIT_CASE_WORKER_MODAL: {
                TITLE: 'Redigér selskabsoplysninger',
                CHOOSE_CASE_WORKER: 'Vælg sagsbehandler',
                REFERRAL: 'Henvisning',
                SAVE: 'Gem',
                CANCEL: 'Fortryd',
                ERROR: 'En fejl opstod - prøv igen.',
            },
            EDIT_ADDRESS_MODAL: {
                TITLE: 'Redigér kundeoplysninger',
                EDIT_ADDRESS: 'Redigér adresse',
                ADDRESS_SEARCH: 'Adressesøger',
                ADDRESS_INPUT: 'Adressefelter',
                ADDRESS_FOREIGN: 'Udenlandsk adresse',
                STREET_NAME: 'Vejnavn',
                HOUSE_NO: 'Husnummer',
                LEVEL: 'Etage',
                DOOR_SIDE: 'Dør',
                ZIP_CODE: 'Postnummer',
                CITY: 'By',
                ADDRESS_LINES: 'Indtast adresse',
                COUNTRY_CODE: 'Landekode',
                SAVE: 'Gem',
                CANCEL: 'Fortryd',
                ERROR: 'En fejl opstod - prøv igen.',
            },
            DOCUMENT_NOTE_MODAL: {
                TITLE: 'Note',
                FROM: 'Fra',
                CHANNEL: 'Kanal',
                DESCRIPTION: 'Beskrivelse',
                SPOKE_ABOUT: 'Talte om',
                CONCLUSION: 'Konklusion',
                WHEN: 'Dato',
                CLOSE: 'Luk',
            },
            CUSTOMER_DOCUMENTS_COMPONENT: {
                DOCUMENT: 'Dokument',
                DATE: 'Dato',
                CREATED_BY: 'Oprettet af',
                ADD: 'Tilføj',
                UPLOAD_NOTICE: 'Vær opmærksom på at dokumenterne er tilgængelige i kundens selvbetjening.',
            },
            UPLOAD_DOCUMENT_MODAL: {
                TITLE: 'Upload fil',
                UPLOAD: 'Upload',
                CANCEL: 'Fortryd',
                DRAG_DROP_MESSAGE: 'Træk filer hertil eller klik for at vælge en fil',
                DRAG_DROP_FILE_TYPES: 'Tilladte filtyper: PDF, Word, TXT, JPG, BMP, GIF',
            },
            CREDIT_INFORMATION_MODAL: {
                TITLE: 'RKI opslag',
                NO_REGISTRATIONS_MESSAGE: 'Der er ingen registreringer på kunden.',
                CREDITOR: 'Kreditor',
                AMOUNT: 'Beløb',
                HANDOFF_DATE: 'Videregivelsesdato',
                DELETED_DATE: 'Slettetdato',
                CLOSE: 'Luk',
            },
            CUSTOMER_TEXT_MESSAGE_MODAL: {
                TITLE: 'Send SMS',
                CHOOSE_PHONE_NUMBER: 'Vælg nummer',
                YES: 'Ja',
                NO: 'Nej',
                CHOOSE_ALLOW_REPLY: 'Mulighed for besvarelse?',
                RECIPIENT_PHONE_NUMBER: 'Modtager nummer',
                TEMPLATE: 'Skabelon',
                CHOOSE_TEMPLATE: 'Vælg skabelon',
                NO_PHONE_NUMBERS: 'Der er ingen telefonnumre af typen Mobil oprettet for denne kunde.',
            },
            QUESTIONNAIRE_MODAL: {
                TITLE: 'Send spørgeskema',
                RECIPIENT: 'Modtager',
                SCHEMA: 'Skema',
                TIME: 'Tidspunkt',
                PREVIOUS_FUTURE_SENT: 'Tidligere/fremtidige afsendelse',
                DATE: 'Dato',
                STATUS: 'Status',
                CHOOSE_EMAIL: 'Vælg email',
                CHOOSE_SCHEMA: 'Vælg skema',
                CHOOSE_DATE: 'Vælg tidspunkt',
                SEE_ANSWERS: 'Se svar',
                BACK: 'Tilbage til spørgeskemaer',
                CLOSE: 'Luk',
                ANSWER_TITLE: 'Svar for spørgeskema:',
                QUESTION: 'Spørgsmål',
                ANSWER: 'Svar',
                NO_ANSWERS_FOUND: 'Ingen svar fundet.',
                GET_ANSWERS_ERROR: 'Der opstod en fejl under indlæsning af svar.',
            },
            POLICY_AGREEMENT_MODAL: {
                TITLE: 'Policeændring',
                DISCOUNT_AGREEMENT: 'Rabataftale',
                GO_TO_MTA: 'Gå til policeændring',
                EDIT_POLICY_CREATION: 'Redigér policeoprettelse',
                FUTURE_EDIT_WARNING: `OBS! Der ligger en fremtidig ændring.
                Du skal huske, at hvis du ønsker at foretage en ændring, der også skal gælde for fremtidige hændelser, så skal du efter ændringen - gennemføre en ny ændring for hver fremtidig hændelse.
                Dette er ikke muligt via SalesPortal - kontakt kundeservice.`,
                CANCEL_POLICY: 'Annullér police',
                LOADING_FAILED: 'Der opstod en fejl under indlæsning af policen',
            },
            POLICY_CANCEL_MODAL: {
                TITLE: 'Annullér police',
                REASON: 'Begrundelse',
                REASON_REMARK: 'Bemærkning',
                CANCEL_DATE: 'Hændelsesdato',
                CHOOSE_DATE: 'Vælg dato',
                CANCEL_ERROR: 'Der opstod en fejl. Policen blev ikke annulleret.',
            },
            EDIT_FOLDER_MODAL: {
                TITLE: 'Mappe opsætning',
                NAME: 'Navn',
                TYPE_NAME: 'Indtast navn',
                TYPE: 'Type',
                LIMITED: 'Begrænset',
                OPEN: 'Åben',
                CHOOSE_TYPE: 'Vælg type',
                CHOOSE_AGENCIES: 'Vælg agenturer',
                CHOOSE_USERS: 'Vælg brugere',
                AGENCIES_ACCESS: 'Agenturadgang',
                USERS_ACCESS: 'Brugeradgang',
                SAVE: 'Gem',
                CANCEL: 'Fortryd',
                FOLDER_ALREADY_EXISTS_MESSAGE: 'Der er allerede en mappe med det valgte navn. Venligst vælg et andet.',
                CLOSE_WITHOUT_SAVING_MESSAGE: 'Du har lavet ændringer der ikke er blevet gemt. Er du sikker på du vil lukke?',
                CLOSE_WITHOUT_SAVING: 'Luk uden at gemme',
            },
            MOTORCARD_MODAL: {
                TITLE: 'Generér  dokument',
                GREEN_CARD: 'Grønt kort',
                RED_CARD: 'Rødt kort',
                VALID_FROM: 'Gyldigt fra',
                VALID_TO: 'Gyldigt til',
                DOWNLOAD_DOCUMENT: 'Hent dokument',
                CANCEL: 'Fortryd',
                CHOOSE_DATE: 'Vælg dato',
            },
            CUSTOMER_CLAIMS_COMPONENT: {
                DATE: 'Dato',
                PRODUCT: 'Produkt',
                POLICY_NO: 'Police nr.',
                DESCRIPTION: 'Beskrivelse',
                STATUS: 'Status',
            },
            CUSTOMER_OFFERS_COMPONENT: {
                OFFER_NO: 'Tilbudsnummer',
                CREATED_BY: 'Oprettet af',
                PRODUCTS: 'Produkter',
                STATUS: 'Status',
                PRICES: 'Priser',
                TOTAL_PRICE: 'Pris (total)',
                ACTIONS: 'Handlinger',
                DELETE: 'Slet',
            },
            DFIM_MODAL: {
                TITLE: 'DFIM opslag',
            },
            AGENCY_TEXT_MESSAGE_MODAL: {
                TITLE: 'Send SMS',
                CHOOSE_AGENCY: 'Vælg agentur',
                AGENCY: 'Agentur',
                MESSAGE: 'Besked',
                MESSAGE_PLACEHOLDER: 'Skriv besked',
                MAX_CHARACTERS_MESSAGE: 'Du må maksimalt skrive 306 tegn',
                TEMPLATE: 'Skabelon',
                CHOOSE_TEMPLATE: 'Vælg skabelon',
                SINGLE: {
                    RECIPIENTS: 'Modtager',
                    RECIPIENTS_PLACEHOLDER: 'Indtast modtager',
                    RECIPIENTS_EXAMPLE: 'Fx. "12345678"',
                    INVALID_PHONE_NUMBERS_MESSAGE: 'Telefonnummeret er i et ugyldigt format. Vær sikker på at der kun er indtastet tal og det er 8 cifre langt.',
                },
                BULK: {
                    RECIPIENTS: 'Modtagere',
                    RECIPIENTS_PLACEHOLDER: 'Indtast modtagere',
                    RECIPIENTS_EXAMPLE: 'For at sende til flere modtagere, kan man separere numre med komma, semikolon, mellemrum eller ny linje. Fx. "12345678,12345679"',
                    INVALID_PHONE_NUMBERS_MESSAGE:
                        'Et telefonnummer er i et ugyldigt format. Vær sikker på det er 8 cifre langt og at efterfølgende numre er separeret af komma, semikolon, mellemrum eller ny linje.',
                },
            },
            CUSTOMER_OFFER_DELETE_MODAL: {
                TITLE: 'Slet tilbud',
                REASON: 'Begrundelse',
                CHOOSE_REASON: 'Vælg begrundelse',
                REMARK: 'Bemærkning',
                REMARK_PLACEHOLDER: 'Skriv evt. en bemærkning',
                GENERIC_ERROR_MESSAGE: 'Der opstod desværre en fejl.',
                DELETE: 'Slet',
                CANCEL: 'Fortryd',
            },
            PAYMENT_SERVICE_MODAL: {
                TITLE: 'Betalingsservice',
                TYPE: 'Type',
                SUBSCRIBE: 'Tilmeld',
                UNSUBSCRIBE: 'Afmeld',
                POLICIES: 'Policer',
                CHOOSE_POLICIES: 'Vælg en eller flere policer',
                REG_NO: 'Reg. nr.',
                REG_NO_PLACEHOLDER: 'Indtast reg. nr.',
                ACCOUNT_NO: 'Konto nr.',
                ACCOUNT_NO_PLACEHOLDER: 'Indtast konto nr.',
                EXECUTE: 'Udfør',
                SUBSCRIBE_POSTFIX: 'tilmelding',
                UNSUBSCRIBE_POSTFIX: 'afmelding',
                STATUS_SUCCESS: 'Følgende policenumre blev udført med succes:',
                STATUS_FAIL: 'Følgende policenumre fejlede:',
                STATUS_NO_POLICIES: '- ingen policer -',
            },
        },
        SERVICES: {
            ALERT_SERVICE: {
                ERROR: 'Fejl',
            },
            CUSTOMER_SERVICE: {
                INVALID_CPR_TITLE: 'Ugyldig CPR',
                INVALID_CPR_MESSAGE: 'Der kunne ikke hentes oplysninger på det indtastede cpr nummer. Tjek om det er et gyldigt cpr nummer.',
            },
        },
        VIEWS: {
            ALERT_DIALOG: {
                ERROR_TEXT: 'Der skete desværre en fejl. Prøv venligst igen. Gentager fejlen sig, er du velkommen til at kontakte teknisk support.',
                TRACE_DESCRIPTION: 'Oplys fejl-ID ved kontakt.',
                TRACE_ID: 'Fejl-ID',
                PHONE_LABEL: 'Telefon:',
                EMAIL_LABEL: 'Email:',
                SESSION_EXPIRED: 'Din login session blev forældet. Log ind igen.',
                CLOSE: 'Luk',
                CALCULATION_FAILED: 'Beregningen fejlede. Prøv at justér de indtastede informationer.',
            },
            EDIT_EMAILS_MODAL: {
                TITLE: 'Redigér email',
                PRIVATE: 'Privat',
                WORK: 'Arbejde',
                EMAIL_PLACEHOLDER: 'Indtast email',
                UNSAVED_CHANGES: 'Der er ændringer, der ikke er gemt. Er du sikker på du vil lukke?',
                INVALID_INPUTS: 'Du skal huske at udfylde alle felter inden du kan gemme.',
                SAVE: 'Gem',
                CLOSE_NO_SAVE: 'Luk uden at gemme',
                CLOSE: 'Luk',
            },
            EDIT_RELATIONS_MODAL: {
                TITLE: 'Redigér relationer',
                ADD_RELATION_PLACEHOLDER: 'Indtast kundenummer, CPR eller CVR',
                UNSAVED_CHANGES: 'Der er ændringer, der ikke er gemt. Er du sikker på du vil lukke?',
                INVALID_INPUTS: 'Du skal huske at udfylde alle felter inden du kan gemme.',
                SAVE: 'Gem',
                CLOSE_NO_SAVE: 'Luk uden at gemme',
                CLOSE: 'Luk',
                RELATION: 'Relation',
                NAME: 'Navn',
                ADDRESS: 'Adresse',
                CUSTOMER_NO: 'Kundenummer',
                CHANGE: 'Ændring',
                CHANGE_DELETE: 'Slettes',
                CHANGE_CREATE: 'Tilføjes',
                CHANGE_NONE: 'Ingen',
                DELETE: 'Slet',
                ADD: 'Tilføj',
                CANNOT_CREATE_CUSTOMER: 'Kunden kunne desværre ikke oprettes. Er du sikker på oplysningerne er korrekte?',
                CANNOT_ADD_SAME_CUSTOMER_RELATION: 'Det er ikke tilladt at tilføje en relation til sig selv.',
            },
            EDIT_PHONES_MODAL: {
                TITLE: 'Redigér telefonnummer',
                MOBILE: 'Mobil',
                WORK: 'Arbejde',
                LANDLINE: 'Fastnet',
                FAX: 'Fax',
                PHONE_PLACEHOLDER: 'Indtast nummer',
                UNSAVED_CHANGES: 'Der er ændringer, der ikke er gemt. Er du sikker på du vil lukke?',
                INVALID_INPUTS: 'Du skal huske at udfylde alle felter inden du kan gemme.',
                INVALID_NUMBER: 'Ugyldigt nummer indtastet.',
                SAVE: 'Gem',
                CLOSE_NO_SAVE: 'Luk uden at gemme',
                CLOSE: 'Luk',
            },
            LAST_VISITED_USERS: {
                USER: 'Bruger',
                DATE: 'Dato',
            },
            TIMEOUT_WARNING: {
                TITLE: 'Du har været inaktiv i længere tid.',
                TEXT: 'Af sikkerhedsmæssige årsager vil du blive logget ud, hvis du ikke foretager dig noget.',
                COUNTDOWN_TEXT: 'Du vil automatisk blive logget ud om',
                LOG_OFF: 'Log af',
                STAY: 'Bliv på siden',
            },
            NOTES: {
                DATE: 'Dato',
                NOTE: 'Note',
                CREATE_NOTE: 'Opret note',
                CREATE_NOTE_PLACEHOLDER: 'Indtast note',
            },
            POLICY: {
                SHARED: {
                    SECONDARY_POLICY_HOLDER: 'Sekundær forsikringstager',
                },
                CHANGE_OF_OWNERSHIP: {
                    CHANGE_OF_OWNERSHIP_INSURANCE: 'Ejerskifteforsikring',
                },
                CARAVAN_DETAILS: {
                    CARAVAN_INSURANCE: 'Campingvognsforsikring',
                    USAGE_AND_TYPE: 'Type',
                    BRAND: 'Mærke',
                    MODEL: 'Model',
                    MODELYEAR: 'Årgang',
                    MARKETVALUE: 'Handelsværdi',
                    REGNO: 'Registreringsnummer',
                    CHASSISNO: 'Stelnummer',
                    STATIONARY: 'Stillestående',
                    SUPPLEMENTARY_INFO: 'Supplerende oplysninger',
                },
                CAR_DETAILS: {
                    CAR_INSURANCE: 'Bilforsikring',
                    BRAND: 'Mærke',
                    MODEL: 'Model',
                    VARIANT_NAME: 'Variant',
                    MODEL_YEAR: 'Modelår',
                    MOTOR_SIZE: 'Motorstørrelse',
                    WEIGHT: 'Egenvægt (Kg.)',
                    HORSE_POWER: 'Hk',
                    CHASSIS_TYPE: 'Karosseri',
                    NEW_VALUE: 'Nyværdi',
                    YEAR_OF_FIRST_REGISTRATION: '1. indregistreringsår',
                    REG_NO: 'Registreringsnummer',
                    CHASSIS_NUMBER: 'Stelnummer',
                    VEHICLE_ID: 'Køretøjs-id',
                    VEHICLE_MMVT: 'KøretøjsMMVT',
                    USAGE_AND_TYPE_NAME: 'Art og anvendelse',
                    STAND_STILL: 'Stilstand',
                    LEASING: 'Leasing',
                    MAX_KM_PR_YEAR_NAME: 'Maks. km. pr. år',
                    CURRENT_METER_READING: 'Km-stand',
                    DATE_OF_METER_READING: 'Aflæsningsdato',
                    SUPPLEMENTARY_INFO: 'Supplerende oplysninger',
                    BONUS_CLASS: 'Bonusklasse',
                },
                MOPED_DETAILS: {
                    MOPED_INSURANCE: 'Knallertforsikring',
                    USAGE_AND_TYPE_NAME: 'Type',
                    BRAND: 'Mærke',
                    MODEL: 'Model',
                    REGNO: 'Registreringsnummer',
                    CHASSISNO: 'Stelnummer',
                    VEHICLE_ID: 'Køretøjsid',
                    YEAR_OF_FIRST_REGISTRATION: 'Årgang',
                    MARKET_VALUE: 'Handelsværdi',
                },
                MC_DETAILS: {
                    MC_INSURANCE: 'Motorcykelforsikring',
                    BRAND: 'Mærke',
                    MODEL: 'Model',
                    NEWVALUE: 'Nyværdi',
                    REGNO: 'Registreringsnummer',
                    CHASSISNO: 'Stelnummer',
                    LEASING: 'Leasing',
                    BONUSCLASS: 'Bonustrin',
                    DRIVING_PERIODE_NAME: 'Kørselsperiode',
                    SUPPLEMENTARY_INFO: 'Supplerende oplysninger',
                },
                SALARY_DETAILS: {
                    SALARY_INSURANCE: 'Lønforsikring',
                    TRANSFEREDFROMOTHERCOMPANY: 'Er forsikringen overført fra andet selskab',
                    COMPANYNAME: 'Selskabsnavn',
                    SENIORITYMONTHS: 'Anciennitet i tidligere selskab (mdr.)',
                    TOTALCOVERSUMPRMONTH: 'Samlet dækningsbeløb i tidligere selskab (pr. md.)',
                    ID: 'ID',
                    NAME: 'Navn',
                    OCCUPATION: 'Beskæftigelse',
                    COVER_PER_MONTH: 'Dækningssum',
                    DEDUCTIBLE_MONTHS: 'Selvrisikomåneder',
                    PAYOUTMONTHS: 'Udbetalingsmåneder',
                    CUSTOMER_NO: 'Kundenunmmer',
                    BIRTHDATE: 'Fødselsdato',
                    AGE: 'Alder',
                    MONTHS: 'måneder',
                    CURRENCY: 'kr',
                    NUMBER: 'Nummer',
                },
                TRACTOR_DETAILS: {
                    TRACTOR_INSURANCE: 'Traktorforsikring',
                    BRAND: 'Mærke',
                    MODEL: 'Model',
                    YEAR_OF_FIRST_REGISTRATION: 'Årgang',
                    NEWVALUE: 'Nyværdi',
                    REGNO: 'Registreringsnummer',
                    CHASSISNO: 'Stelnummer',
                    USAGE: 'Anvendelse',
                },
                TRAILER_DETAILS: {
                    TRAILER_INSURANCE: 'Trailerforsikring',
                    USAGE_AND_TYPE: 'Anvendelse og type',
                    BRAND: 'Mærke',
                    MODEL: 'Model',
                    YEAR_OF_FIRST_REGISTRATION: 'Årgang',
                    MARKET_VALUE: 'Markedsværdi',
                    REGNO: 'Registreringsnummer',
                    CHASSISNO: 'Stelnummer',
                },
                ACCIDENT_DETAILS: {
                    ACCIDENT_INSURANCE: 'Ulykkeforsikring',
                    NAME: 'Navn',
                    CUSTOMER_NO: 'Kundenummer',
                    DEGREE_PERMANENT_INJURY: 'Permanent skadegrad',
                    INSURANCE_SCOPE: 'Type',
                    OCCUPATION: 'Beskæftigelse',
                    CLOSEST_RELATIVE_AS_BENEFICIEARY: 'Police begunstiger nærmeste familiemedlem',
                    BENEFICIERAY_PERCENTAGE: 'Begunstigelsesgrad',
                    BENEFICIEARY: 'Begunstiget',
                    RISKS: 'Særlige risici',
                },
                DOG_DETAILS: {
                    DOG_INSURANCE: 'Hundeforsikring',
                    CALLNAME: 'Navn',
                    RACE: 'Race',
                    PEDIGREENAME: 'Stambogsnavn',
                    BIRTHDATE: 'Fødselsdato',
                    GENDER: 'Køn',
                    CHIP_NUMBER: 'Chipnummer',
                },
                HOUSE_DETAILS: {
                    HOUSE_INSURANCE: 'Husforsikring',
                    INSURANCE_PLACE: 'Forsikringssted',
                    USAGE_AND_TYPE: 'Anvendelse og type',
                    TOTAL_RESIDENCE_AREA: 'Areal, beboelse',
                    TOTAL_BUSINESS_AREA: 'Areal, erhverv',
                    TOTAL_GARAGE_AREA: 'Areal, garage',
                    TOTAL_AREA_EXCLUDING_BASEMENT: 'Total areal ekskl. kælder',
                    SUM_FOR_STAMP_DUTY: 'Stempelafgift',
                    BUILDINGS: 'Bygninger',
                    BUILDING_NO: 'Bygningsnummer',
                    BUILDING_STATUS_NAME: 'Bygningsstatus',
                    EXPECTED_COMPLETION_DATE: 'Forventet færdiggørelsesdato',
                    USAGE_NAME: 'Anvendelse',
                    HEATING_NAME: 'Opvarmningsform',
                    ROOF_TYPE_NAME: 'Tagtype',
                    RESIDENCE_AREA: 'Areal, beboelse',
                    CONSTRUCTION_YEAR: 'Opførelsesår',
                    BUSINESS_AREA: 'Areal, erhverv',
                    GARAGE_AREA: 'Areal, garage',
                    BASEMENT: 'Kælder',
                    FREE_HEATING_TEXT: 'Opvarmningsform (fritekst)',
                    FREE_ROOF_TYPE_TEXT: 'Tagtype (fritekst)',
                    RENOVATION_YEAR_ROOF: 'Renoveringsår, tag',
                    RENOVATION_YEAR_WINDOWS_AND_DOORS: 'Renoveringsår, vinduer og døre',
                    RENOVATION_YEAR_WIRES: 'Renoveringsår, ledninger',
                    RENOVATION_YEAR_PIPES: 'Renoveringsår, rør',
                    SUPPLEMENTARY_INFO: 'Supplerende oplysninger',
                },
                FARMHOUSE_DETAILS: {
                    NAME: 'Forsikringsnavn',
                    STAMP_DUTY: 'Stempelafgift',
                    INSURANCE_PLACE: 'Forsikringssted',
                    USAGE_AND_TYPE: 'Anvendelse og type',
                    TOTAL_RESIDENCE_AREA: 'Areal, beboelse',
                    TOTAL_BUSINESS_AREA: 'Areal, erhverv',
                    TOTAL_GARAGE_AREA: 'Areal, garage',
                    TOTAL_AREA_EXCLUDING_BASEMENT: 'Total areal ekskl. kælder',
                    SUM_FOR_STAMP_DUTY: 'Stempelafgift',
                    BUILDINGS: 'Bygninger',
                    BUILDING_NO: 'Bygningsnummer',
                    BUILDING_STATUS_NAME: 'Bygningsstatus',
                    EXPECTED_COMPLETION_DATE: 'Forventet færdiggørelsesdato',
                    USAGE_NAME: 'Anvendelse',
                    HEATING_NAME: 'Opvarmningsform',
                    ROOF_TYPE_NAME: 'Tagtype',
                    RESIDENCE_AREA: 'Areal, beboelse',
                    CONSTRUCTION_YEAR: 'Opførelsesår',
                    BUSINESS_AREA: 'Areal, erhverv',
                    GARAGE_AREA: 'Areal, garage',
                    BASEMENT: 'Kælder',
                    FREE_HEATING_TEXT: 'Opvarmningsform (fritekst)',
                    FREE_ROOF_TYPE_TEXT: 'Tagtype (fritekst)',
                    RENOVATION_YEAR_ROOF: 'Renoveringsår, tag',
                    RENOVATION_YEAR_WINDOWS_AND_DOORS: 'Renoveringsår, vinduer og døre',
                    RENOVATION_YEAR_WIRES: 'Renoveringsår, ledninger',
                    RENOVATION_YEAR_PIPES: 'Renoveringsår, rør',
                    SUPPLEMENTARY_INFO: 'Supplerende oplysninger',
                    HOUSE_INSURANCE: 'Husforsikring',
                    HOME_INSURANCE: 'Indboforsikring',
                    DOG_INSURANCE: 'Hundeforsikring',
                    OUTHOUSE_INSURANCE: 'Udhusforsikring',
                    INSURANCE_SUM: 'Forsikringssum',
                    VAT_REGISTERED: 'Momsregistreret',
                    HAY_AND_STRAW: 'Hø og strå',
                    INSURANCE_TYPE: 'Forsikringstype',
                },
                HOME_DETAILS: {
                    HOME_INSURANCE: 'Indboforsikring',
                    INSURANCE_PLACE: 'Forsikringssted',
                    HOUSING_TYPE_NAME: 'Boligtype',
                    ROOF_TYPE_NAME: 'Tagtype',
                    NUMBER_OF_OCCUPANTS: 'Personer i hustanden',
                    RESIDENCE_AREA: 'Beboelsesareal',
                    BUSINESS_AREA: 'Erhvervsareal',
                    GARAGE_AREA: 'Garage og udhuse',
                    OVERWRITE_CALCULATED_AREA_VALUES: 'Overskriv beregnede arealer',
                    SUPPLEMENTARY_INFO: 'Supplerende oplysninger',
                    INSURED_ITEMS_BIKES: 'Udvidet cykeldækning',
                    BIKE: 'Cykel',
                    BIKE_FRAME_NO: 'Stelnummer',
                },
                BOAT_DETAILS: {
                    BOAT_INSURANCE: 'Bådforsikring',
                    BOAT_TYPE: 'Bådtype',
                    CERTIFICATION: 'Bådførers certificering',
                    CERTIFICATION_5: 'Over 5 års erfaring og ingen skader de sidste 3 år',
                    CERTIFICATION_10: 'Over 10 års erfaring og ingen skader de sidste 3 år',
                    CERTIFICATION_20: 'Over 20 års erfaring og ingen skader de sidste 3 år',
                    CERTIFICATION_NONE: 'Ingen',
                    USED_IN_SALTWATER: 'Brugt i saltvand',
                    USED_IN_FRESHWATER: 'Brugt i ferskvand',
                    MOTORIZED: 'Har motor',
                    SPEEDBOAT: 'Speedbåd',
                    MOTOR_YEAR: 'Motor år',
                    MOTOR_HORSEPOWER: 'Hestekræfter',
                    LENGTH_FOOT: 'Længde i fod',
                    MAX_SPEED_KNOB: 'Tophastighed (knob)',
                    CONSTRUCTION_YEAR: 'Årgang',
                    LOCATION_HARBOR_REMARK: 'Lokationsbemærkning',
                    MOTOR_SERIAL_NO: 'Motor serienummer',
                    MOTOR_BRAND: 'Motor mærke',
                    FUSELAGE_SERIAL: 'Skrog serienummer',
                    BOAT_NAME: 'Navn',
                    HARBOR: 'Havn',
                    LOCATION: 'Lokation',
                    SAILING_AREA: 'Sejleområde',
                    SAILING_PERIOD: 'Sejlperiode',
                    MOTOR_TYPE: 'Motortype',
                    FUSELAG: 'Skrog',
                    BRAND: 'Mærke',
                    MODEL: 'Model',
                    BUILD_NO: 'Byggenummer',
                    SUPPLEMENTARY_INFO: 'Supplerende informationer',
                },
            },
        },
        PAGES: {
            CPR: {
                SEARCH: 'Søg',
                AGENCY: 'Agentur',
                SEARCH_CPR: 'Søg med CPR',
                CPR: 'CPR',
                CPR_STATUS: 'CPR status',
                INVALID_INPUTS_MESSAGE: 'Venligst udfyld de påkrævede felter',
                EXTENDED_SEARCH: 'Udvidet søgning',
                PROTECTED_ADDRESS_WARNING: 'Personen er adressebeskyttet og der skal derfor bruges "Udvidet beskyttet adresse søgning"',
                EXTENDED_ADDRESS_PROTECTED_SEARCH: 'Udvidet beskyttet adresse søgning',
                FULL_NAME: 'Fulde navn',
                SEARCH_RESULT: 'Søgeresultat',
                NAME: 'Navn',
                ADDRESS: 'Adresse',
                ADDRESS_PROTECTION: 'Adressebeskyttet',
                ADVERTISEMENT_PROTECTION: 'Reklamebeskyttet',
            },
            CUSTOMER_REVIEWS: {
                SEND_CUSTOMER_REVIEWS: 'Afsend kundeanmeldelser',
                AGENCIES: 'Agenturer',
                CHOOSE_AGENCY: 'Vælg agentur',
                DRAG_DROP_MESSAGE: 'Træk filer hertil eller klik for at vælge en fil',
                DRAG_DROP_FILE_TYPES: 'Tilladte filtyper: CSV',
                HELP_TEXT_COLUMNS: 'Kolonner: ConsumerEmail,ReferenceNumber,ConsumerName,Locale,SenderEmail,RedirectUri,Tag1,Tag2,Tag3,Tag4,TemplateId,SenderName',
                HELP_TEXT_GENERAL: 'Initialer må ikke indeholde /',
                UPLOAD: 'Send',
                SEND_SUCCEEDED_MESSAGE: 'Invitationerne er afsendt',
            },
            AUTH: {
                LOGIN: {
                    TITLE: 'Login',
                    BUTTON: 'Log ind',
                    FORGOT_BUTTON: 'Glemt adgangskode',
                    PASSWORD: 'Adgangskode',
                    USERNAME: 'Brugernavn',
                    INVALID_LOGIN: 'Ugyldige loginoplysninger. Forsøg venligst igen.',
                    PASSWORD_EXPIRED: 'Din adgangskode er udløbet',
                },
                FORGOT_PASSWORD: {
                    TITLE: 'Glemt adgangskode?',
                    TEXT: 'Har du glemt din adgangskode, kan du få hjælp ved at kontakte Nem Forsikring på 70 77 77 77.',
                    SUBMIT_BUTTON: 'Send',
                    BACK_BUTTON: 'Tilbage',
                    FORGOT_BUTTON: 'Glemt adgangskode',
                    SUCCESS: 'Din adgangskode er nu nulstillet.',
                    EMAIL: 'Email',
                },
                AUTH: {
                    WELCOME: 'Velkommen til SalesPortal!',
                },
            },
            DASHBOARDS: {
                WIDGETS: {
                    DUMMY: 'Test widget',
                    TOP_SALESMEN: 'Top sælgere',
                },
                EDIT_DASHBOARDS: {
                    CHOOSE_DASHBOARD: 'Vælg dashboard',
                    CHOOSE_WIDGET: 'Vælg widget',
                    CHOOSE_ALLOWED_USERS: 'Vælg tilladte brugere',
                    CHOOSE_ALLOWED_AGENCIES: 'Vælg tilladte agenturer',
                    SETTINGS: 'Opsætning',
                    CHANGE_NAME: 'Ret navn',
                    ROWS: 'Rækker',
                    ALLOWED_USERS: 'Tilladte brugere',
                    ALLOWED_AGENCIES: 'Tilladte agenturer',
                    NEW_COMPONENT: 'Ny komponent',
                    SAVE_CHANGES: 'Gem ændringer',
                    DELETE_DASHBOARD: 'Slet dashboard',
                    CREATE_NEW_DASHBOARD: 'Opret nyt dashboard',
                    NEW_DASHBOARD_NAME: 'Nyt dashboard navn',
                },
                TOP_SALESMEN_WIDGET: {
                    NAME: 'Navn',
                    POLICIES: 'Policer',
                    PREMIUMS: 'Præmier',
                    CURRENCY: 'kr.',

                    TOP_SALESMEN_SETUP_MODAL: {
                        TITLE: 'Widget opsætning',
                        CHOOSE_SALESMEN: 'Tilvælg sælgere',
                        SORTING: 'Sortering',
                        PREMIUMS: 'Præmier',
                        POLICIES: 'Policer',
                        WIDGET_TITLE: 'Titel',
                        CHOOSE_TITLE: 'Vælg titel',
                        ALL_CHOSEN: 'Alle er valgt',
                        FONT_SIZE: 'Skriftstørrelse',
                        CHOOSE_FONT_SIZE: 'Vælg skriftstørrelse',
                    },
                },
            },
            CREATE_CUSTOMER: {
                TITLE: 'Opret kunde',
                GET_NEXT_LEAD: 'Hent næste henvisning',
                NO_NEW_LEADS: 'Ingen nye henvisninger fundet',
                LOADING_LEAD_ERROR: 'Der opstod en fejl under indlæsning af henvisning',
                LEAD_TITLE: 'Henvisning hentet:',
                LEAD_NAME: 'Navn',
                LEAD_PHONE_NUMBER: 'Telefonnummer',
                LEAD_REFERENCE: 'Henvisnings-id',
                CHOOSE_WRAPUP_REASON: 'Vælg årsag',
                BTN_WRAPUP: 'OK',
                WRAPUP_ERROR: 'Der opstod en fejl under angivelse af årsag',
                CHOOSE_AGENCY: 'Vælg agentur',
                CHOOSE_CASEWORKER: 'Vælg sagsbehandler',
                INPUT_ADDRESS: 'Indtast adresse',
                INPUT_SSN: 'Indtast CPR-nummer',
                INPUT_REFERENCEID: 'Indtast henvisnings-id',
                NO_REFERENCE: 'Kunden er ikke henvist',
                ADDRESS_PROTECTED_TITLE: 'Det indtastede CPR-nummer er adressebeskyttet. Indtast adresse herunder:',
                INPUT_ADDRESS_MANUALLY_TITLE: 'Jeg kan ikke finde adressen',
                INPUT_ADDRESS_SEARCH_TITLE: 'Søg adresse',
                INPUT_STREET_NAME: 'Vejnavn',
                INPUT_STREET_NO: 'Nummer',
                INPUT_FLOOR: 'Etage',
                INPUT_DOOR: 'Dør',
                INPUT_HOUSE_NO: 'Bogstav',
                INPUT_ZIPCODE: 'Postnummer',
                CREATE: 'Opret',
                INPUT_FIRST_AND_MIDDLENAMES: 'Indtast for- og mellemnavne',
                INPUT_SURNAME: 'Indtast efternavn',
            },
            FRONTPAGE: {
                TECHNICALASSISTANCE: {
                    TITLE: 'Teknisk assistance',
                    BODY: 'Har du nogle spørgsmål til SalesPortal, eller støder du ind i problemer, er du velkommen til at kontakte os på:',
                    PHONE: 'Telefon:',
                    EMAIL: 'Email:',
                },
                INSURANCE_CONTACT: {
                    TITLE: 'Forsikringsmæssige spørgsmål',
                    BODY: 'Hvis du har generelle spørgsmål vedr. forsikringsprodukterne, indtegningsregler, forretningsgange eller lignende, er du velkommen til at kontakte %CONTACTNAME%',
                    PHONE: 'Telefon:',
                    EMAIL: 'Email:',
                },
                LAST_VISITED_CUSTOMERS: {
                    TITLE: 'Seneste besøgte kunder',
                    CUSTOMERNO: 'Kundenummer',
                    NAME: 'Navn',
                    ADDRESS: 'Adresse',
                    VISITED_TIME: 'Tidspunkt',
                    AGENCY_NAME: 'Agentur',
                },
            },
            DFIM: {
                TITLE: 'DFIM opslag',
                TYPE_CPR_CVR: 'Indtast CPR eller CVR nummer',
                SEARCH_CPR: 'Søg på CPR',
                SEARCH_ADDRESS: 'Søg på adresse',
                CPR_CVR_NO: 'CPR/CVR-nummer',
                FIRST_NAME: 'Fornavn',
                SURNAME: 'Efternavn',
                STREET_NAME: 'Vej',
                HOUSE_NO: 'Vej nummer',
                ZIP_CODE: 'Postnummer',
                CITY: 'By',
                LEVEL: 'Etage',
                DOOR_SIDE: 'Side/dør',
                INVALID_INPUTS_MESSAGE: 'Venligst udfyld de markerede felter.',
                INVALID_CPR_INPUT_MESSAGE: 'Venligst udfyld CPR/CVR feltet.',
                FEE_REGISTER_TITLE: 'Gebyrregistreret',
                PREMIUMS_REGISTER_TITLE: 'Fællesregistreret',
                NAME: 'Navn',
                CPR: 'CPR',
                CVR: 'CVR',
                STATE: 'Status',
                COMMENT: 'Kommentar',
                ID: 'ID',
                ADDRESS: 'Adresse',
                POLICY_NO: 'Police',
                REGISTRATION_NO: 'Reg.nr.',
                COMPANY_NO: 'Firma',
                COMPANY_CODE: 'Firma kode',
                REASON: 'Begrundelse',
                CREATION_DATE: 'Oprettet',
                DELETION_DATE: 'Slettet',
                PAYMENT_DATE: 'Betalt',
                NO_REGISTRATIONS_MESSAGE: 'Ingen registeringer fundet',
                SEARCH: 'Søg',
            },
            SMS_TEMPLATES: {
                TITLE: 'SMS tekster',
                NAME: 'Navn',
                MESSAGE: 'Besked',
                FUNCTIONS: 'Funktioner',
                DELETE: 'Slet',
                EDIT: 'Redigér',
                CREATE_TEMPLATE: 'Ny SMS tekst',
                INPUT_NAME_PLACEHOLDER: 'Indtast navn',
                INPUT_MESSAGE_PLACEHOLDER: 'Indtast besked',
                NAME_EXISTS_ERROR_MESSAGE: 'En skabelon eksisterer allerede med det valgte navn',
                DELETE_TEMPLATE_MODAL_TITLE: 'Slet %TEMPLATENAME%',
                DELETE_TEMPLATE_MODAL_CONFIRM_QUESTION: 'Er du sikker på du vil slette %TEMPLATENAME%?',
                AGENCY: 'Agentur',
                AGENCIES: 'Agenturer',
                CHOOSE_AGENCIES: 'Vælg agentur',
            },
            PARTIALS: {
                LAYOUT: {
                    TOPBAR: {
                        USER_PROFILE: {
                            TITLE: 'Brugerprofil',
                            DESCRIPTION: 'Skift adgangskode mm.',
                            SIGNOUT: 'Log af',
                        },
                        SEARCH: {
                            SEARCH_PLACEHOLDER: 'Søg...',
                        },
                    },
                },
            },
            PROFILE: {
                TITLE: 'Brugerprofil',
                CHANGEPASSWORD: 'Skift adgangskode',
                NAME: 'Navn',
                EMAIL: 'Email',
                PHONE: 'Telefonnummer',
            },
            NEWPASSWORD: {
                HEADER: 'Skift adgangskode',
                DESCRIPTION: 'Angiv dine nuværende loginoplysninger og din ønskede nye adgangskode:',
                NOT_MATCHING: 'Adgangskoder er ikke ens.',
                RENEW_ERROR:
                    'Der opstod en fejl - din adgangskode er ikke blevet skiftet. Sørg for at adgangskoden er minimum 7 karakterer lang, ikke er benyttet tidligere og indeholder små og store bogstaver samt min. et tal eller specialtegn.',
                PASSWORD_CHANGED: 'Din adgangskode er nu ændret og du kan logge ind med din nye kode.',
                PASSWORD: 'Adgangskode',
                NEWPASSWORD: 'Ny adgangskode',
                CONFIRM_PASSWORD: 'Bekræft adgangskode',
                CONFIRM_PASSWORD_BUTTON: 'Skift adgangskode',
                CONFIRM_NEWPASSWORD: 'Bekræft ny adgangskode',
                USERNAME: 'Brugernavn',
                GO_TO_LOGIN: 'Gå til login',
            },
            CUSTOMER: {
                CUSTOMER_SEARCH: {
                    TITLE: 'Søg',
                    SEARCH_PLACEHOLDER: 'Indtast søgning...',
                    SEARCH: 'Søg',
                    CUSTOMER_NO: 'Kundenummer',
                    NAME: 'Navn',
                    ADDRESS: 'Adresse',
                    AGENCY: 'Agentur',
                    POLICY_COUNT: 'Policer',
                    NO_CUSTOMERS_FOUND: 'Ingen kunder fundet for denne søgning.',
                },
                CUSTOMER_SEARCH_DETAILS: {
                    DFIM_LOOKUP: 'DFIM opslag',
                    CUSTOMER_WARNING: 'Advarselsmarkering',
                    ADDRESS_PROTECTED: 'Adressebeskyttet',
                    ADVERTISING_PROTECTED: 'Reklamebeskyttet',
                    REFERRAL: 'Henvisning',
                    CREATE_OFFER: 'Opret tilbud',
                    AGENCY: 'Agentur',
                    CASEWORKER: 'Sagsbehandler',
                    TITLE: 'Stamdata',
                    CUSTOMER_NO: 'Kundenr.',
                    CPR: 'CPR',
                    CVR: 'CVR',
                    NAME: 'Navn',
                    YEARS: 'år',
                    ADDRESS: 'Adresse',
                    ZIP: 'Postnummer',
                    CITY: 'By',
                    PHONE_REMINDER: 'Husk at bede kunden om telefonnummer',
                    PHONE_MOBILE: 'Mobil',
                    PHONE_LANDLINE: 'Fastnet',
                    PHONE_WORK: 'Arbejde',
                    PHONE_FAX: 'Fax',
                    PHONE_OTHER: 'Andet',
                    PHONE: 'Telefon',
                    ADD: 'tilføj',
                    EMAIL_REMINDER: 'Husk at bede kunden om e-mail',
                    EMAIL_PRIVATE: 'Email (privat)',
                    EMAIL_WORK: 'Email (arbejde)',
                    EMAIL: 'Email',
                    TABS_TITLE: 'Noter og besøgslog',
                    TAB_NOTES: 'Noter',
                    TAB_VISISTED_USERS: 'Besøgslog',
                    SHOW_DETAILS: 'Vis detaljer',
                    STATUS: 'Status',
                    INSURED_ITEM: 'Forsikret objekt',
                    POLICYNO: 'Policenummer',
                    START_DATE: 'Startdato',
                    END_DATE: 'Slutdato',
                    PAYMENT_FREQUENCE: 'Betalingsfrekvens',
                    PAYMENT_METHOD: 'Betalingsmetode',
                    MAINMATURITY_DATE: 'Hovedforfald',
                    CUSTOMER_RATING_GOOD: 'Indtegning tilladt',
                    CUSTOMER_RATING_MEDIUM: 'Indtegning kræver individuel opslag samt dokumentation i noter',
                    CUSTOMER_RATING_BAD: 'Indtegning ej tilladt',
                    INVOICES_TITLE: 'Fakturaarkiv',
                    AGENCYINFORMATION: 'Selskabsoplysninger',
                    CUSTOMERINFORMATION: 'Kundeoplysninger',
                    TELEPHONEINFORMATION: 'Telefonoplysninger',
                    MAILINFORMATION: 'Mailoplysninger',
                    RELATIONS: 'Relationer',
                    DOCUMENTS_TITLE: 'Dokumenter',
                    FUNCTIONS: 'Funktioner',
                    CREDIT_INFORMATION_LOOKUP: 'RKI opslag',
                    CUSTOMER_SELFCARE_LOGIN: 'Kundens selvbetjening',
                    SEND_TEXT_MESSAGE: 'Send SMS',
                    INVOICE_AMOUNTS: 'Fakturering',
                    AWAITING_PAYMENT: 'Afventer fakturering',
                    OUTSTANDING_AMOUNT: 'Udestående',
                    TOTAL: 'Totalt',
                    SEND_QUESTIONNAIRE: 'Send spørgeskema',
                    OTHER_INFO: 'Andre oplysninger',
                    POLICY_COUNT: 'Antal policer',
                    OFFER_COUNT: 'Antal tilbud',
                    LOADING_POLICIES: 'Indlæser policer...',
                    NO_POLICIES: 'Ingen policer',
                    CLAIMS_TITLE: 'Skader',
                    OFFERS_TITLE: 'Tilbud',
                    INSURANCE_CERTIFICATE: 'Forsikringsbevis',
                    ADVERTISING_PROTECTION: 'Reklamebeskyttelse',
                    ADDRESS_PROTECTION: 'Adressebeskyttelse',
                    CONSENT: 'Samtykkeerklæring',
                    PAYMENT_SERVICE: 'Betalingsservice',
                    COUNTRY_CODE: 'Landekode',
                    COUNTRY: 'Land',
                    CONAME: 'C/o',
                    NO_REFERED_ID_ERROR: "OBS! Hvis kunden er henvist, skal du indtaste id'et under selskabsoplysninger.",
                    NO_REFERED_ID_ERROR_TITLE: 'Henvisning-ID er ikke udfyldt',
                    REFERRAL_REMINDER: 'Husk at indtaste henvisning',
                    REMINDER_POPOVER: 'Der er sendt flere rykkere på policen, som endnu ikke er betalt - det er derfor ikke muligt at foretage ændringer.',
                    CANCELLED_POPOVER: 'Policen er afgangsført - der kan derfor ikke foretages ændringer.',
                    SEND_CUSTOMER_REVIEW_INVITE_TITLE: 'Send TP invitation',
                    SEND_CUSTOMER_REVIEW_INVITE_QUESTION: 'Er du sikker på du vil sende en TP invitation til kunden?',
                    CPR_SEARCH: 'CPR opslag',
                    NO_EMAIL_MODAL_TITLE: 'Ingen email',
                    NO_EMAIL_MODAL_TEXT: 'Kunden har ingen email tilknyttet og der kan derfor ikke sendes en Trustpilot invitation.',
                    CUSTOMER_RISK_YES: 'Kunden bor på en risikoadresse - præmietillæg 100%',
                },
                CUSTOMER_TABS: {
                    GENERAL: {
                        TITLE: 'Generelt',
                    },
                    PREMIUMS: {
                        TITLE: 'Pris',
                        DESCRIPTION: 'Beskrivelse',
                        AMOUNT_ANNUAL: 'Pris pr. år',
                        TOTAL: 'I alt',
                    },
                    COVERS: {
                        TITLE: 'Dækninger',
                        NAME: 'Navn',
                        COVERTYPE: 'Dækningstype',
                        DESCRIPTION: 'Beskrivelse',
                        MINDEDUCTIBLE: 'Minimum selvrisiko',
                        MAXDEDUCTIBLE: 'Maximum selvrisiko',
                        DEDUCTIBLE: 'Selvrisiko',
                        DEDUCTIBLE_PR_CLAIM: 'Selvrisiko per skade',
                        SUMTYPE: 'Sumtype',
                        MAXSUMPRCLAIM: 'Maks sum per skade',
                        MAXSUMPERYEAR: 'Maks sum per år',
                    },
                    COLLECTIONS: {
                        TITLE: 'Fakturaarkiv',
                        INVOICEDATE: 'Fakturadato',
                        DUEDATE: 'Forfaldsdato',
                        AMOUNT: 'Beløb',
                        STATE: 'Status',
                    },
                    DOCUMENTS: {
                        TITLE: 'Dokumenter',
                        DESCRIPTION: 'Beskrivelse',
                        DATE: 'Dato',
                        DOWNLOAD: 'Hent',
                        DOWNLOAD_TERMS: 'Hent forsikringsbetingelser',
                        DOWNLOAD_MOTORCARD: 'Generér dokument',
                    },
                    NOTES: {
                        TITLE: 'Noter',
                        CREATE_NOTE: 'Opret note',
                        DATE: 'Dato',
                        NOTE: 'Note',
                    },
                    TASKS: {
                        TITLE: 'Opgaver',
                        CREATED: 'Oprettet',
                        TYPE: 'Type',
                        DESCRIPTION: 'Beskrivelse',
                        STATUS: 'Status',
                    },
                    CLAIMS: {
                        TITLE: 'Skader',
                        DATE: 'Dato',
                        CLAIM_NO: 'Skadenr.',
                        DESCRIPTION: 'Beskrivelse',
                        STATE: 'Status',
                    },
                    POLICY_VERSIONS: {
                        TITLE: 'Versionsændringer',
                        EVENTDATE: 'Versionsdato',
                        MAJORCHANGE: 'Større ændring',
                        REASON: 'Begrundelse',
                        NOTE: 'Bemærkning',
                        STATE: 'Status',
                    },
                    CLAUSES: {
                        TITLE: 'Klausuler',
                        CLAUSENO: 'Klausul',
                        DESCRIPTION: 'Beskrivelse',
                        TEXT: 'Tekst',
                    },
                    EDI: {
                        TITLE: 'Opsigelser',
                        RECIEVED_SENT: 'Modtaget/afsendt',
                        DIRECTION: 'Retning',
                        SENDER: 'Afsender',
                        RECIEVER: 'Modtager',
                        TYPE: 'Type',
                        CANCELLED: 'Opsigelsesdato',
                    },
                    PREVIOUSCOMPANY: {
                        INSURANCE: 'Forsikring',
                        COMPANY: 'Selskab',
                        POLICYNO: 'Policenummer',
                        REASON: 'Årsag',
                        FREETEXT: 'Fri tekst',
                        SENT: 'Sendt',
                        APPROVED: 'Godkendt',
                    },
                },
            },
            FOLDERS: {
                ADMIN: {
                    FOLDERSETUP: 'Mappeopsætning',
                    NAME: 'Navn',
                    DOCUMENTS: 'Documents',
                    ACCESS: 'Adgang',
                    FUNCTIONS: 'Funktioner',
                    LIMITED: 'Begrænset',
                    OPEN: 'Åben',
                    CREATED_BY: 'Oprettet af',
                    CREATED: 'Oprettet',
                    ADD_FOLDER: 'Tilføj mappe',
                    CONFIRM_DELETE_TITLE: 'Slet mappe',
                    CONFIRM_DELETE_QUESTION: 'Er du sikker på du vil slette mappen: %FOLDERNAME%?',
                },
                VIEW: {
                    ADD_DOCUMENT: 'Tilføj dokument',
                    TITLE: 'Dokumentmapper',
                    FILE_TYPE: 'Filtype',
                    DOCUMENT_NAME: 'Dokumentnavn',
                    ADDED: 'Tilføjet',
                    ADDED_BY: 'Tilføjet af',
                    CONFIRM_DELETE_TITLE: 'Slet dokument',
                    CONFIRM_DELETE_QUESTION: 'Er du sikker på du vil slette dokumentet: %DOCUMENTNAME%?',
                },
            },
            CREATEOFFER: {
                STARTOFFER: {
                    TITLE: 'Opret tilbud',
                    CHOOSE_AGENCY: 'Vælg agentur',
                    CHOOSE_CASEWORKER: 'Vælg sagsbehandler',
                    INPUT_ADDRESS: 'Indtast adresse',
                    INPUT_SSN: 'Indtast CPR-nummer',
                    INPUT_REFERENCEID: 'Indtast henvisnings-id',
                    NO_REFERENCE: 'Kunden er ikke henvist',
                    ADDRESS_PROTECTED_TITLE: 'Det indtastede CPR-nummer er adressebeskyttet. Indtast adresse herunder:',
                    INPUT_ADDRESS_MANUALLY_TITLE: 'Jeg kan ikke finde adressen',
                    INPUT_ADDRESS_SEARCH_TITLE: 'Søg adresse',
                    INPUT_STREET_NAME: 'Vejnavn',
                    INPUT_STREET_NO: 'Nummer',
                    INPUT_FLOOR: 'Etage',
                    INPUT_DOOR: 'Dør',
                    INPUT_HOUSE_NO: 'Bogstav',
                    INPUT_ZIPCODE: 'Postnummer',
                    INPUT_FIRST_AND_MIDDLENAMES: 'Indtast for- og mellemnavne',
                    INPUT_SURNAME: 'Indtast efternavn',
                },
                PRODUCTS: {
                    TITLE: 'Vælg produkter',
                    NO_AGENCY_ID: 'Intet agentur valgt',
                    NO_ADDRESS: {
                        TITLE: 'Ugyldig adresse',
                        TEXT: 'Det er ikke muligt at oprette tilbud på kunden på grund af kundens adresse. Kontakt kundeservice for tilbud.',
                    },
                },
                CUSTOMERINFO: {
                    TITLE: 'Kundeoplysninger',
                    SHOW_GOOGLE_MAP: 'Vis oversigtskort',
                    SHOW_STREETVIEW_MAP: 'Vis streetview',
                    MAP_NOT_AVAILABLE: 'Kort ikke tilgængeligt',
                    SEARCH_RKI: 'Søg RKI',
                },
                OFFEROVERVIEW: {
                    TITLE: 'Tilbud',
                    DISCOUNT: '% rabat',
                    TOTAL_PRICE: 'Samlet pris',
                    CURRENCY: 'kr.',
                    ELIGIBLE_FOR: 'Berettiget til',
                    BEGIN_CALCULATION: 'Start beregning',
                    REMOVE_PRODUCT_CONFIRM_TITLE: 'Bekræft sletning',
                    REMOVE_PRODUCT_CONFIRM_QUESTION: 'Er du sikker på du vil fjerne %OFFERLINE% fra tilbuddet?',
                    CREATING_OFFER: 'Opretter tilbud',
                },
                PRODUCTBOX: {
                    ADD_PRODUCT: 'Tilføj',
                    DISCOUNT_ENTRY: 'Rabatudløsende',
                    DISCOUNT_GIVING: 'Rabatgivende',
                },
            },
            STATISTICS: {
                QUESTIONNAIRES: {
                    TITLE: 'Spørgeskemarapport',
                    DATE_FROM: 'Dato fra',
                    DATE_TO: 'Dato til',
                    CHOOSE_DATE: 'Vælg dato',
                    AGENCY: 'Agentur',
                    CHOOSE_AGENCY: 'Vælg agentur',
                    QUESTIONNAIRE: 'Spørgeskema',
                    CHOOSE_QUESTIONNAIRE: 'Vælg spørgeskema',
                    DOWNLOAD_REPORT: 'Hent rapport',
                },
            },
            POLICY_MTA: {
                MAJOR_CHANGE: 'Stor ændring',
                DATE: 'Dato',
                POLICY_START_DATE: 'Policens startdato',
                COMMENT: 'Kommentar',
                MTA: 'Policeændring',
                PRODUCT_ACTIONS: {
                    CALCULATE: 'Beregn',
                    UPDATE_POLICY: 'Opdater police',
                    CANCEL: 'Fortryd',
                },
                PRE_UPDATE_MODAL: {
                    TITLE: 'Begrundelse for ændring',
                    COMMENT: 'Kommentar',
                    TYPE_COMMENT: 'Skriv kommentar',
                    MISSING_UPDATE_REASON: 'Venligst vælg en begrundelse',
                    MISSING_COMMENT_MESSAGE: 'Venligst skriv en kommentar',
                    UPDATE_POLICY: 'Opdatér police',
                    CANCEL: 'Fortryd',
                    UPDATE_REASON: 'Begrundelse',
                    SELECT_UPDATE_REASON: 'Vælg begrundelse',
                    COMMENT_MESSAGE_TOO_LONG: 'Kommentaren er for lang. Brug max. 100 tegn.',
                },
            },
            ACTIVATE: {
                SHARED: {
                    START_DATE: 'Ikrafttrædelse',
                    MAIN_MATURITY_DATE: 'Næste hovedforfald',
                    SAVE_NEXT: 'Gem & næste',
                    EDI: {
                        REMOVE_EDI: 'Fjern EDI',
                        ADD_EDI: 'Tilføj EDI',
                        CONSENT_RECEIVED: 'Samtykke modtaget',
                        TYPE: 'Type',
                        INSURANCE_COMPANY: 'Selskab',
                        REASON: 'Årsag',
                        POLICY_NO: 'Police nr.',
                        ENABLE_EDI: 'Opsig forsikring',
                        FREE_TEXT: 'Fri tekst',
                    },
                    STREET: 'Vej',
                    HOUSE_NO: 'Husnummer',
                    FLOOR: 'Etage',
                    DOOR: 'Dør',
                    ZIP_CODE: 'Postnummer',
                    CITY: 'By',
                    REGISTRATION_NO: 'Registreringsnummer',
                    CHASSIS_NO: 'Stelnummer',
                    NAME: 'Navn',
                    DATE_FOR_START: 'Dato for ikrafttrædelse',
                },
                DOG: {
                    CALL_NAME: 'Navn',
                },
                SECONDHOUSE: {
                    REMOVE_HOUSE_EDI: 'Fjern hus EDI',
                    ADD_HOUSE_EDI: 'Tilføj hus EDI',
                    REMOVE_HOME_EDI: 'Fjern indbo EDI',
                    ADD_HOME_EDI: 'Tilføj indbo EDI',
                    HOUSE_EDI: 'Hus EDI',
                    HOME_EDI: 'Indbo EDI',
                },
                ACCIDENT: {
                    EDI_HELP_TEXT_LINE_1: 'Det er ikke muligt at EDI-opsige ulykkesforsikringen via SalesPortal.',
                    EDI_HELP_TEXT_LINE_2_PRE: 'Du skal i stedet skrive en mail til',
                    EDI_HELP_TEXT_LINE_2_POST: ', med overskriften "Opsigelse ulykke – Kundenummer ##########"',
                    EDI_HELP_TEXT_LINE_3:
                        'OBS! Sikr dig inden du sender mailen, at alle felter i acceptbilagene er udfyldt på ulykkeforsikringen. Dvs. vi har følgende oplysninger tilgængeligt:',
                    EDI_HELP_TEXT_LINE_4: 'Navn og cpr.nr. på forsikringstager.',
                    EDI_HELP_TEXT_LINE_LIST_1: 'Navn og cpr.nr. på den/de sikrede',
                    EDI_HELP_TEXT_LINE_LIST_2: 'Policenummer og selskab på tidligere police',
                    EDI_HELP_TEXT_LINE_5: 'Husk at sætte forsikringen i kraft pr. den dato, den kan overtages fra tidligere selskab. (Vær opmærksom på 5 dages behandlingstid.)',
                },
                FARMHOUSE: {
                    HOUSE: 'Hus',
                    HOME: 'Indbo',
                    DOG: 'Hund',
                    OUTHOUSE: 'Udhus',
                    ADD_HOME_EDI: 'Tilføj indbo EDI',
                    REMOVE_HOME_EDI: 'Fjern indbo EDI',
                    EDI_HELP_TEXT_LINE_1: 'Det er ikke muligt at EDI-opsige landbo via SalesPortal.',
                    EDI_HELP_TEXT_LINE_2_PRE: 'Du skal i stedet skrive en mail til',
                    EDI_HELP_TEXT_LINE_2_POST: ', med overskriften "Opsigelse landbo – Kundenummer ##########"',
                    EDI_HELP_TEXT_LINE_3: 'OBS! Sikr dig inden du sender mailen, at alle felter i acceptbilagene er udfyldt på landbo. Dvs. vi har følgende oplysninger tilgængeligt:',
                    EDI_HELP_TEXT_LINE_4: 'Navn og cpr.nr. på forsikringstager.',
                    EDI_HELP_TEXT_LINE_LIST_1: 'Navn og cpr.nr. på den/de sikrede',
                    EDI_HELP_TEXT_LINE_LIST_2: 'Policenummer og selskab på tidligere police',
                    EDI_HELP_TEXT_LINE_5: 'Husk at sætte forsikringen i kraft pr. den dato, den kan overtages fra tidligere selskab. (Vær opmærksom på 5 dages behandlingstid.)',
                },
            },
            SUMMARY: {
                CUSTOMER_DETAILS_TITLE: 'Kundeoplysninger',
                PRODUCT_ACTIONS: {
                    CALCULATE: 'Beregn',
                    ADD_TO_OFFER: 'Tilføj til tilbud',
                    UPDATE_OFFER: 'Opdater tilbud',
                    CANCEL: 'Fortryd',
                },
                TASKS: {
                    TITLE: 'Opgaver',
                    PREVENTS_APPROVAL: 'Blokerer for godkendelse',
                    DOES_NOT_PREVENT_APPROVAL: 'Blokerer ikke for godkendelse',
                },
                DFIM: {
                    TITLE: 'DFIM opslag',
                    INSURANCE_HOLDER: 'Forsikringstager',
                    INSURANCE_HOLDER_NOT_REGISTERED_FEE: 'Forsikringstager er ikke registreret i gebyrregistret',
                    INSURANCE_HOLDER_REGISTERED_FEE: 'Forsikringstager er registreret i gebyrregistret',
                    INSURANCE_HOLDER_NOT_REGISTERED_PREMIUM: 'Forsikringstager er ikke registreret i fællesregistret',
                    INSURANCE_HOLDER_REGISTERED_PREMIUM: 'Forsikringstager er registreret i fællesregistret',
                    PRIMARY_USER: 'Primær bruger',
                    USER_NOT_REGISTERED_FEE: 'Primær bruger er ikke registreret i gebyrregistret',
                    USER_REGISTERED_FEE: 'Primær bruger er registreret i gebyrregistret',
                    USER_NOT_REGISTERED_PREMIUM: 'Primær bruger er ikke registreret i fællesregistret',
                    USER_REGISTERED_PREMIUM: 'Primær bruger er registreret i fællesregistret',
                    COULD_NOT_DETERMINE_INSURANCE_HOLDER_REGISTERED_FEE: 'Tjek af forsikringstager kunne ikke udføres i gebyrregistret',
                    COULD_NOT_DETERMINE_INSURANCE_HOLDER_REGISTERED_PREMIUM: 'Tjek af forsikringstager kunne ikke udføres i fællesregistret',
                    COULD_NOT_DETERMINE_USER_REGISTERED_FEE: 'Tjek af primær bruger kunne ikke udføres i gebyrregistret',
                    COULD_NOT_DETERMINE_USER_REGISTERED_PREMIUM: 'Tjek af primær bruger kunne ikke udføres i fællesregistret',
                },
                FLOW: {
                    TITLE: 'Afslut tilbud',
                    APPROVE_OFFER: 'Godkend tilbud',
                    UNAPPROVE_OFFER: 'Fortryd godkend',
                    SEND_OFFER: 'Send tilbud',
                    ACCEPT_OFFER: 'Tilbudsaccept',
                    START_OFFER: 'Ikrafttrædelse',
                    RKI_LOOKUP: 'RKI opslag',
                    CLOSE_OFFER: 'Luk tilbud',
                    SEND_OFFER_ACCEPT_TASK: 'Send acceptopgave',
                    DOWNLOAD_OFFER_DOCUMENT: 'Hent tilbudsdokument',
                    CUSTOMER_SELFCARE: 'Kundens selvbetjening',
                    DOWNLOAD_ACCEPT: 'Hent acceptbilag',
                    GO_TO_START_OFFER: 'Gå til ikrafttrædelse',
                    OFFER_NOT_APPROVED: 'Tilbuddet er ikke godkendt',
                    CREATED_BY: 'Oprettet af',
                    CREATED_DATE: 'Oprettet d.',
                    VALID_UNTIL: 'Gældende til',
                    OFFER_NOT_SENT: 'Tilbuddet er ikke sendt',
                    SENT_DATE: 'Sendt d.',
                    OFFER_NOT_ACCEPTED: 'Tilbuddet er ikke accepteret',
                    ACCEPTED: 'Accepteret',
                    OFFER_NOT_ACTIVE: 'Tilbuddet er ikke ikraftsat',
                    NEW_PRODUCT: 'Tilføj produkt',
                },
            },
            CALCULATE: {
                ACCEPTANCE_MODAL: {
                    TITLE: 'Send acceptopgave',
                    SEND_TO: 'Sendes til',
                    MAIL_TEMPLATE: 'Skabelon',
                    SELECT_MAIL_TEMPLATE: 'Vælg skabelon',
                    SUBJECT: 'Emne',
                    MAIL_TEXT: 'Mail tekst',
                    SEND: 'Send',
                    CLOSE: 'Luk',
                },
                ERROR: {
                    TITLE: 'Fejl',
                },
                LEGAL_IDENTITY_INPUT: {
                    STREET: 'Vej',
                    NAME: 'Navn',
                    AGE: 'Alder',
                    HOUSE_NO: 'Husnummer',
                    DOOR: 'Dør',
                    FLOOR: 'Etage',
                    ZIP_CODE: 'Postnummer',
                    CITY: 'By',
                    ADDRESS_NO: 'Adressenummer',
                    DIRECTLY_TYPED: 'Direkte indtastet',
                    INPUT_TYPE: 'Indtastningstype',
                    SEARCH: 'Søg',
                    USE_CPR_NO_MESSAGE: 'Prøv igen med CPR nummer',
                    TYPE_VALID_ADDRESS_NO_MESSAGE: 'Venligst indtast et gyldigt kundenummer eller CPR nummer',
                    COULD_NOT_CREATE_CUSTOMER: 'Kunden kunne desværre ikke oprettes. Er du sikker på oplysningerne er korrekte?',
                    CPR_IS_ADDRESS_PROTECTED: 'Det indtastede CPR-nummer er adressebeskyttet',
                    NONE: 'Ikke valgt',
                    RELATIONSHIP: 'Relation',
                    SECONDARY_INSURANCE_HOLDER: 'Sekundær forsikringstager',
                },
                SUPPLEMENTARY_INFOS: {
                    TITLE: 'Generelle oplysninger',
                },
                CALCULATE_PROGRESS: {
                    CREATE_OFFER: 'Opret tilbud',
                    CALCULATE_OFFER: 'Beregn tilbud',
                    SUMMARY: 'Opsummering',
                    COMPLETE: 'Ikræfttrædelse',
                },
                PRODUCT_ACTIONS: {
                    CALCULATE: 'Beregn',
                    SAVE_NEXT: 'Gem & næste',
                },
                PRODUCT_CALCULATION: {
                    DESCRIPTION: 'Beskrivelse',
                    PRICE: 'Pris',
                    INSURANCE_EXCL_TAX: 'Forsikring ex. afgifter',
                    CURRENCY: 'kr.',
                    TOTAL_AMOUNT_PRE_CHANGE: 'Pris før ændring',
                    TOTAL_AMOUNT_DIF: 'Ændring i pris',
                    TOTAL_AMOUNT: 'Samlet pris',
                    AVERAGE: 'Gennemsnitlig',
                    CURRENCY_PR_MONTH: 'kr. pr. måned',
                },
                WRAPPER: {
                    ACTIONS: 'Handlinger',
                    CANCEL: 'Fortryd',
                    SAVE: 'Gem',
                },
                ADMINISTRATION_INPUT: {
                    TITLE: 'Administrative oplysninger',
                    PROVISION: 'Provision',
                    SECONDARY_PROVISION: 'Sekundær provision',
                    INSTALMENT_PERIOD: 'Betaling',
                    AGREEMENT: 'Pakke-aftale',
                    MANUAL_DISCOUNT: 'Manuel rabat',
                    OTHER_DISCOUNTS: 'Andre rabatter',
                    NO_MANUAL_DISCOUNT: 'Ingen manuel rabat',
                },
                COVER_SELECTOR: {
                    TITLE: 'Dækninger',
                    COVER_PACKAGES: 'Dækningspakker',
                    DEDUCTIBLE: 'Selvrisiko',
                    COVERAGE_SCOPE: 'Dækningsomfang',
                    COVER_PACKAGE_BUILD: 'Byg selv',
                    SUM: 'Sum',
                    FRAME_NO: 'Stelnummer',
                    ADD_BIKE: 'Tilføj cykel',
                    REMOVE_BIKE: 'Fjern cykel',
                },
                CALCULATE_ADDRESS_INPUT: {
                    TITLE: 'Adresseoplysninger',
                    ADDRESS: 'Adresse',
                    ADDRESS_CHOSEN: 'Valgt adresse',
                    ADDRESS_SEARCH: 'Adressesøger',
                    CHANGE: 'Skift',
                    USE_LOOKUP_ADDRESS: 'Benyt valgt adresse',
                    MANUAL: 'Manuel indtastning',
                    STREET_NAME: 'Vejnavn',
                    STREET_NO: 'Husnummer',
                    HOUSE_NO: 'Bogstav',
                    FLOOR: 'Etage',
                    DOOR: 'Dør',
                    ZIP_CODE: 'Postnummer',
                    CITY: 'By',
                    PLACE: 'Stednavn',
                    USE_MANUAL_ADDRESS: 'Benyt indtastede',
                    IS_OWN_HOME: 'Forsikringstagers adresse',
                },
                BOAT: {},
                CAR: {
                    CAR_DETAILS: 'Oplysninger om bil',
                    USAGE: 'Art og anvendelse',
                    BRAND: 'Mærke',
                    MODEL: 'Model',
                    YEAR: 'Årgang',
                    MOTOR_SIZE: 'Motor',
                    FUEL: 'Brændstof',
                    VARIANT: 'Variant',
                    VARIANT_NAME: 'Variantnavn',
                    NEW_VALUE: 'Bilens nyværdi',
                    WEIGHT: 'Egenvægt (Kg.)',
                    REGISTRATION_NUMBER: 'Registreringsnummer',
                    CHASSIS_NUMBER: 'Stelnummer',
                    VEHICLE_ID: 'Køretøjsid',
                    CHASSIS_TYPE: 'Karosseri',
                    HORSE_POWER: 'Hk',
                    MAX_KM_PR_YEAR: 'Maks. km. pr. år',
                    ODOMETER_VALUE: 'Km-stand',
                    ODOMETER_DATE: 'Aflæsningsdato',
                    ODOMETER_DATE_PLACEHOLDER: 'Vælg aflæsningsdato',
                    DEDUCTIBLE: 'Selvrisiko',
                    CHANGE_OWNER_USER: 'Skift ejer/bruger',
                    YEARS_WITH_OWN_CAR: 'Antal år med egen bil',
                    CLAIMS_LAST_5_YEARS: 'Antal skader sidste 5 år',
                    BONUS_CLASS: 'Bonusklasse',
                    THEFT_SECURED_REQUIRED: 'Krav om tyverisikring',
                    LEASING: 'Leasing',
                    STANDSTILL: 'Stilstand',
                    BONUS: 'Bonusklasse',
                    YEAR_OF_FIRST_REGISTRATION: '1. indregistreringsår',
                    DMR_BTN: 'Hent oplysninger fra DMR',
                    DMR_ERROR: 'Der blev ikke fundet nogen oplysninger i DMR',
                    CAR_SELECTOR_MODAL: {
                        TITLE: 'Vælg bil',
                    },
                    SAVED_VALUE: 'Gemt værdi:',
                    MANUALLY_SELECT: 'Vælg manuelt den mulighed fra listen, der matcher bedst.',
                },
                CARAVAN: {
                    CARAVAN_DETAILS: 'Oplysninger om campingvogn',
                    REGISTRATION_NUMBER: 'Registreringsnummer',
                    CHASSIS_NUMBER: 'Stelnummer',
                    USAGE: 'Art og anvendelse',
                    BRAND: 'Mærke',
                    MODEL: 'Model',
                    YEAR: 'Årgang',
                    MARKET_VALUE: 'Handelsværdi',
                    STATIONARY: 'Stationær campingvogn',
                    DMR_BTN: 'Hent oplysninger fra DMR',
                    DMR_ERROR: 'Der blev ikke fundet nogen oplysninger i DMR',
                },
                HOME: {
                    HOME_DETAILS: 'Boligoplysninger',
                    HOME_TYPE: 'Boligtype',
                    ROOF_TYPE: 'Tagtype',
                    RESIDENCE_AREA: 'Beboelsesareal',
                    BUSINESS_AREA: 'Erhvervsareal',
                    GARAGE_AREA: 'Garage- og udhusareal',
                    THEFT_SECURED: 'Tyverisikring',
                    NO_OCCUPANTS: 'Antal beboere',
                },
                TRAILER: {
                    TRAILER_DETAILS: 'Oplysninger om trailer',
                    USAGE: 'Art og anvendelse',
                    BRAND: 'Mærke',
                    MODEL: 'Model',
                    YEAR: 'Årgang',
                    MARKET_VALUE: 'Handelsværdi',
                    REGISTRATION_NUMBER: 'Registreringsnummer',
                    CHASSIS_NUMBER: 'Stelnummer',
                    DEDUCTIBLE_FIXED: 'Selvrisiko (fast)',
                    DMR_BTN: 'Hent oplysninger fra DMR',
                    DMR_ERROR: 'Der blev ikke fundet nogen oplysninger i DMR',
                },
                ACCIDENT: {
                    REMOVE: 'Fjern',
                    ADD_ACCIDENT_FULL: 'Tilføj fuldtidsulykke',
                    ADD_ACCIDENT_LEISURE: 'Tilføj fritidsulykke',
                    ADD_ACCIDENT_CHILD: 'Tilføj børneulykke',
                    ADD_ACCIDENT: 'Tilføj ulykke',
                    ACCIDENT_FULL: 'Fuldtidsulykke',
                    ACCIDENT_LEISURE: 'Fritidsulykke',
                    ACCIDENT_CHILD: 'Børneulykke',
                    TYPE: 'Type',
                    SCOPE_TYPE: 'Forsikringsomfang',
                    OCCUPATION: 'Beskæftigelse',
                    PERMANENT_INJURY_DEGREE: 'Mengrad (%)',
                    SPECIAL_RISKS: 'Særlige risici',
                    DOUBLE_COMPENSATION: 'Dobbelterstatning',
                    ADD_BENEFICIARY: 'Tilføj begunstiget',
                    REMOVE_BENEFICIARY: 'Fjern begunstiget',
                    BENEFICIARY: 'Begunstiget',
                    BENEFICIARY_TYPE: 'Begunstiget type',
                    CHILD_SLAVE_HELP_TEXT: 'Mengrad, dobbelterstatning og dækninger sættes på første børneulykke.',
                    ACCIDENT_BENEFICIEARY: {
                        INPUT_TYPE: 'Indtastningstype',
                        CUSTOMER: 'Kunde',
                        PERSON: 'Person',
                        RELATION: 'Relation',
                        ADDRESS_NO: 'Adressenummer',
                        SEARCH: 'Søg',
                        PERCENTAGE: 'Procent',
                        NAME: 'Navn',
                        AGE: 'Alder',
                        ZIP_CODE: 'Postnummer',
                        DIRECTLY_TYPED: 'Direkte angivet',
                        ADDRESS: 'Adresse',
                    },
                    OCCUPATION_CATEGORY: 'Beskæftigelseskategori',
                },
                DOG: {
                    BIRTH_DATE: 'Fødselsdato',
                    LIABILITY: 'Hundeansvar',
                    EXTENDED_LIABILITY: 'Udvidet hundeansvar',
                    SICKNESS: 'Sygeforsikring',
                    RACE: 'Race',
                    NAME: 'Kaldenavn',
                    PEDIGREE_NAME: 'Stamtavle navn',
                    GENDER: 'Køn',
                    CHIP_NO: 'Tatoveringsnr. / Microchipnr.',
                    HEALTH_JOURNAL: 'Sundhedsattest',
                    ADD_NEW_DOG: 'Tilføj hund',
                    DOG: 'Hund',
                    REMOVE_DOG: 'Fjern hund',
                    HEALTH_INSURANCE_HELP_TEXT: `Hunden skal være ældre end 2 mdr. og yngre end 5 år for at der kan tegnes en sygeforsikring.
                    OBS! Sundhedsattest skal foreligge når hunden er ældre end 3 år - Attest må max være 14 dage gammel.`,
                    LIFE_INSURANCE_HELP_TEXT: `Der må maksimalt vælges en livsforsikringssum på 5000 kr. hvis hunden er yngre end 1 eller ældre end 3 år.
                    OBS! Sundhedsattest skal foreligge når hunden er ældre end 1 år - Attest må max være 14 dage gammel.`,
                },
                MOPED: {
                    MOPED_DETAILS: 'Oplysninger om knallert',
                    TYPE: 'Art og anvendelse',
                    BRAND: 'Mærke',
                    MODEL: 'Model',
                    YEAR: 'Årgang',
                    REGISTRATION_NUMBER: 'Registreringsnummer',
                    CHASSIS_NUMBER: 'Stelnummer',
                    DMR_BTN: 'Hent oplysninger fra DMR',
                    DMR_ERROR: 'Der blev ikke fundet nogen oplysninger i DMR',
                },
                MOTORCYCLE: {
                    TYPE: 'Type',
                    DRIVING_PERIOD: 'Køreperiode',
                    DEDUCTIBLE: 'Selvrisiko',
                    BRAND: 'Mærke',
                    MODEL: 'Model',
                    YEAR: 'Årgang',
                    YEAR_OF_FIRST_REGISTRATION: '1. indregistreringsår',
                    MARKET_VALUE: 'Nyværdi',
                    REGISTRATION_NUMBER: 'Registreringsnummer',
                    CHASSIS_NO: 'Stelnummer',
                    ENGINE_SIZE: 'Motorstørrelse (CCM)',
                    LEASING: 'Leasing',
                    STANDSTILL: 'Stilstand',
                    BONUS: 'Bonusklasse',
                    DMR_BTN: 'Hent oplysninger fra DMR',
                    DMR_ERROR: 'Der blev ikke fundet nogen oplysninger i DMR',
                },
                TRACTOR: {
                    DEDUCTIBLE: 'Selvrisiko',
                    USAGE: 'Art og anvendelse',
                    BRAND: 'Mærke',
                    MODEL: 'Model',
                    YEAR: 'Årgang',
                    NEW_VALUE: 'Nyværdi',
                    REGISTRATION_NUMBER: 'Registreringsnummer',
                    CHASSIS_NUMBER: 'Stelnummer',
                    APPROVED: 'Godkendt traktor',
                    REGISTERED: 'Momsregistreret',
                    DMR_BTN: 'Hent oplysninger fra DMR',
                    DMR_ERROR: 'Der blev ikke fundet nogen oplysninger i DMR',
                },
                HOUSE: {
                    BUILDING_NO: 'Bygningsnummer',
                    REMOVE: 'Fjern',
                    USAGE_TYPE: 'Brugstype',
                    BUILDING_STATUS: 'Bygningsstatus',
                    HEATING_TYPE: 'Opvarmningstype',
                    ROOF_TYPE: 'Tagtype',
                    BASEMENT: 'Kælder',
                    RESIDENCE_AREA: 'Beboelsesareal',
                    BUSINESS_AREA: 'Erhvervsareal',
                    GARAGE_AREA: 'Garage/udhusareal',
                    CONSTRUCTION_YEAR: 'Opførelsesår',
                    ROOF_TYPE_FREE_TEXT: 'Tagtype (fritekst)',
                    RENOVATION_YEAR_PIPES: 'Renovationsår, rør',
                    RENOVATION_YEAR_ROOF: 'Renovationsår, tag',
                    RENOVATION_YEAR_WINDOWS_DOORS: 'Renovationsår, vinduer og døre',
                    RENOVATION_YEAR_WIRES: 'Renovationsår, ledninger',
                    ADD_BUILDING: 'Tilføj bygning',
                    BUILDING: 'Bygning',
                    EXPECTED_COMPLETION_DATE: 'Forventet færdiggørelse',
                },
                SECONDHOUSE: {
                    TITLE: 'Fritidshus',
                    INCLUDE_HOME: 'Inkluder indbo',
                    HOME: 'Indbo',
                    HOUSE: 'Hus',
                },
                FARMHOUSE: {
                    TITLE: 'Landbo',
                    INSURANCE_TYPE: 'Forsikringstype',
                    DOG: 'Hund',
                    HOME: 'Indbo',
                    HOUSE: 'Hus',
                    OUTHOUSE: 'Udhus',
                    ENABLE_DOG: 'Inkluder hund',
                    ENABLE_HOME: 'Inkluder indbo',
                    INSURANCE_SUM: 'Forsikringssum',
                    STAMP_DUTY_SUM: 'Stempelafgift',
                    VAT_REGISTERED: 'Momsregistreret',
                    HAY_STRAW: 'Oplag af hø/halm',

                    BUILDING_SELECTOR: {
                        TITLE: 'Vælg bygningsammensætning',
                        HOUSE: 'Hus',
                        OUTHOUSE: 'Udbygning',
                        NONE: 'Ikke valgt',
                        BUILDING: 'Bygning',
                        RESIDENCE_AREA: 'Beboelse',
                        BUSINESS_AREA: 'Erhverv',
                        GARAGE_AREA: 'Garage',
                        BLOCK: 'Blok',
                    },
                },
                SALARY: {
                    PREVIOUS_COMPANY_NAME: 'Tidligere selskabsnavn',
                    PREVIOUS_COMPANY_COVERAGE_AMOUNT: 'Tidligere forsikringssum (pr. måned)',
                    PREVIOUS_COMPANY_QUALIFICATION_PERIOD: 'Tidligere anciennitet (måneder)',
                    OCCUPATION: 'Beskæftigelse',
                    COVERAGE_AMOUNTS: 'Forsikringssum',
                    INITIAL_QUALIFICATION_PERIOD: 'Kvalifikationsperiode (måneder)',
                    QUALIFICATION_PERIOD: 'Rekvalifikationsperiode',
                    COVERAGE_PERIOD: 'Udbetalingsperiode',
                    QUALIFYING_PERIOD: 'Selvrisikoperiode',
                    TRANSFERRED_FROM_PREVIOUS: 'Overført fra tidligere',
                },
            },
        },
    },
};
