<div class="kt-subheader kt-grid__item" id="kt_subheader">
    <div class="steps-container">
        <!-- Step 1 -->
        <div class="step-wrapper" [ngClass]="{ current: step === 1, done: step > 1, todo: step < 1 }">
            <div class="icon-wrapper">
                <i class="flaticon2-supermarket"></i>
            </div>
            <div class="text-wrapper">1. {{ 'PAGES.CALCULATE.CALCULATE_PROGRESS.CREATE_OFFER' | translate }}</div>
        </div>
        <div class="arrow-wrapper" [ngClass]="{ done: step > 1, todo: step == 1 }">
            <i class="fas fa-angle-right"></i>
        </div>

        <!-- Step 2 -->
        <div class="step-wrapper" [ngClass]="{ current: step === 2, done: step > 2, todo: step < 2 }">
            <div class="icon-wrapper">
                <i class="flaticon2-list"></i>
            </div>
            <div class="text-wrapper">2. {{ 'PAGES.CALCULATE.CALCULATE_PROGRESS.CALCULATE_OFFER' | translate }}</div>
        </div>
        <div class="arrow-wrapper" [ngClass]="{ done: step > 2, todo: step < 1 }">
            <i class="fas fa-angle-right"></i>
        </div>

        <!-- Step 3 -->
        <div class="step-wrapper" [ngClass]="{ current: step === 3, done: step > 3, todo: step < 3 }">
            <div class="icon-wrapper">
                <i class="flaticon2-list-1"></i>
            </div>
            <div class="text-wrapper">3. {{ 'PAGES.CALCULATE.CALCULATE_PROGRESS.SUMMARY' | translate }}</div>
        </div>
        <div class="arrow-wrapper" [ngClass]="{ done: step > 3, todo: step < 2 }">
            <i class="fas fa-angle-right"></i>
        </div>

        <!-- Step 4 -->
        <div class="step-wrapper" [ngClass]="{ current: step === 4, done: step > 4, todo: step < 4 }">
            <div class="icon-wrapper">
                <i class="flaticon2-box-1"></i>
            </div>
            <div class="text-wrapper">4. {{ 'PAGES.CALCULATE.CALCULATE_PROGRESS.COMPLETE' | translate }}</div>
        </div>
    </div>
</div>
