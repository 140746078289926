import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { IdNameModel } from '../../../services/service-models/idName';
import { LeadsServiceWrapper } from '../../../services/leads-service/leads.service.wrapper';
import { AlertService } from '../../../services/alert-service/alert.service';
import { AgencyServiceWrapper } from '../../../services/agency-service/agency.service.wrapper';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'leads-bulk-create-modal',
    templateUrl: './leads-bulk-create-modal.component.html',
    styleUrls: ['./leads-bulk-create-modal.component.scss', '../../../../assets/sass/custom/file-icons.scss'],
})
export class LeadsBulkCreateModalComponent implements OnInit {
    agency: IdNameModel;
    campaign: IdNameModel;

    isCreatingLead: boolean;
    campaignsObservable: Observable<IdNameModel[]>;
    agenciesObservable: Observable<IdNameModel[]>;

    acceptMimeTypes = ['text/csv'];
    // If the user has Office installed it will set the mime type to "application/vnd.ms-excel" as dictated by the regestry [HKEY_LOCAL_MACHINE\SOFTWARE\Classes\.csv\Content Type]
    allowedMimeTypes = ['text/csv', 'application/vnd.ms-excel'];
    allowedFileExtensions = ['csv'];
    file: File;
    isFileOnTop = false;
    isFileAllowed = true;

    isCreatingLeads: boolean = false;
    creatingLeadsSucceded: boolean;

    constructor(private leadsService: LeadsServiceWrapper, public activeModal: NgbActiveModal, private alertService: AlertService, private agencyService: AgencyServiceWrapper) {}

    ngOnInit(): void {
        this.agenciesObservable = this.agencyService.getAgencies().pipe(
            tap((agencies) => {
                if (agencies.length === 1) {
                    this.agency = agencies[0];
                    this.onAgencySelected();
                }
            })
        );
    }

    onAgencySelected() {
        this.campaignsObservable = this.leadsService.getLeadBulkCampaigns(this.agency.id);
    }

    isStringFalsy(value: string): boolean {
        return !Boolean(value);
    }

    onClose() {
        this.activeModal.close();
    }

    onFilesDrop(event: DragEvent) {
        // Prevent default behavior (Prevent file from being opened)
        event.preventDefault();

        if (event.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            for (let i = 0; i < event.dataTransfer.items.length; i++) {
                if (this.isFileExtensionAllowed(event.dataTransfer.files[i].name)) {
                    this.file = event.dataTransfer.items[i].getAsFile();
                }
            }
        } else {
            // Use DataTransfer interface to access the file(s)
            for (let i = 0; i < event.dataTransfer.files.length; i++) {
                if (this.isFileExtensionAllowed(event.dataTransfer.files[i].name)) {
                    this.file = event.dataTransfer.files[i];
                }
            }
        }
    }

    isFileExtensionAllowed(filename: string): boolean {
        if (!filename) {
            return false;
        }

        const extension = this.getFileExtension(filename.toLowerCase());
        return this.allowedFileExtensions.some((e) => e === extension);
    }

    isFileTypeAllowed(mimeType: string): boolean {
        const mimeTypeLower = mimeType.toLowerCase();
        return this.allowedMimeTypes.some((mt) => mt === mimeTypeLower);
    }

    onDragOver(event: DragEvent) {
        event.stopPropagation();
        event.preventDefault();

        this.isFileOnTop = event.dataTransfer.items && event.dataTransfer.items[0].kind === 'file';
        this.isFileAllowed = this.isFileTypeAllowed(event.dataTransfer.items[0].type);
    }

    onDragLeave(event: DragEvent) {
        event.stopPropagation();
        event.preventDefault();

        this.isFileOnTop = false;
        this.isFileAllowed = true;
    }

    onFilesSelected(files: FileList) {
        for (let i = 0; i < files.length; i++) {
            if (this.isFileExtensionAllowed(files.item(i).name)) {
                this.file = files.item(i);
            }
        }
    }

    clickFileInput() {
        window.document.getElementById('file-input').click();
    }

    getFileExtension(fileName: string): string {
        return fileName.split('.').pop();
    }

    upload() {
        if (!this.agency || !this.campaign || !this.isFileExtensionAllowed(this.file?.name)) {
            return;
        }

        this.isCreatingLeads = true;
        this.creatingLeadsSucceded = undefined;

        this.agencyService
            .createBulkLeads(this.agency.id, this.campaign.id, this.file)
            .then(() => {
                this.creatingLeadsSucceded = true;
                this.file = null;
            })
            .finally(() => {
                this.isCreatingLeads = false;
            });
    }
}
