// Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
// Layout
import { LayoutConfigService } from '../../../core/_base/layout';
// Object-Path
import * as objectPath from 'object-path';
import { Router, NavigationEnd } from '@angular/router';
import { CalculateStep } from '../../pages/calculate/shared/calculateStep';
import { Subscription } from 'rxjs';

@Component({
    selector: 'kt-subheader',
    templateUrl: './subheader.component.html',
})
export class SubheaderComponent implements OnInit, OnDestroy {
    // Public properties
    // subheader layout
    layout: string;
    fluid: boolean;
    clear: boolean;

    calculateStep: CalculateStep;
    subscriptions: Subscription[] = [];

    /**
     * Component constructor
     *
     * @param layoutConfigService: LayoutConfigService
     */
    constructor(private layoutConfigService: LayoutConfigService, private router: Router) {}

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit(): void {
        const config = this.layoutConfigService.getConfig();

        this.layout = objectPath.get(config, 'subheader.layout');
        this.fluid = objectPath.get(config, 'footer.self.width') === 'fluid';
        this.clear = objectPath.get(config, 'subheader.clear');

        this.setCalculateStep(document.location.href);

        this.subscriptions.push(
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.setCalculateStep(event.url);
                }
            })
        );
    }

    setCalculateStep(url: string) {
        if (url.indexOf('/chooseproducts') !== -1 || url.indexOf('/createoffer/start') !== -1) {
            this.calculateStep = CalculateStep.CreateOffer;
        } else if (url.indexOf('/summary/offer') !== -1) {
            this.calculateStep = CalculateStep.Summary;
        } else if (url.indexOf('/summary/activate') !== -1) {
            this.calculateStep = CalculateStep.Complete;
        } else if (url.indexOf('/calculate/') !== -1) {
            this.calculateStep = CalculateStep.CalculateOffer;
        } else {
            this.calculateStep = null;
        }
    }
}
