<div *ngIf="!showPasswordChangedMessage">
    <div *ngIf="!username">
        <h3>{{ 'PAGES.NEWPASSWORD.HEADER' | translate }}</h3>
        <p>{{ 'PAGES.NEWPASSWORD.DESCRIPTION' | translate }}</p>
    </div>

    <form class="kt-form" [formGroup]="renewForm" autocomplete="off">
        <div class="form-group">
            <mat-form-field>
                <mat-label>{{ 'PAGES.NEWPASSWORD.USERNAME' | translate }}</mat-label>
                <input matInput type="text" placeholder="{{ 'PAGES.NEWPASSWORD.USERNAME' | translate }}" formControlName="username" autocomplete="off" />
                <mat-error *ngIf="isControlHasError('username', 'required')">
                    <strong>{{ 'SHARED.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                </mat-error>
                <mat-error *ngIf="isControlHasError('username', 'minlength')">
                    <strong>{{ 'SHARED.VALIDATION.MIN_LENGTH_FIELD' | translate }} 2</strong>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="form-group">
            <mat-form-field>
                <mat-label>{{ 'PAGES.NEWPASSWORD.PASSWORD' | translate }}</mat-label>
                <input matInput type="password" placeholder="{{ 'PAGES.NEWPASSWORD.PASSWORD' | translate }}" formControlName="currentPassword" autocomplete="off" />
                <mat-error *ngIf="isControlHasError('currentPassword', 'required')">
                    <strong>{{ 'SHARED.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                </mat-error>
                <mat-error *ngIf="isControlHasError('currentPassword', 'minlength')">
                    <strong>{{ 'SHARED.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                </mat-error>
                <mat-error *ngIf="isControlHasError('currentPassword', 'maxlength')">
                    <strong>{{ 'SHARED.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="form-group">
            <mat-form-field>
                <mat-label>{{ 'PAGES.NEWPASSWORD.NEWPASSWORD' | translate }}</mat-label>
                <input matInput type="password" placeholder="{{ 'PAGES.NEWPASSWORD.NEWPASSWORD' | translate }}" formControlName="newPassword" autocomplete="off" />
                <mat-error *ngIf="isControlHasError('newPassword', 'required')">
                    <strong>{{ 'SHARED.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                </mat-error>
                <mat-error *ngIf="isControlHasError('newPassword', 'minlength')">
                    <strong>{{ 'SHARED.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                </mat-error>
                <mat-error *ngIf="isControlHasError('newPassword', 'maxlength')">
                    <strong>{{ 'AUTSHARED.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field>
                <mat-label>{{ 'PAGES.NEWPASSWORD.CONFIRM_NEWPASSWORD' | translate }}</mat-label>
                <input matInput type="password" placeholder="{{ 'PAGES.NEWPASSWORD.CONFIRM_NEWPASSWORD' | translate }}" formControlName="newPasswordConfirm" autocomplete="off" />
                <mat-error *ngIf="isControlHasError('newPasswordConfirm', 'required')">
                    <strong>{{ 'SHARED.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                </mat-error>
                <mat-error *ngIf="isControlHasError('newPasswordConfirm', 'minlength')">
                    <strong>{{ 'SHARED.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                </mat-error>
                <mat-error *ngIf="isControlHasError('newPasswordConfirm', 'maxlength')">
                    <strong>{{ 'SHARED.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
                </mat-error>
            </mat-form-field>
        </div>

        <div *ngIf="passwordsNotMatching" class="alert alert-warning">
            <div class="alert-text">
                {{ 'PAGES.NEWPASSWORD.NOT_MATCHING' | translate }}
            </div>
        </div>

        <div *ngIf="renewError" class="alert alert-danger">
            <div class="alert-text">
                {{ 'PAGES.NEWPASSWORD.RENEW_ERROR' | translate }}
            </div>
        </div>

        <div class="kt-login__actions">
            <button
                (click)="renewPassword()"
                [disabled]="renewloading"
                [ngClass]="{ 'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': renewloading }"
                id="kt_renewpassword_submit"
                class="btn btn-primary btn-elevate kt-login__btn-primary"
            >
                {{ 'PAGES.NEWPASSWORD.CONFIRM_PASSWORD_BUTTON' | translate }}
            </button>
        </div>
    </form>
</div>

<div *ngIf="showPasswordChangedMessage" class="alert alert-success">
    <div class="alert-text">
        {{ 'PAGES.NEWPASSWORD.PASSWORD_CHANGED' | translate }}
        <br />
        <br />
        <!-- Do not use routerLink here - we want to force a new state for the login page -->
        <a [href]="'/signout'" class="btn btn-primary">{{ 'PAGES.NEWPASSWORD.GO_TO_LOGIN' | translate }}</a>
    </div>
</div>
