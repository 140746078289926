import { IdNameDefaultValueItem } from '@scalepoint/nemex-api-clients/services';

export class IdNameDefaultValueModel {
    readonly defaultValue?: boolean;
    readonly id?: string | undefined;
    readonly name?: string | undefined;

    constructor(apiIdNameDefaultValue: IdNameDefaultValueItem) {
        if (!apiIdNameDefaultValue) {
            return;
        }

        this.defaultValue = apiIdNameDefaultValue.defaultValue;
        this.id = apiIdNameDefaultValue.id;
        this.name = apiIdNameDefaultValue.name;
    }
}
