import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    BlockedCprInput,
    IBlockedCprInput,
    AddressProtectedExtendedCprInput,
    CprInput,
    CprService,
    ExtendedCprInput,
    IAddressProtectedExtendedCprInput,
    ICpr,
    ICprInput,
    IExtendedCprInput,
} from '@scalepoint/nemex-api-clients/services';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { CprResultModel } from '../service-models/cpr/cprResult';
import { ExtendedCprResultModel } from '../service-models/cpr/extendedCprResult';
import { BlockedCprLookupModel } from '../service-models/cpr/blockedCprResult';

@Injectable()
export class CprServiceWrapper {
    constructor(private cprService: CprService) {}

    lookupCpr(cpr: string, agencyId: string): Observable<CprResultModel> {
        var cprInput: ICprInput = { agencyId };

        return this.cprService.cpr(cpr, environment.apiVersion, new CprInput(cprInput)).pipe(map((cprResult) => new CprResultModel(cprResult)));
    }

    extendedLookupCpr(cpr: string, agencyId: string): Observable<ExtendedCprResultModel> {
        var extendedCprInput: IExtendedCprInput = { agencyId };

        return this.cprService.extended(cpr, environment.apiVersion, new ExtendedCprInput(extendedCprInput)).pipe(map((cprResult) => new ExtendedCprResultModel(cprResult)));
    }

    addressProtectedExtendedLookupCpr(cpr: string, fullName: string, agencyId: string): Observable<ExtendedCprResultModel> {
        var addressProtectedCprInput: IAddressProtectedExtendedCprInput = { agencyId, fullName };

        return this.cprService
            .addressProtected(cpr, environment.apiVersion, new AddressProtectedExtendedCprInput(addressProtectedCprInput))
            .pipe(map((cprResult) => new ExtendedCprResultModel(cprResult)));
    }

    blockedCprLookup(cpr: string, agencyId: string): Observable<BlockedCprLookupModel> {
        const blockedCprInput: IBlockedCprInput = {
            agencyId: agencyId,
        };

        return this.cprService.blocked(cpr, environment.apiVersion, new BlockedCprInput(blockedCprInput)).pipe(map((bc) => new BlockedCprLookupModel(bc)));
    }
}
