// Angular
import { Component, HostBinding, OnInit, Input, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
// RxJS
import { filter } from 'rxjs/operators';
// Translate
import { TranslationService } from '../../../../../core/_base/layout';

interface LanguageFlag {
    lang: string;
    name: string;
    flag: string;
    active?: boolean;
}

@Component({
    selector: 'kt-language-selector',
    templateUrl: './language-selector.component.html',
})
export class LanguageSelectorComponent implements OnInit, OnDestroy {
    // Public properties
    @HostBinding('class') classes = '';
    @Input() iconType: '' | 'brand';

    subscriptions: Subscription[] = [];
    language: LanguageFlag;
    languages: LanguageFlag[] = [
        {
            lang: 'da',
            name: 'Dansk',
            flag: './assets/media/flags/174-denmark.svg',
        },
        {
            lang: 'en',
            name: 'English',
            flag: './assets/media/flags/260-united-kingdom.svg',
        },
    ];

    /**
     * Component constructor
     *
     * @param translationService: TranslationService
     * @param router: Router
     */
    constructor(private translationService: TranslationService, private router: Router) {}

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        this.setSelectedLanguage();
        this.subscriptions.push(
            this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((event) => {
                this.setSelectedLanguage();
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    /**
     * Set language
     *
     * @param lang: any
     */
    setLanguage(lang) {
        this.languages.forEach((language: LanguageFlag) => {
            if (language.lang === lang) {
                language.active = true;
                this.language = language;
            } else {
                language.active = false;
            }
        });
        this.translationService.setLanguage(lang);
    }

    /**
     * Set selected language
     */
    setSelectedLanguage(): any {
        this.setLanguage(this.translationService.getSelectedLanguage());
    }
}
