import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../../../core/core.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { ContentLoadingSpinnerComponent } from './content-loading-spinner.component';

@NgModule({
    imports: [CommonModule, CoreModule, InlineSVGModule],
    declarations: [ContentLoadingSpinnerComponent],
    exports: [ContentLoadingSpinnerComponent],
    providers: [],
})
export class ContentLoadingSpinnerModule {}
