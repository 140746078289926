import { InsuranceTypeEnum } from './policy/insuranceType';

export class ProductInfoModel {
    readonly id?: string | undefined;
    readonly name?: string | undefined;
    insuranceType: InsuranceTypeEnum;
    discountEntry?: boolean;
    discountGiving?: boolean;
    availableForSignup?: boolean;
    availableForChange?: boolean;

    constructor(id: string, name: string, discountEntry: boolean, discountGiving: boolean, insuranceType: InsuranceTypeEnum, availableForSignup: boolean, availableForChange: boolean) {
        this.id = id;
        this.name = name;
        this.discountEntry = discountEntry;
        this.discountGiving = discountGiving;
        this.insuranceType = insuranceType;
        this.availableForSignup = availableForSignup;
        this.availableForChange = availableForChange;
    }
}
