import { CoreModule } from '../../../core/core.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { LeadsCreateModalComponent } from './leads-create-modal.component';

@NgModule({
    declarations: [LeadsCreateModalComponent],
    imports: [CommonModule, CoreModule, FormsModule, NgbModule, PipesModule, TranslateModule.forChild(), NgSelectModule],
    exports: [LeadsCreateModalComponent],
})
export class LeadsCreateModalModule {}
