import { AgencyTextMessageModalComponent } from './agency-text-message-modal.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from '../../../../core/core.module';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../../../../pipes/pipes.module';
import { ApiModule } from '../../../../api.module';
import { AgencyServiceWrapper } from '../../../../services/agency-service/agency.service.wrapper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgModule } from '@angular/core';
import { SmsTemplatesServiceWrapper } from '../../../../services/sms-templates-service/sms-templates.service.wrapper';

@NgModule({
    imports: [CommonModule, FormsModule, NgbModule, CoreModule, TranslateModule.forChild(), PipesModule, ApiModule, NgbDropdownModule, MatFormFieldModule, MatInputModule],
    providers: [AgencyServiceWrapper, SmsTemplatesServiceWrapper],
    declarations: [AgencyTextMessageModalComponent],
    exports: [AgencyTextMessageModalComponent],
})
export class AgencyTextMessageModalModule {}
