import { IdNameDefaultValueModel } from './../service-models/idNameDefaultValue';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AgencyService, AddAgencySmsInput, IAddAgencySmsInput } from '@scalepoint/nemex-api-clients/services';
import { map, share } from 'rxjs/operators';
import { AgencyFeatureTypeModel } from '../service-models/agencyFeatureType';
import { environment } from '../../../environments/environment';
import { QuestionnaireModel } from '../service-models/questionnaire';
import { AgencyModel } from '../service-models/agency';
import { ProductCategoryWithProductsModel } from '../service-models/productCategoryWithProducts';
import { AppConfigService } from '../config-service/config.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class AgencyServiceWrapper {
    agencyFeaturesCache: { [agencyId: string]: AgencyFeatureTypeModel[] } = {};

    constructor(private agencyService: AgencyService, private appConfig: AppConfigService, private httpClient: HttpClient) {}

    getCaseWorkers(agencyId: string): Observable<IdNameDefaultValueModel[]> {
        return this.agencyService.caseworkers(agencyId, 1, 1000, environment.apiVersion).pipe(
            share(),
            map((pagedCaseWorkers) => pagedCaseWorkers.items.map((c) => new IdNameDefaultValueModel(c)))
        );
    }

    getFeatures(agencyId: string): Observable<AgencyFeatureTypeModel[]> {
        const cachedAgencyFeatures = this.agencyFeaturesCache[agencyId];

        if (cachedAgencyFeatures) {
            return of(cachedAgencyFeatures);
        }

        return this.agencyService.features(agencyId, environment.apiVersion).pipe(
            share(),
            map((features) => {
                const featureModels = features.map((f) => new AgencyFeatureTypeModel(f.featureType));
                this.agencyFeaturesCache[agencyId] = featureModels;

                return featureModels;
            })
        );
    }

    getQuestionnaires(agencyId: string): Observable<QuestionnaireModel[]> {
        return this.agencyService.questionnairesAll(agencyId, environment.apiVersion).pipe(map((questionnaires) => questionnaires.map((q) => new QuestionnaireModel(q))));
    }

    getAgencies(): Observable<AgencyModel[]> {
        return this.agencyService.agencies(environment.apiVersion).pipe(map((agencies) => agencies.map((a) => new AgencyModel(a))));
    }

    getProducts(agencyId: string): Observable<ProductCategoryWithProductsModel[]> {
        return this.agencyService.products(agencyId, environment.apiVersion).pipe(map((products) => products.map((p) => new ProductCategoryWithProductsModel(p))));
    }

    sendTextMessages(agencyId: string, text: string, phoneNumbers: string[]) {
        const input: IAddAgencySmsInput = {
            phoneNumbers,
            text,
        };

        return this.agencyService.textMessages(agencyId, environment.apiVersion, new AddAgencySmsInput(input));
    }

    sendReviewInvites(agencyId: string, files: File[]) {
        return new Promise<void>((resolve, reject) => {
            const url = `${this.appConfig.appConfig.Nemex.ApiHost}/agencies/${agencyId}/reviews?api-version=${environment.apiVersion}`;

            const formData = new FormData();
            files.forEach((f) => formData.append('csvFiles', f));

            this.httpClient
                .post(url, formData)
                .toPromise()
                .then(() => {
                    resolve();
                })
                .catch(reject);
        });
    }

    createBulkLeads(agencyId: string, campaignId: string, file: File): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const url = `${this.appConfig.appConfig.Nemex.ApiHost}/agencies/${agencyId}/lead-bulks?api-version=${environment.apiVersion}`;

            const formData = new FormData();
            formData.append('campaignId', campaignId);
            formData.append('csvFile', file);

            this.httpClient
                .post(url, formData)
                .toPromise()
                .then(() => {
                    resolve();
                })
                .catch(reject);
        });
    }
}
