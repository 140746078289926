import { IdNameItem, IIdNameItem } from '@scalepoint/nemex-api-clients/services';

export class IdNameModel {
    readonly id?: string | undefined;
    readonly name?: string | undefined;

    constructor(apiNameId: IdNameItem) {
        if (!apiNameId) {
            return;
        }

        this.id = apiNameId.id;
        this.name = apiNameId.name;
    }
}
