export class PageConfig {
    public defaults: any = {
        frontpage: {
            page: {
                title: 'FRONTPAGE',
            },
        },
        customer: {
            search: {
                page: {
                    title: 'SEARCH_CUSTOMER',
                },
            },
            details: {
                page: {
                    title: 'CUSTOMER_OVERVIEW',
                },
            },
        },
        createoffer: {
            start: {
                page: {
                    title: 'CREATE_OFFER',
                },
            },
            chooseproducts: {
                page: {
                    title: 'CREATE_OFFER',
                },
            },
        },
        dfim: {
            search: {
                page: {
                    title: 'DFIM_LOOKUP',
                },
            },
        },
        cpr: {
            search: {
                page: {
                    title: 'CPR_LOOKUP',
                },
            },
        },
        folders: {
            admin: {
                page: {
                    title: 'SETTINGS',
                },
            },
            view: {
                page: {
                    title: 'FOLDERS',
                },
            },
        },
        smstemplates: {
            page: {
                title: 'SMS_TEMPLATES',
            },
        },
        customerreviews: {
            page: {
                title: 'CUSTOMER_REVIEWS',
            },
        },
        statistics: {
            questionnaires: {
                page: {
                    title: 'STATISTICS',
                },
            },
        },
        policymta: {
            any: {
                page: {
                    title: 'POLICY_MTA',
                },
            },
        },
        error: {
            404: {
                page: { title: '404 Not Found', subheader: false },
            },
            403: {
                page: { title: '403 Access Forbidden', subheader: false },
            },
        },
    };

    public get configs(): any {
        return this.defaults;
    }
}
