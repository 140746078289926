import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AgencyServiceWrapper } from '../../../../services/agency-service/agency.service.wrapper';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../../services/alert-service/alert.service';
import { AuthService } from '../../../../core/auth';
import { Role } from '../../../../core/auth/_services/roles';
import { AgencyModel } from '../../../../services/service-models/agency';
import { SmsTemplateModel } from '../../../../services/service-models/sms/smsTemplate';
import { SmsTemplatesServiceWrapper } from '../../../../services/sms-templates-service/sms-templates.service.wrapper';

@Component({
    selector: 'agency-text-message-modal',
    templateUrl: './agency-text-message-modal.component.html',
    styleUrls: ['./agency-text-message-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class AgencyTextMessageModalComponent implements OnInit {
    agencies: AgencyModel[];
    uiTexts = {
        recipients: '',
        recipientsPlaceholder: '',
        recipientsExample: '',
    };
    textMessage: string;
    phoneNumbersInput = '';
    areUserInputsValid = false;
    isSending: boolean;
    selectedAgency: AgencyModel;
    hasBulkRole: boolean;
    showInvalidPhoneNumbers = false;
    selectedSmsTemplate: SmsTemplateModel;
    smsTemplates: SmsTemplateModel[];
    filteredSmsTemplates: SmsTemplateModel[];

    constructor(
        private agencyService: AgencyServiceWrapper,
        private activeModal: NgbActiveModal,
        public translate: TranslateService,
        private alertService: AlertService,
        private authService: AuthService,
        private changeDetectorRef: ChangeDetectorRef,
        private smsTemplateService: SmsTemplatesServiceWrapper
    ) {}

    ngOnInit() {
        this.smsTemplateService.getAllTemplates().subscribe(
            (templates) => {
                this.smsTemplates = templates;
                this.updateFilteredSmsTemplates();
            },
            (error) => {
                this.alertService.showAlertFromError(error);
            }
        );

        this.agencyService.getAgencies().subscribe(
            (agencies) => {
                this.agencies = agencies;
            },
            (error) => {
                this.alertService.showAlertFromError(error);
            }
        );

        this.hasBulkRole = this.authService.hasRole(Role.SmsAgencyBulk);

        if (this.hasBulkRole) {
            this.uiTexts.recipients = this.translate.instant('COMPONENTS.AGENCY_TEXT_MESSAGE_MODAL.BULK.RECIPIENTS');
            this.uiTexts.recipientsPlaceholder = this.translate.instant('COMPONENTS.AGENCY_TEXT_MESSAGE_MODAL.BULK.RECIPIENTS_PLACEHOLDER');
            this.uiTexts.recipientsExample = this.translate.instant('COMPONENTS.AGENCY_TEXT_MESSAGE_MODAL.BULK.RECIPIENTS_EXAMPLE');
        } else {
            this.uiTexts.recipients = this.translate.instant('COMPONENTS.AGENCY_TEXT_MESSAGE_MODAL.SINGLE.RECIPIENTS');
            this.uiTexts.recipientsPlaceholder = this.translate.instant('COMPONENTS.AGENCY_TEXT_MESSAGE_MODAL.SINGLE.RECIPIENTS_PLACEHOLDER');
            this.uiTexts.recipientsExample = this.translate.instant('COMPONENTS.AGENCY_TEXT_MESSAGE_MODAL.SINGLE.RECIPIENTS_EXAMPLE');
        }
    }

    updateFilteredSmsTemplates() {
        this.filteredSmsTemplates = this.selectedAgency ? this.smsTemplates.filter((t) => t.agencyId === this.selectedAgency.id) : this.smsTemplates;
    }

    extractPhoneNumbers(): string[] {
        // splits on comma, semicolon, newline and space
        const phoneNumbers = this.phoneNumbersInput.split(/(?:,|;|\n| )+/);
        const uniquePhoneNumbers = phoneNumbers.filter((v, i, a) => a.indexOf(v) === i);
        return uniquePhoneNumbers;
    }

    arePhoneNumbersValid() {
        const phoneNumbers = this.extractPhoneNumbers();
        const isValid = phoneNumbers && !phoneNumbers.some((p) => p.length !== 8 || parseInt(p, 10).toString() !== p);

        if (this.hasBulkRole) {
            return isValid && phoneNumbers.length > 0;
        }

        return isValid && phoneNumbers.length === 1;
    }

    validateUserInputs() {
        const arePhoneNumbersValid = this.arePhoneNumbersValid();

        if (this.showInvalidPhoneNumbers) {
            this.showInvalidPhoneNumbers = !arePhoneNumbersValid;
        }

        this.areUserInputsValid = this.selectedAgency && this.textMessage && this.textMessage.length > 0 && arePhoneNumbersValid;
        this.changeDetectorRef.markForCheck();
    }

    onRecipientBlur() {
        this.showInvalidPhoneNumbers = !this.arePhoneNumbersValid();
    }

    onSelectAgency(agency: AgencyModel) {
        this.selectedAgency = agency;
        this.updateFilteredSmsTemplates();
        this.validateUserInputs();
    }

    onSelectTemplate(smsTemplate: SmsTemplateModel) {
        this.selectedSmsTemplate = smsTemplate;
        this.textMessage = smsTemplate.text;
    }

    onSend() {
        if (!this.areUserInputsValid) {
            return;
        }

        this.isSending = true;

        const phoneNumbers = this.extractPhoneNumbers();

        this.agencyService.sendTextMessages(this.selectedAgency.id, this.textMessage, phoneNumbers).subscribe(
            () => {
                this.onClose();
                this.isSending = false;
            },
            (error) => {
                this.alertService.showAlertFromError(error);
                this.isSending = false;
            }
        );
    }

    onClose() {
        this.activeModal.close();
    }
}
