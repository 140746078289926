<!-- begin:: Footer -->
<div class="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop" id="kt_footer">
    <div class="kt-container" [ngClass]="{ 'kt-container--fluid': fluid }">
        <div class="kt-footer__copyright"></div>
        <div class="kt-footer__menu">
            <a class="kt-footer__menu-link">Version: {{ version }}</a>
            <a href="https://scalepoint.com" target="_blank" class="kt-footer__menu-link kt-link">Kontakt</a>
        </div>
    </div>
</div>
<!-- end:: Footer -->
