import { LeadPullDetails } from '@scalepoint/nemex-api-clients/services';
import { IdNameModel } from '../idName';
import { PersonNameModel } from '../personName';
import { LeadWrapupCommentDetailsModel } from './leadWrapupCommentDetails';

export class LeadPullDetailsModel {
    readonly pullId?: string;
    readonly leadId?: string | undefined;
    name?: PersonNameModel;
    readonly phoneNumber?: string | undefined;
    email?: string | undefined;
    campaign?: IdNameModel;
    comment?: string | undefined;
    isNewContact: boolean;
    initiated: boolean;
    leadWrapupComments?: LeadWrapupCommentDetailsModel[] | undefined;
    status: string;
    contactTries: number;
    lastUpdated?: Date;

    constructor(apiModel: LeadPullDetails, isNewContact: boolean = false) {
        this.pullId = apiModel.pullId;
        this.leadId = apiModel.leadId;
        this.name = new PersonNameModel(apiModel.name);
        this.phoneNumber = apiModel.phoneNumber;
        this.email = apiModel.email;
        this.campaign = new IdNameModel(apiModel.campaign);
        this.comment = apiModel.comment;
        this.isNewContact = isNewContact;
        this.leadWrapupComments = apiModel.leadWrapupComments
            ? apiModel.leadWrapupComments.sort((a, b) => a.created.getTime() - b.created.getTime()).map((c) => new LeadWrapupCommentDetailsModel(c))
            : [];

        this.status = apiModel.status;
        this.contactTries = apiModel.contactTries;
        this.lastUpdated = apiModel.lastUpdated;
    }
}
