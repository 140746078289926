import { AgencyFeatureTypeEnumDisplay } from '@scalepoint/nemex-api-clients/services';
import { AgencyFeatureTypeEnum } from './agencyFeatureTypeEnum';

export class AgencyFeatureTypeModel {
    readonly typeCode?: string | undefined;
    readonly name?: string | undefined;
    enum?: AgencyFeatureTypeEnum;

    constructor(apiAgencyFeatureType: AgencyFeatureTypeEnumDisplay) {
        this.typeCode = apiAgencyFeatureType.typeCode;
        this.name = apiAgencyFeatureType.name;
        this.enum = apiAgencyFeatureType.enum.valueOf();
    }
}
