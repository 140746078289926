<div class="modal-header fade show alert alert-primary" role="alert">
    <div class="alert-text">
        <h5>{{ 'COMPONENTS.LEADS_BULK_CREATE_MODAL.TITLE' | translate }}</h5>
    </div>
</div>
<div class="modal-body">
    <div class="data-row">
        <div class="row-label">{{ 'COMPONENTS.LEADS_BULK_CREATE_MODAL.AGENCY' | translate }}</div>
        <div class="row-content">
            <ng-select
                [items]="agenciesObservable | async"
                [searchable]="true"
                bindLabel="name"
                placeholder="{{ 'COMPONENTS.LEADS_BULK_CREATE_MODAL.AGENCY' | translate }}"
                [appendTo]="'body'"
                [(ngModel)]="agency"
                (change)="onAgencySelected()"
            >
            </ng-select>
        </div>
    </div>

    <div class="data-row">
        <div class="row-label">{{ 'COMPONENTS.LEADS_BULK_CREATE_MODAL.CAMPAIGN' | translate }}</div>
        <div class="row-content">
            <ng-select
                [items]="campaignsObservable | async"
                [searchable]="true"
                bindLabel="name"
                placeholder="{{ 'COMPONENTS.LEADS_BULK_CREATE_MODAL.CAMPAIGN' | translate }}"
                [appendTo]="'body'"
                [(ngModel)]="campaign"
            >
            </ng-select>
        </div>
    </div>

    <div class="data-row">
        <input type="file" multiple="multiple" id="file-input" [accept]="acceptMimeTypes" (change)="onFilesSelected($event.target.files)" />

        <div
            id="drop-zone"
            type="file"
            (drop)="onFilesDrop($event)"
            (click)="clickFileInput()"
            (dragover)="onDragOver($event)"
            (dragleave)="onDragLeave($event)"
            [ngClass]="{ highlight: isFileOnTop, error: !isFileAllowed }"
        >
            <div *ngIf="!file" class="help-text">
                <h6>{{ 'COMPONENTS.LEADS_BULK_CREATE_MODAL.DRAG_DROP_MESSAGE' | translate }}</h6>
                <p>{{ 'COMPONENTS.LEADS_BULK_CREATE_MODAL.DRAG_DROP_FILE_TYPES' | translate }}</p>
                <p>{{ 'COMPONENTS.LEADS_BULK_CREATE_MODAL.COLUMN_HELP_TEXT' | translate }}</p>
            </div>
            <span *ngIf="file" class="kt-badge kt-badge--brand kt-badge--inline">
                <img [className]="'file-icon extension-' + getFileExtension(file.name)" />
                {{ file.name }}
            </span>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button
        type="button"
        class="btn btn-secondary"
        [disabled]="isCreatingLead || !campaign || !file"
        (click)="upload()"
        [ngClass]="{ 'kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--info': isCreatingLead }"
    >
        {{ 'COMPONENTS.LEADS_BULK_CREATE_MODAL.CREATE' | translate }}
    </button>
    <button type="button" class="btn btn-secondary" (click)="onClose()">{{ 'SHARED.COMMON.CANCEL' | translate }}</button>
</div>
