import { TokenStorage } from './../../../../../core/auth/token-storage.service';
// Angular
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'kt-user-profile',
    templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
    username: string;
    userimage: string = '/assets/media/users/default.jpg';

    @Input() avatar: boolean = true;
    @Input() greeting: boolean = true;
    @Input() badge: boolean;
    @Input() icon: boolean;

    constructor(private tokenStorage: TokenStorage, private translate: TranslateService) {}

    ngOnInit(): void {
        this.username = this.tokenStorage.getUsername();
    }
}
