import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { AlertDialogComponent } from './alert-dialog.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [CommonModule, MatIconModule, TranslateModule.forChild()],
    declarations: [AlertDialogComponent],
    exports: [AlertDialogComponent],
})
export class AlertDialogModule {}
