import { NgModule } from '@angular/core';
import {
    CustomerService,
    API_BASE_URL,
    AuthenticationService,
    PolicyService,
    PolicyDocumentService,
    CustomerEmailService,
    PolicyNoteService,
    PolicyDetailsService,
    CustomerNoteService,
    AgencyService,
    CustomerQuestionnaireService,
    CustomerRelationService,
    DfimService,
    FolderService,
    QuestionnaireService,
    SmsTemplateService,
    CustomerOfferService,
    OfferService,
    OfferChangeReasonService,
    ProductService,
    OfferLineService,
    CalculationService,
    DashboardService,
    DashboardWidgetService,
    SalesService,
    CprService,
    ConsentService,
} from '@scalepoint/nemex-api-clients/services';
import { AppConfigService } from './services/config-service/config.service';

export function apiHostLoadFactory(config: AppConfigService) {
    return config.appConfig.Nemex.ApiHost;
}

@NgModule({
    imports: [],
    providers: [
        {
            provide: API_BASE_URL,
            useFactory: apiHostLoadFactory,
            deps: [AppConfigService],
        },
        AgencyService,
        AuthenticationService,
        CustomerService,
        CustomerEmailService,
        CustomerNoteService,
        CustomerQuestionnaireService,
        CustomerRelationService,
        PolicyDocumentService,
        PolicyService,
        PolicyNoteService,
        PolicyDetailsService,
        DfimService,
        FolderService,
        QuestionnaireService,
        SmsTemplateService,
        CustomerOfferService,
        OfferService,
        OfferChangeReasonService,
        ProductService,
        OfferLineService,
        CalculationService,
        DashboardService,
        DashboardWidgetService,
        SalesService,
        CprService,
        ConsentService,
    ],
})
export class ApiModule {}
