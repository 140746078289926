<!-- begin:: Brand -->
<div class="kt-aside__brand kt-grid__item" [ngClass]="htmlClassService.getClasses('brand', true)" id="kt_aside_brand">
    <span class="nemex-logo" [inlineSVG]="'./assets/media/logos/svg/nemex-blue.svg'"></span>
    <div class="kt-aside__brand-logo">
        <a href="javascript:;" routerLink="/">
            <h2 style="font-size: 2.3rem; color: white;">SalesPortal</h2>
        </a>
    </div>
    <div class="kt-aside__brand-tools"></div>
</div>
<!-- end:: Brand -->
