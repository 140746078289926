import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'topbar-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
    searchString: string;
    showSearchInput: boolean;
    @ViewChild('searchInput') searchInputElement: ElementRef;

    constructor(private router: Router, private changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit() {}

    search() {
        this.router.navigate(['customer/search'], { queryParams: { query: this.searchString } });
        this.searchString = '';
    }

    onIconClick() {
        this.showSearchInput = true;
        this.changeDetectorRef.detectChanges();
        this.searchInputElement.nativeElement.focus();
    }
}
