import { InterceptService } from './core/_base/crud/utils/intercept.service';
import { FunctionsService } from './core/_base/crud/utils/functions.service';
import { TokenStorage } from './core/auth/token-storage.service';
// Angular
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, ErrorHandler } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OverlayModule } from '@angular/cdk/overlay';
// Perfect Scroll bar
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';
// Copmponents
import { AppComponent } from './app.component';
// Modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { ThemeModule } from './views/theme/theme.module';
// Partials
import { PartialsModule } from './views/partials/partials.module';
// Layout Services
import { KtDialogService, LayoutConfigService, LayoutRefService, MenuAsideService, MenuConfigService, MenuHorizontalService, PageConfigService, SubheaderService } from './core/_base/layout';
// Auth
import { AuthModule } from './views/pages/auth/auth.module';
import { AuthService } from './core/auth';

// CRUD
import { LayoutUtilsService } from './core/_base/crud';
// Config
import { LayoutConfig } from './core/_config/layout.config';
import { ApiModule } from './api.module';
import { AppConfigService } from './services/config-service/config.service';
import { LoadingService } from './services/loading-service/loading.service';
import { LoadingSpinnerModule } from './views/components/loading-spinner/loading-spinner.module';
import { ErrorInterceptService } from './core/_base/crud/utils/error-intercept.service';
import { AlertDialogModule } from './views/components/alert-dialog/alert-dialog.module';
import { GlobalErrorHandler } from './helpers/global-error-handler/global-error-handler';
import { ApplicationInsightsService } from './services/application-insights-service/application-insights.service';
import { BackendTranslateService } from './services/backend-translate-service/backend-translate.service';
import { PipesModule } from './pipes/pipes.module';
import { MatPaginatorIntlCustom } from './helpers/translate/matpaginatorintlcustom';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFormatter } from './helpers/ngb-date-formatter/ngb-date-formatter';
import { ContentLoadingSpinnerModule } from './views/components/content-loading-spinner/content-loading-spinner.module';
import { RouteReuseStrategy } from '@angular/router';
import { NemexRouteReuseStrategy } from './nemexRouteReuseStrategy';
import { BlockedCprModalModule } from './views/components/blocked-cpr-modal/blocked-cpr-modal.module';

// tslint:disable-next-line:class-name
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.5,
    swipeEasing: true,
    minScrollbarLength: 40,
    maxScrollbarLength: 300,
};

export function initializeLayoutConfig(layoutConfigService: LayoutConfigService) {
    // initialize app by loading default demo layout config
    return () => {
        if (layoutConfigService.getConfig() === null) {
            layoutConfigService.loadConfigs(new LayoutConfig().configs);
        }
    };
}

export function appConfigLoadFactory(config: AppConfigService) {
    return () => config.appConfig;
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        PartialsModule,
        CoreModule,
        OverlayModule,
        AuthModule.forRoot(),
        TranslateModule.forRoot(),
        MatProgressSpinnerModule,
        InlineSVGModule.forRoot(),
        ThemeModule,
        ApiModule,
        LoadingSpinnerModule,
        AlertDialogModule,
        PipesModule,
        ContentLoadingSpinnerModule,
        BlockedCprModalModule,
    ],
    exports: [],
    providers: [
        AuthService,
        TokenStorage,
        FunctionsService,
        AppConfigService,
        LayoutConfigService,
        LayoutRefService,
        MenuConfigService,
        PageConfigService,
        KtDialogService,
        {
            // app config initializer
            provide: APP_INITIALIZER,
            useFactory: appConfigLoadFactory,
            deps: [AppConfigService],
            multi: true,
        },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
        {
            // layout config initializer
            provide: APP_INITIALIZER,
            useFactory: initializeLayoutConfig,
            deps: [LayoutConfigService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptService,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptService,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
        {
            provide: MatPaginatorIntl,
            useClass: MatPaginatorIntlCustom,
        },
        {
            provide: NgbDateParserFormatter,
            useClass: NgbDateFormatter,
        },
        {
            provide: RouteReuseStrategy,
            useClass: NemexRouteReuseStrategy,
        },
        // template services
        SubheaderService,
        MenuHorizontalService,
        MenuAsideService,
        LayoutUtilsService,
        LoadingService,
        ApplicationInsightsService,
        BackendTranslateService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
