// Angular
import { Injectable, OnDestroy } from '@angular/core';
// RxJS
import { Subject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from './translation.service';
import { MenuConfig } from '../../../_config/menu.config';
import { AuthService } from '../../../auth';

@Injectable()
export class MenuConfigService implements OnDestroy {
    // Public properties
    onConfigUpdated$: Subject<any>;
    // Private properties
    private menuConfig: any;
    private subscriptions: Subscription[] = [];
    /**
     * Service Constructor
     */
    constructor(private translate: TranslateService, private translationService: TranslationService, private authService: AuthService) {
        // register on config changed event and set default config
        this.onConfigUpdated$ = new Subject();

        this.subscriptions.push(
            this.translationService.getLanguageChangeEmitter().subscribe(() => {
                this.loadConfigs();
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    /**
     * Returns the menuConfig
     */
    getMenus() {
        return this.menuConfig;
    }

    loadConfigs() {
        this.menuConfig = this.translateMenu(new MenuConfig().configs(this.authService.getRoles()));
        this.onConfigUpdated$.next(this.menuConfig);
    }

    translateMenu(config: any) {
        if (!config) {
            return config;
        }

        config.aside.items.forEach((x) => {
            if (x.title) {
                x.title = this.translate.instant('SHARED.MENU.' + x.title);
            }
            if (x.section) {
                x.section = this.translate.instant('SHARED.MENU.' + x.section);
            }
        });
        return config;
    }
}
