import { Agency } from '@scalepoint/nemex-api-clients/services';

export class AgencyModel {
    readonly id?: string | undefined;
    readonly name?: string | undefined;

    constructor(apiAgency: Agency) {
        this.id = apiAgency.id;
        this.name = apiAgency.name;
    }
}
