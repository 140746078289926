export enum Role {
    CustomerRatingRead = 'CustomerRating_Read',
    CustomerInvoiceRead = 'CustomerInvoice_Read',
    CustomerDocumentWrite = 'CustomerDocument_Write',
    CustomerDocumentRead = 'CustomerDocument_Read',
    SmsCustomer = 'SMS_Customer',
    QuestionnaireAdmin = 'Questionnaire_Admin',
    QuestionnaireCustomer = 'Questionnaire_Customer',
    CreditInformationSearch = 'CreditInformation_Search',
    CreditInformationCustomer = 'CreditInformation_Customer',
    PolicyNoteWrite = 'PolicyNote_Write',
    CustomerNoteWrite = 'CustomerNote_Write',
    CustomerNoteRead = 'CustomerNote_Read',
    CustomerRelationWrite = 'CustomerRelation_Write',
    CustomerRelationRead = 'CustomerRelation_Read',
    CustomerPhoneWrite = 'CustomerPhone_Write',
    CustomerEmailWrite = 'CustomerEmail_Write',
    CustomerCreate = 'Customer_Create',
    CustomerUpdate = 'Customer_Update',
    CustomerSelfcareLogin = 'CustomerSelfcare_Login',
    AccessNemex = 'Access_NEMEX',
    DfimCustomer = 'DFIM_Customer',
    DfimSearch = 'DFIM_Search',
    FolderAdmin = 'Folder_Admin',
    SmsAdmin = 'SMS_Admin',
    SmsAgencySingle = 'SMS_Agency_Single',
    SmsAgencyBulk = 'SMS_Agency_Bulk',
    InsuranceCertificate = 'Insurance_Certificate',
    OfferRead = 'Offer_Read',
    OfferDelete = 'Offer_Delete',
    LeadBasic = 'Lead_Basic',
    LeadAdmin = 'Lead_Admin',
    DashboardRead = 'Dashboard_Read',
    DashboardAdmin = 'Dashboard_Admin',
    FolderAccess = 'Folder_Access',
    CustomerReviewsSingle = 'CustomerReviews_Single',
    CustomerReviewsBulk = 'CustomerReviews_Bulk',
    CprSearch = 'CPR_Search',
    CustomerWarningRead = 'CustomerWarning_Read',
    CustomerWarningWrite = 'CustomerWarning_Write',
}
