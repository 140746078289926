import { TranslateService } from '@ngx-translate/core';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { AlertModel } from './alert-model';
import { AlertType } from './alert-type';

@Injectable({
    providedIn: 'root',
})
export class AlertService {
    private alertModel: AlertModel;
    @Output() alertEmitter: EventEmitter<AlertModel> = new EventEmitter();

    constructor(private translate: TranslateService) {
        console.log('Alert service constructed');
    }

    // If alertModel is null, we don't show the alert dialog
    setAlert(alertModel: AlertModel) {
        this.alertModel = alertModel;
        this.alertEmitter.emit(this.alertModel);
    }

    showAlertFromError(error: any) {
        if (!error) {
            return;
        }
        if (error.status && (error.status === 401 || error.status >= 500)) {
            // Handled in ErrorInterceptService
            return;
        }

        let alertType = AlertType.contactTechSupport;
        let title = this.translate.instant('SERVICES.ALERT_SERVICE.ERROR');

        this.alertModel = new AlertModel(title, alertType);
        this.alertEmitter.emit(this.alertModel);
    }
}
