<div class="modal-header fade show alert alert-primary" role="alert">
    <div class="alert-text">
        <h5>{{ 'COMPONENTS.LEADS_CREATE_MODAL.TITLE' | translate }}</h5>
    </div>
</div>
<div class="modal-body">
    <div class="data-row">
        <div class="row-label">{{ 'COMPONENTS.LEADS_CREATE_MODAL.AGENCY' | translate }}</div>
        <div class="row-content">
            <ng-select
                [items]="agenciesObservable | async"
                [searchable]="true"
                bindLabel="name"
                placeholder="{{ 'COMPONENTS.LEADS_CREATE_MODAL.AGENCY' | translate }}"
                [appendTo]="'body'"
                [(ngModel)]="agency"
                (change)="onAgencySelected()"
            >
            </ng-select>
        </div>
    </div>

    <div class="data-row">
        <div class="row-label">{{ 'COMPONENTS.LEADS_CREATE_MODAL.CAMPAIGN' | translate }}</div>
        <div class="row-content">
            <ng-select
                [items]="campaignsObservable | async"
                [searchable]="true"
                bindLabel="name"
                placeholder="{{ 'COMPONENTS.LEADS_CREATE_MODAL.CAMPAIGN' | translate }}"
                [appendTo]="'body'"
                [(ngModel)]="campaign"
            >
            </ng-select>
        </div>
    </div>

    <div class="data-row">
        <div class="row-label">{{ 'COMPONENTS.LEADS_CREATE_MODAL.NAME' | translate }}</div>
        <div class="row-content">
            <div>
                <input type="text" [(ngModel)]="name" class="form-control" placeholder="{{ 'COMPONENTS.LEADS_CREATE_MODAL.NAME' | translate }}" />
            </div>
        </div>
    </div>

    <div class="data-row">
        <div class="row-label">{{ 'COMPONENTS.LEADS_CREATE_MODAL.PHONE_NUMBER' | translate }}</div>
        <div class="row-content">
            <input type="text" [(ngModel)]="phoneNumber" maxlength="20" class="form-control" placeholder="{{ 'COMPONENTS.LEADS_CREATE_MODAL.PHONE_NUMBER' | translate }}" />
        </div>
    </div>

    <div class="data-row">
        <div class="row-label">{{ 'COMPONENTS.LEADS_CREATE_MODAL.EMAIL' | translate }}</div>
        <div class="row-content">
            <div>
                <input type="text" [(ngModel)]="email" class="form-control" placeholder="{{ 'COMPONENTS.LEADS_CREATE_MODAL.EMAIL' | translate }}" />
            </div>
        </div>
    </div>

    <div class="data-row">
        <div class="row-label">{{ 'COMPONENTS.LEADS_CREATE_MODAL.COMMENT' | translate }}</div>
        <div class="row-content">
            <textarea class="comment-textarea" placeholder="{{ 'COMPONENTS.LEADS_CREATE_MODAL.COMMENT' | translate }}" [(ngModel)]="comment"> </textarea>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button
        type="button"
        class="btn btn-secondary"
        [disabled]="isCreatingLead || !name || name.split(' ').some(isStringFalsy) || !phoneNumber || !campaign"
        (click)="onSave()"
        [ngClass]="{ 'kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--info': isCreatingLead }"
    >
        {{ 'COMPONENTS.LEADS_CREATE_MODAL.CREATE' | translate }}
    </button>
    <button type="button" class="btn btn-secondary" (click)="onClose()">{{ 'SHARED.COMMON.CANCEL' | translate }}</button>
</div>
