import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PartialsModule } from '../../partials/partials.module';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NewPasswordComponent } from './new-password/new-password.component';

const routes: Routes = [
    {
        path: 'newpassword',
        component: NewPasswordComponent,
    },
];

@NgModule({
    declarations: [NewPasswordComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, MatInputModule, PartialsModule, RouterModule.forChild(routes), TranslateModule.forChild(), MatFormFieldModule],
    exports: [NewPasswordComponent],
})
export class NewPasswordModule {}
