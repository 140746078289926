import { AlertType } from './alert-type';

export class AlertModel {
    title: string;
    theme: string; // primary | secondary | success | danger | warning | info | light | dark
    traceId: string;
    message: string;
    alertType: AlertType;

    constructor(title: string, alertType: AlertType, traceId = null, message = null) {
        this.title = title;
        this.alertType = alertType;
        this.traceId = traceId;
        this.message = message;

        switch (this.alertType) {
            case AlertType.contactTechSupport:
            case AlertType.calculationFailed:
            case AlertType.simpleError: {
                this.theme = 'danger';
                break;
            }
            case AlertType.tokenExpired: {
                this.theme = 'info';
                break;
            }
            case AlertType.simpleText: {
                this.theme = 'info';
                break;
            }
            default: {
                this.theme = 'primary';
            }
        }
    }
}
